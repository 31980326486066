<template>
	<div class="social-auth-box" :class="$store.state.app.platform === 'ios' ? 'ios' : ''">
		<button type="button" class="button facebook" @click.prevent="facebookAuth">
			<span class="inner"><i
					class="fa-brands fa-facebook-f"></i>{{ message }} {{ $t('auth.via_facebook') }}</span>
		</button>
		<button type="button" class="button google" @click.prevent="googleAuth">
			<span class="inner"><i class="fa-brands fa-google"></i>{{ message }} {{ $t('auth.via_google') }}</span>
		</button>
		<button type="button" class="button apple" @click.prevent="appleAuth"
		        v-if="$store.state.app.platform === 'ios'">
			<span class="inner"><i class="fa-brands fa-apple"></i>{{ message }} {{ $t('auth.via_apple') }}</span>
		</button>
	</div>
</template>

<script>
	import { FacebookLogin } from '@capacitor-community/facebook-login';
	import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
	import { SignInWithApple } from '@capacitor-community/apple-sign-in';

	export default {
		name: "SocialAuthBox",
		props: ['type'],
		data() {
			return {
				googleClient: null,
			}
		},
		computed: {
			message() {
				if (this.type === 'login') {
					return this.$t('auth.log_in');
				}

				return this.$t('auth.sign_up');
			}
		},
		methods: {
			appleAuth() {
				SignInWithApple.authorize({
					clientId: process.env.VUE_APP_CAP_ID_APPLE,
					scopes: 'email name',
				}).then((data) => {
					if (this.type === 'login') {
						this.appleLogin(data.response.authorizationCode);
					} else {
						this.appleRegister(data.response.authorizationCode);
					}
				});
			},
			appleRegister(token) {
				this.socialRegister('apple', token, true);
			},
			appleLogin(token) {
				this.socialLogin('apple', token, true);
			},
			async facebookAuth() {
				if (this.$store.state.app.native) {
					let result = await FacebookLogin.getCurrentAccessToken();
					if (result && result.accessToken) {
						if (this.type === 'login') {
							this.facebookLogin(result.accessToken.token);
						} else {
							this.facebookRegister(result.accessToken.token);
						}
						return;
					}
				}

				FacebookLogin.login({permissions: ['email']}).then((data) => {
					if (!data || !data.accessToken) {
						return false;
					}

					if (this.type === 'login') {
						this.facebookLogin(data.accessToken.token);
					} else {
						this.facebookRegister(data.accessToken.token);
					}
				});
			},
			facebookRegister(token) {
				this.socialRegister('facebook', token);
			},
			facebookLogin(token) {
				this.socialLogin('facebook', token);
			},
			googleAuth() {
				if (this.$store.state.app.native) {
					this.googleNativeAuth();
				} else {
					this.googleWebAuth();
				}
			},
			googleWebAuth() {
				if (!this.googleClient) {
					this.googleClient = google.accounts.oauth2.initTokenClient({
						client_id: process.env.VUE_APP_GOOGLE_WEB_ID,
						scope: 'email profile',
						ux_mode: 'popup',
						callback: (response) => {
							if (this.type === 'login') {
								this.googleLogin(response.access_token);
							} else {
								this.googleRegister(response.access_token);
							}
						},
					});
				}

				this.googleClient.requestAccessToken();
			},
			googleNativeAuth() {
				GoogleAuth.signIn().then((data) => {
					let token;
					let requestAccessToken = false;
					if (data.authentication.accessToken) {
						token = data.authentication.accessToken;
					} else {
						token = data.serverAuthCode;
						requestAccessToken = true;
					}

					if (this.type === 'login') {
						this.googleLogin(token, requestAccessToken);
					} else {
						this.googleRegister(token, requestAccessToken);
					}
				});
			},
			googleLogin(token, requestAccessToken) {
				this.socialLogin('google', token, requestAccessToken);
			},
			googleRegister(token, requestAccessToken) {
				this.socialRegister('google', token, requestAccessToken);
			},
			socialLogin(driver, token, requestAccessToken = false) {
				let data = {
					token: token,
					driver: driver,
				};
				if (requestAccessToken) {
					data.request_access_token = true;
				}

				this.$root.$emit('socials-login-loading-status', true);
				if (this.$store.state.app.native){
					data.app_version = this.$store.state.app.version;
				}
				this.axios.post('/api/social-login', data).then((response) => {
					this.$store.dispatch('processLogin', response);
					this.$root.$emit('socials-login', false);
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
					this.$root.$emit('socials-login-loading-status', false);
				});
			},
			socialRegister(driver, token, requestAccessToken = false) {
				let data = {
					token: token,
					driver: driver,
				};
				if (requestAccessToken) {
					data.request_access_token = true;
				}

				this.axios.post('/api/get-socials-data', data).then(response => {
					let data = {
						first_name: response.data.data.first_name,
						last_name: response.data.data.last_name,
						email: response.data.data.email,
					};

					switch (driver) {
						case 'facebook':
							data.facebook_token = token;
							break;
						case 'google':
							data.google_token = token;
							break;
						case 'apple':
							data.apple_token = response.data.data.token;
							break;
					}

					this.$root.$emit('socials-data-received', data);
				}).catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.registration-popup-box {
		.social-auth-box {
			flex-direction: row;

			.button {
				//width: calc(50% - 10px);
				margin-right: 20px;
				margin-bottom: 0;
				flex: 1;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.social-auth-box.ios {
			flex-direction: column;
			width: 100%;

			.button {
				margin-right: 0;
				margin-bottom: 10px;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@media($tabletL) {
			.social-auth-box {
				flex-direction: column;
				width: 100%;

				.button {
					margin-right: 0;
					margin-bottom: 10px;
					width: 100%;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}


	}

	.social-auth-box {
		display: flex;
		flex-direction: column;

		.button {
			width: 100%;
			color: var(--white-color, $whiteColor);
			margin-bottom: 10px;
			position: relative;

			.inner {
				position: static;
			}

			i {
				position: absolute;
				left: 15px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				background: var(--white-color, $whiteColor);
				color: var(--background-color, $backgroundColor);
			}
		}

		.facebook {
			background: #1658A5;
		}

		.google {
			background: #C22E2E;
		}

		.apple {
			color: $blackColor;
			background: $whiteColor;

			i {
				font-size: 18px;
				top: calc(50% - 9px);
			}

			&:hover {
				color: $whiteColor;
				background: $blackColor;
			}
		}
	}

	@media($mobileM) {
		.social-auth-box {
			.button {
				font-size: 14px;
			}
		}
	}

	@media($mobileS) {
		.social-auth-box {
			.button {
				font-size: 12px;
			}
		}
	}

</style>