<template>
	<div class="dropzone-box" ref="dropzoneBox">
		<div class="open-dialog" v-if="showDialogElement" @click="openDialog"></div>
		<div class="dropzone" ref="dropzoneContainer"></div>
	</div>
</template>

<script>
	import Dropzone from 'dropzone';
	import {Dialog} from '@capacitor/dialog';

	//Dropzone.autoDiscover = false;

	export default {
		name: "DropzoneBox",
		props: ['name', 'label', 'url', 'maxFiles'],
		data() {
			return {
				dropzone: null,
			}
		},
		computed: {
			showDialogElement() {
				return this.$store.state.app.native && this.$store.state.app.platform === 'android';
			}
		},
		methods: {
			async openDialog() {
				const {value} = await Dialog.confirm({
					title: this.$t('forms.choose_option'),
					message: this.$t('forms.disk_file_or_camera'),
					cancelButtonTitle: this.$t('forms.disk_file'),
					okButtonTitle: this.$t('forms.camera')
				});

				if (value) {
					this.dropzone.hiddenFileInput.accept = 'image/*';
					this.dropzone.hiddenFileInput.setAttribute('capture', true);
				} else {
					this.dropzone.hiddenFileInput.accept = '';
					this.dropzone.hiddenFileInput.removeAttribute('capture');
				}

				this.$refs.dropzoneContainer.click();
			},
			initDropzone() {
				this.dropzone = new Dropzone(this.$refs.dropzoneContainer,
					{
						url: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}/${this.url}`,
						maxFilesize: 10,
						acceptedFiles: null,
						capture: null,
						//withCredentials: true,
						addRemoveLinks: true,
						maxFiles: this.maxFiles || 4,
						headers: {
							'Authorization': `Bearer ${this.$store.state.auth.user_token}`
						},
						dictRemoveFile: '<i class="fal fa-trash-alt"></i>',
						dictCancelUpload: '',
						dictDefaultMessage: this.label,
						success: (data, response) => {
							let input = document.createElement('input');
							input.type = 'hidden';
							input.name = this.name;
							input.value = response.attachment.id;
							input.dataset.uuid = data.upload.uuid;
							this.$refs.dropzoneBox.appendChild(input);
						},
						error: (data, response) => {
							//data.previewElement.parentNode.removeChild(data.previewElement);
							this.dropzone.removeFile(data);
							let errorItems = [];

							if (response.errors) {
								for (const [key, value] of Object.entries(response.errors)) {
									value.forEach((item => errorItems.push(item)));
								}
							}

							if (errorItems.length) {
								this.$swal.fire({
									icon: 'error',
									title: response.message,
									text: errorItems.join(' ')
								});
							} else {
								this.$swal.fire({
									icon: 'error',
									title: 'Oops',
									text: response.message || response
								});
							}

						},
						removedfile: (data) => {
							data.previewElement.parentNode.removeChild(data.previewElement);
							let input = document.querySelector(`input[data-uuid="${data.upload.uuid}"]`);
							if (input) {
								this.$refs.dropzoneBox.removeChild(input);
							}
						}
					}
				);
			}
		},
		mounted() {
			this.initDropzone();
		}
	}
</script>

<style lang="scss">
	.light-mode {
		.dropzone-box {
			.dropzone {
				border: 3px dashed rgba(var(--background-color-rgb), .1);

				.dz-message {
					.dz-button {
						color: var(--background-color, $backgroundColor);
					}
				}
			}
		}
	}

	.dropzone-box {
		margin-bottom: 25px;
		position: relative;

		.open-dialog {
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			z-index: 10;
			cursor: pointer;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.dropzone {
			//border: 3px dashed transparentize($whiteColor, .90);
			border: 3px dashed rgba(var(--white-color-rgb), .1);
			border-radius: 5px;
			background-color: transparent;
			padding: 5px;
			position: relative;

			.dz-message {
				.dz-button {
					border: none;
					padding: 0;
					margin: 0;
					color: var(--white-color, $whiteColor);
					font-weight: 500;
					font-size: 14px;
					//position: relative;
					&:before {
						content: "\f574";
						font-family: "Font Awesome 6 Pro";
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						display: var(--fa-display, inline-block);
						font-style: normal;
						font-variant: normal;
						line-height: 1;
						text-rendering: auto;
						color: var(--primary-color, $primaryColor);
						font-size: 24px;
						display: block;
						margin-bottom: 17px;
					}
				}
			}

			.dz-preview {
				margin: 5px;
				background: transparent;
				min-height: 93px;

				.dz-image {
					width: 93px;
					height: 93px;
					border-radius: 5px;
				}

				&.dz-error {
					.dz-error-message,
					.dz-error-mark {
						display: none;
					}
				}

				.dz-filename {
					overflow: hidden;
					max-width: 75px;
					text-overflow: ellipsis;
				}

				.dz-details {
					padding: 5px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					bottom: 0;

					.dz-size {
						margin-bottom: 5px;
					}
				}


				&.dz-file-preview {
					.dz-image {
						border-radius: 5px;
					}

					.dz-details {
						padding: 5px;
						bottom: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;

						.dz-filename {
							overflow: hidden;
						}

					}

					.dz-error-message,
					.dz-success-mark,
					.dz-error-mark {
						display: none;
					}
				}

				&.dz-image-preview {
					background: transparent;
				}

				.dz-remove {
					position: absolute;
					right: 7px;
					bottom: 3px;
					z-index: 20;
					opacity: 0;
					transition: $animationDefaultDuration;
					color: $errorColor;

					i {
						cursor: pointer;
					}

					&:hover {
						color: var(--primary-color, $primaryColor);
					}
				}

				&:hover {
					.dz-remove {
						opacity: 1;
					}
				}
			}

			&.dz-started {
				.dz-default.dz-message {
					display: none;
				}
			}

			.dz-default.dz-message {
				font-size: 14px;
				font-weight: 600;
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				position: absolute;
			}
		}
	}
</style>