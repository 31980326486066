import { render, staticRenderFns } from "./PageDetail.vue?vue&type=template&id=3e020c71&scoped=true&"
import script from "./PageDetail.vue?vue&type=script&lang=js&"
export * from "./PageDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e020c71",
  null
  
)

export default component.exports