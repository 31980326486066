<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ service.name }}</h2>
				</header>
				<article>
					<service-iframe-box :data="service"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import ServiceIframeBox from "../components/containers/ServiceIframeBox";
	export default {
		name: "ServiceDetail",
		components: {
			ServiceIframeBox
		},
		data() {
			return {
				service: this.$store.state.views.serviceDetail
			}
		},

	}
</script>

<style scoped>

</style>