import axios from "axios";
import {Badge} from "@capawesome/capacitor-badge";

const sidebars = {
    state: {
        showNotifications: false,
        showSettings: false,
        showDocumentsCreateForm: false,
        showDocumentsEditForm: false,
	    showContractsEditForm: false,
        showPersonalDocumentsCreateForm: false,
        showPersonalDocumentsEditForm: false,
        showCardsCreateForm: false,
        showCardsEditForm: false,
        showProtocolDetail: false,
        showMobilitySidebar: false,
        showPortfolioDetail: false,
    },
    mutations: {
        TOGGLE_NOTIFICATIONS(state) {
            state.showNotifications = !state.showNotifications;
        },
        TOGGLE_SETTINGS(state) {
            state.showSettings = !state.showSettings;
        },
        TOGGLE_DOCUMENTS_CREATE_FORM(state) {
            state.showDocumentsCreateForm = !state.showDocumentsCreateForm;
        },
        HIDE_DOCUMENTS_EDIT_FORM(state){
            state.showDocumentsEditForm = false;
        },
        HIDE_CONTRACTS_EDIT_FORM(state){
            state.showContractsEditForm = false;
        },
        HIDE_PERSONAL_DOCUMENTS_EDIT_FORM(state){
            state.showPersonalDocumentsEditForm = false;
        },
        SHOW_MOBILITY_SIDEBAR(state){
            state.showMobilitySidebar = true;
        },
        HIDE_MOBILITY_SIDEBAR(state){
            state.showMobilitySidebar = false;
        },
        SHOW_PERSONAL_DOCUMENTS_EDIT_FORM(state, data){
            state.showPersonalDocumentsEditForm = data;
        },
        SHOW_DOCUMENTS_EDIT_FORM(state, data){
            state.showDocumentsEditForm = data;
        },
	    SHOW_CONTRACTS_EDIT_FORM(state, data){
		    state.showContractsEditForm = data;
	    },
        SHOW_PERSONAL_DOCUMENTS_CREATE_FORM(state){
            state.showPersonalDocumentsCreateForm = true;
        },
        HIDE_PERSONAL_DOCUMENTS_CREATE_FORM(state){
            state.showPersonalDocumentsCreateForm = false;
        },
        TOGGLE_PERSONAL_DOCUMENTS_CREATE_FORM(state){
            state.showPersonalDocumentsCreateForm = !state.showPersonalDocumentsCreateForm;
        },
        SHOW_CARDS_CREATE_FORM(state){
            state.showCardsCreateForm = true;
        },
        TOGGLE_CARDS_CREATE_FORM(state){
            state.showCardsCreateForm = !state.showCardsCreateForm;
        },
        SHOW_CARDS_EDIT_FORM(state, data){
            state.showCardsEditForm = data;
        },
        HIDE_CARDS_EDIT_FORM(state){
            state.showCardsEditForm = false;
        },
        SHOW_PROTOCOL_DETAIL(state, data){
            state.showProtocolDetail = data;
        },
        HIDE_PROTOCOL_DETAIL(state){
            state.showProtocolDetail = false;
        },
        SHOW_PORTFOLIO_DETAIL(state, data){
            state.showPortfolioDetail = data;
        },
        HIDE_PORTFOLIO_DETAIL(state){
            state.showPortfolioDetail = false;
        },
    },
    actions: {
        toggleNotificationsSidebar(context){
            if (!context.state.showNotifications){
                if (context.rootState.auth.user.not_shown_notifications_count){
                    axios.get('/api/notification/mark-as-shown').then(() => {
                        context.commit('CLEAR_NOT_SHOWN_NOTIFICATIONS');
                    });
                }

                if (context.rootState.app.native){
                    Badge.clear();
                }
            }

            context.state.showNotifications = !context.state.showNotifications;
        },
        closeSidebars(context) {
	        for (const [key] of Object.entries(context.state)) {
		        context.state[key] = false;
	        }
        },
    }
};

export default sidebars;