<template>
	<form class="personal-documents-form" enctype="multipart/form-data" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<div class="form-row">
			<checkbox-switcher name="child" :label="$t('forms.personal_document_child')"
			                   icon='<i class="fa-thin fa-circle-check"></i>'/>
		</div>
		<div class="form-row">
			<field-box name="personal_document_category_id" :label="$t('forms.personal_document_category')" type="select"
			           :options="personalDocumentCategoriesOptions" event="category-field-changed"/>
		</div>
		<div class="form-rows-group" v-if="!showOcr">
			<div class="form-row two-columns">
				<field-box name="number" :label="$t('forms.personal_document_number')" type="text"/>
				<field-box name="issued_by" :label="$t('forms.issued_by')" type="text"/>
			</div>
			<div class="form-row" v-if="category === 4">
				<field-box name="name" :label="$t('forms.personal_document_title')" type="text"/>
			</div>
			<div class="form-row two-columns" v-if="child">
				<field-box name="first_name" :label="$t('forms.first_name')" type="text"/>
				<field-box name="last_name" :label="$t('forms.last_name')" type="text"/>
			</div>
			<div class="form-row two-columns">
				<field-box name="valid_from" :label="$t('forms.valid_from')" type="date"/>
				<field-box name="valid_to" :label="$t('forms.valid_to')" type="date"/>
			</div>
			<div class="dropzone-boxes-container">
				<dropzone-box name="front_side_id"
				              :label="$t('forms.upload_front_side')"
				              url="api/personal-documents/attachment"
				              :max-files="1"/>
				<dropzone-box name="back_side_id"
				              :label="$t('forms.upload_back_side')"
				              url="api/personal-documents/attachment"
				              :max-files="1"/>
			</div>
		</div>
		<div class="form-actions">
			<button class="button style-4" type="submit" :class="loading ? 'loading' : ''" v-if="!showOcr">
				<span class="inner">{{ $t('forms.save') }}</span>
			</button>
			<button class="button style-4" type="button" :class="!ocrLink ? 'loading' : ''" v-else
			        @click.prevent="startOcr">
				<span class="inner">{{ $t('forms.launch_ocr') }}</span>
			</button>
			<button class="button style-1" type="button"
			        @click="$store.commit('HIDE_PERSONAL_DOCUMENTS_CREATE_FORM')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import ErrorsList from "../containers/ErrorsList";
	import EditableValueField from "../form-fields/EditableValueField";
	import FieldBox from "../form-fields/FieldBox";
	import DropzoneBox from "../form-fields/DropzoneBox";
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import {Browser} from '@capacitor/browser';

	export default {
		name: "PersonalDocumentsCreateForm",
		components: {
			ErrorsList,
			EditableValueField,
			FieldBox,
			DropzoneBox,
			CheckboxSwitcher,
		},
		data() {
			return {
				loading: false,
				errors: false,
				child: false,
				category: '',
				ocrLink: false,
				ocrUid: false,
				runningAjax: false,
			}
		},
		computed: {
			showOcr() {
				let category = this.$store.state.views.personal_document_categories.find(item => item.id === this.category);
				if (!category) {
					return false;
				}

				if (category.ocr) {
					return true;
				}

				return false;
			},
			personalDocumentCategoriesOptions() {
				let categories = [];
				this.$store.state.views.personal_document_categories.forEach(item => {
					categories.push({
						value: item.id,
						title: item.name
					});

				});

				return categories;
			},
		},
		methods: {
			async startOcr() {
				if (!this.ocrLink) {
					return false;
				}

				if (this.$store.state.app.native) {
					this.$store.commit('SET_STOP_AUTOMATIC_LOGOUT');
					await Browser.open({
						url: this.ocrLink
					});
				} else {
					this.$store.commit('HIDE_PERSONAL_DOCUMENTS_CREATE_FORM');
					this.$store.commit('OPEN_OCR_POPUP', this.ocrLink);
				}
			},
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);

				this.loading = true;
				this.axios.post('/api/personal-documents', formData).then(response => {
					this.resetForm(form);
					this.loading = false;

					this.$store.commit('ADD_PERSONAL_DOCUMENT', response.data.personal_document);
					this.$store.commit('TOGGLE_PERSONAL_DOCUMENTS_CREATE_FORM');
				}).catch(error => {
					this.loading = false;
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
				});
			},
			resetForm(form) {
				form.reset();

				let frontSideInput = form.querySelector('input[name="front_side_id"]');
				if (frontSideInput) {
					frontSideInput.parentNode.removeChild(frontSideInput);
				}

				let backSideInput = form.querySelector('input[name="back_side_id"]');
				if (backSideInput) {
					backSideInput.parentNode.removeChild(backSideInput);
				}

				let dzPreviews = form.querySelectorAll('.dz-preview');
				if (dzPreviews.length) {
					for (let dzPreview of dzPreviews) {
						dzPreview.parentNode.removeChild(dzPreview);
					}
				}

				let dropzoneEls = form.querySelectorAll('.dropzone');
				if (dropzoneEls.length) {
					for (let dropzoneEl of dropzoneEls) {
						dropzoneEl.classList.remove('dz-started');
					}
				}
			},
			processPersonalDocumentCategory(data) {
				this.category = parseInt(data.value);
				let category = this.$store.state.views.personal_document_categories.find(item => item.id === this.category);
				if (!category || !category.ocr || this.runningAjax) {
					return false;
				}

				this.runningAjax = true;
				this.axios.post('/api/personal-documents/get-ocr-link', {
					personal_document_category_id: this.category,
				}).then((response) => {
					this.ocrLink = response.data.ocr_link;
					this.ocrUid = response.data.ocr_uid;
					this.runningAjax = false;
				}).catch((error) => {
					this.runningAjax = false;
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});

					this.$store.commit('HIDE_PERSONAL_DOCUMENTS_CREATE_FORM');
				});
			},
			verifyOcrPersonalDocument() {
				if (!this.ocrUid) {
					return false;
				}

				this.axios.post('/api/personal-documents/verify', {
					ocr_uid: this.ocrUid
				}).then(response => {
					this.$store.dispatch('addPersonalDocument', [response.data.personal_document]);
				});
			},
		},
		mounted() {
			this.$root.$on('checkbox-switcher-change', (data) => {
				if (data.name === 'child') {
					this.child = data.value;
				}
			});

			this.$root.$on('category-field-changed', (data) => {
				if (data.name === 'personal_document_category_id') {
					this.processPersonalDocumentCategory(data);
				}
			});

			if (this.$store.state.app.native) {
				Browser.addListener('browserFinished', () => {
					this.verifyOcrPersonalDocument();
					this.$store.commit('UNSET_STOP_AUTOMATIC_LOGOUT');
					this.$store.commit('HIDE_PERSONAL_DOCUMENTS_CREATE_FORM');
				});
			}
		},
		destroyed() {
			this.$root.$off('category-field-changed');
			this.$root.$off('checkbox-switcher-change');
			if (this.$store.state.app.native) {
				Browser.removeAllListeners();
			}
		}
	}
</script>

<style scoped lang="scss">
	.personal-documents-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-rows-group {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}

	.form-actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;

		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.dropzone-boxes-container {
		display: flex;
		justify-content: space-between;

		.dropzone-box {
			width: calc(50% - 8px);
		}
	}

	@media($mobileS) {
		.form-actions {
			margin-top: 25px;
		}
		.dropzone-boxes-container {
			flex-direction: column;

			.dropzone-box {
				width: 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.form-row {
			margin-bottom: 10px;

			&.two-columns {
				flex-direction: column;

				.field-box {
					width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>