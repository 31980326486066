import { App } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from "@capacitor/local-notifications";

const automaticLogoutMixin = {
	created() {
		let lastActiveTimestamp = localStorage.getItem('last_active_timestamp');
		this.$store.commit('SET_LAST_ACTIVE_TIMESTAMP', lastActiveTimestamp);
	},

	mounted() {
		if (this.$store.state.app.native) {
			App.addListener('appStateChange', async ({ isActive }) => {
				if (!isActive) {
					let lastActiveTimestamp = +new Date();
					this.$store.commit('SET_LAST_ACTIVE_TIMESTAMP', lastActiveTimestamp);
					localStorage.setItem('last_active_timestamp', lastActiveTimestamp);
				} else {
					if (!this.$store.state.auth.lastActiveTimestamp) {
						return false;
					}

					this.$store.dispatch('checkAutomaticLogoutStatus');
				}
			});
		}
	},

	destroyed() {
		if (this.$store.state.app.native) {
			App.removeAllListeners();
		}
	},
};

export default automaticLogoutMixin;