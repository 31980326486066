<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<contact-detail-box :data="contactDetailData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import ContactDetailBox from "../components/containers/ContactDetailBox";

	export default {
		name: "PartnerDetail",
		components: {
			ContactDetailBox,
		},
		data() {
			return {
				partner: false,
			}
		},
		computed: {
			logo() {
				let logo = false;
				if (this.partner.info.logo_link) {
					logo = this.partner.info.logo_link;
				}

				return logo;
			},
			valueBoxes() {
				let data = [];

				if (this.partner.info.phone.length) {
					this.partner.info.phone.forEach(item => {
						data.push({
							icon: '<i class="fa-thin fa-phone"></i>',
							title: item.phoneDescription,
							value: item.phoneNumber,
							url: `tel:${item.phoneNumber}`,
						});
					});
				}

				if (this.partner.info.email.length) {
					this.partner.info.email.forEach(item => {
						data.push({
							icon: '<i class="fa-thin fa-envelope"></i>',
							title: item.emailDescription,
							value: item.email,
							url: `mailto:${item.email}`
						});
					});
				}

				if (this.partner.info.link.length) {
					this.partner.info.link.forEach(item => {
						data.push({
							icon: '<i class="fa-thin fa-headset"></i>',
							title: item.linkDescription,
							value: this.$t('contacts.visit_link'),
							valueOverflow: true,
							url: item.link,
							target: '_blank',
						});
					});
				}

				return data;
			},
			contactDetailData() {
				let data = {
					title: this.partner.title,
					logo: this.logo,
					valueBoxes: this.valueBoxes,
				};

				return data;
			}
		},

		created() {
			this.partner = this.$store.state.views.partners.find(item => {
				return item.id === this.$route.params.id;
			});
		}

	}
</script>

<style scoped>

</style>