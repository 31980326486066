<template>
	<default-popup>
		<div class="content">
			<h2 class="popup-title">{{ titles[currentStep] }}</h2>
			<pin-field name="first_pin" v-if="currentStep === 0" :loading="loading"/>
			<pin-field name="second_pin" v-if="currentStep === 1" :loading="loading"/>
			<div class="actions">
				<button class="button style-1" @click.prevent="$store.dispatch('closePopups')">
					<span class="inner">{{ $t('forms.setup_later') }}</span>
				</button>
			</div>
		</div>
	</default-popup>
</template>

<script>
	import {Device} from '@capacitor/device';
	import {Storage} from '@capacitor/storage';
	import DefaultPopup from "./DefaultPopup";
	import PinField from "../form-fields/PinField";

	export default {
		name: "SetupPinPopup",
		components: {
			DefaultPopup,
			PinField,
		},
		data() {
			return {
				loading: false,
				firstPin: null,
				secondPin: null,
				currentStep: 0,
				titles: [
					this.$t('forms.setup_pin'),
					this.$t('forms.repeat_pin')
				],
			}
		},
		methods: {
			setPin(data) {
				if (data.name === 'first_pin') {
					this.firstPin = data.value;
					this.currentStep++;
					return true;
				}
				if (data.name === 'second_pin') {
					this.secondPin = data.value;
					if (this.firstPin !== this.secondPin) {
						this.currentStep--;
						this.firstPin = null;
						this.secondPin = null;
						return false;
					}
				}

				this.savePin();
			},
			async savePin() {
				this.loading = true;
				const deviceId = await Device.getId();
				this.axios.post('/api/user/store-native-auth', {
					device_id: deviceId.uuid,
					type: 'pin',
					pin: this.firstPin,
				}).then(async response => {
					await Storage.set({
						key: 'user_pin',
						value: deviceId.uuid,
					});
					this.$root.$emit('pin-login-setup');
					this.$store.dispatch('closePopups');
					this.loading = false;
				}).catch(error => {
					console.log(error.response);
					this.loading = false;
				})
			},
		},
		mounted() {
			this.$root.$on('pin-value-changed', (data) => {
				this.setPin(data);
			});
		},
    destroyed() {
      this.$root.$off('pin-value-changed');
    }
  }
</script>

<style scoped lang="scss">
	.default-popup {
		.popup-title {
			font-size: 26px;
			margin-bottom: 25px;
			min-height: 62px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.content {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.actions {
		margin-top: auto;
	}

	.pin-field {
		margin-bottom: 20px;
	}
</style>