import { render, staticRenderFns } from "./UserActivationPopup.vue?vue&type=template&id=20698419&scoped=true&"
import script from "./UserActivationPopup.vue?vue&type=script&lang=js&"
export * from "./UserActivationPopup.vue?vue&type=script&lang=js&"
import style0 from "./UserActivationPopup.vue?vue&type=style&index=0&id=20698419&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20698419",
  null
  
)

export default component.exports