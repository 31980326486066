<template>
	<div class="contact-detail-box">
		<h1 class="contact-detail-title">{{ data.title }}</h1>
		<figure class="logo fit-image contain" v-if="data.logo">
			<img :src="data.logo">
		</figure>
		<value-boxes-container :data="data.valueBoxes"/>
		<div class="actions">
			<router-link :to="$t('routes.contacts.path')" class="button style-1">
				<span class="inner">{{ $t('contacts.back')}}</span>
			</router-link>
		</div>
	</div>
</template>

<script>
	import ValueBoxesContainer from "./ValueBoxesContainer";

	export default {
		name: "ContactDetailBox",
		props: ['data'],
		components: {
			ValueBoxesContainer
		},
		computed: {

		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.contact-detail-title {
		font-size: 36px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.value-boxes-container {
		margin-bottom: 50px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.actions {
		text-align: center;
		.button {
			max-width: 320px;
			width: 100%;
		}
	}

	.logo {
		width: 142px;
		height: 142px;
		margin: 0 auto 20px;
		border-radius: 50%;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.value-boxes-container {
		margin-top: 50px;
	}

	@media($tabletM) {
		.value-boxes-container {
			margin-top: 35px;
		}
	}

	@media($mobileM) {
		.logo {
			width: 125px;
			height: 125px;
		}
		.contact-detail-title {
			font-size: 26px;
		}
		.value-boxes-container {
			margin-top: 0;
		}

	}
</style>