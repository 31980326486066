<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<tab-boxes-container>
						<tab-box :title="$t('routes.documents.title')">
							<actions-panel extra-class="space-between">
								<router-link class="button style-1" :to="$t('routes.all_documents.path')">
									<span class="inner">{{ $t('routes.all_documents.title') }}</span>
								</router-link>
								<button class="button style-3"
								        @click.stop="$store.commit('TOGGLE_DOCUMENTS_CREATE_FORM')">
									<span class="inner">{{ $t('documents.add_document') }}</span>
								</button>
							</actions-panel>
							<icon-link-boxes-container :data="$store.state.views.document_categories"/>
						</tab-box>
						<tab-box :title="$t('protocols.title')">
							<actions-panel>
								<button class="button style-3" @click.prevent="refreshProtocols">
									<span class="inner">{{ $t('documents.refresh_protocols') }}</span>
								</button>
							</actions-panel>
							<panel-boxes-container :data="protocols" v-if="protocols.length"/>
							<p v-else>{{ $t('documents.no_documents') }}</p>
						</tab-box>
					</tab-boxes-container>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import protocolMixin from "../mixins/protocolMixin";
	import TabBoxesContainer from "../components/containers/TabBoxesContainer";
	import TabBox from "../components/containers/TabBox";
	import IconLinkBoxesContainer from "../components/containers/IconLinkBoxesContainer";
	import PanelBoxesContainer from "../components/containers/PanelBoxesContainer";
	import ActionsPanel from "../components/panels/ActionsPanel";

	export default {
		name: "DocumentsHome",
		components: {
			TabBox,
			TabBoxesContainer,
			IconLinkBoxesContainer,
			PanelBoxesContainer,
			ActionsPanel
		},
		mixins: [protocolMixin],
		data() {
			return {
				loading: false,
			}
		},
		computed: {
			protocols() {
				let protocols = [];
				this.$store.state.views.protocols.forEach(item => {
					let name, description, category, statusText;
					if (this.eicTypes.includes(item.typ_dokumentu_id)) {
						name = this.$t('protocols.eic_name');
						description = this.$t('protocols.eic_description');
					} else {
						name = this.$t('protocols.protocol_name');
						description = this.$t('protocols.protocol_description');
					}

					switch (item.status_dokumentu_id) {
						case '1':
						case '4':
							category = this.$t('protocols.category_to_approve');
							break;
						case '2':
							category = this.$t('protocols.category_refused');
							statusText = this.$t('protocols.status_refused');
							break;
						case '3':
							category = this.$t('protocols.category_approved');
							statusText = this.$t('protocols.status_approved');
							break;
						case '6':
							category = this.$t('protocols.category_first_part_approved');
							statusText = this.$t('protocols.status_first_part_approved');
							break;
					}

					protocols.push({
						id: item.id,
						category: category,
						date: this.$moment(item.vlozeny).format('D.M.YYYY HH:mm:ss'),
						name: name,
						description: description,
						detail: true,
						type: 'protocol',
						protocolData: {
							status: item.status_dokumentu_id,
							statusText: statusText,
						}
					});
				});

				return protocols;
			},
		},
		methods: {
			refreshProtocols(){
				this.$store.dispatch('getProtocolsData');
			}
		},
		created() {
			//this.$store.dispatch('getDashboardData');
		}
	}
</script>

<style scoped>

</style>