<template>
	<nav class="mobile-nav">
		<ul class="nav-buttons">
			<li>
				<router-link :to="$t('routes.investments.path')" class="nav-button-item">
					<div class="icon">
						<i class="fa-thin fa-chart-line-up"></i>
					</div>
					<div class="button-title">{{ $t('routes.investments.title') }}</div>
				</router-link>
			</li>
			<li>
				<router-link :to="$t('routes.services.path')" class="nav-button-item">
					<div class="icon">
						<i class="fa-thin fa-layer-group"></i>
					</div>
					<div class="button-title">{{ $t('routes.services.mobile_title')}} </div>
				</router-link>
			</li>
			<li>
				<router-link :to="$t('routes.documents.path')" class="nav-button-item">
					<div class="icon">
						<i class="fa-thin fa-file-contract"></i>
					</div>
					<div class="button-title">{{ $t('routes.documents.title') }}</div>
				</router-link>
			</li>
			<li>
				<router-link :to="$t('routes.cards.path')" class="nav-button-item">
					<div class="icon">
						<i class="fa-thin fa-id-card"></i>
					</div>
					<div class="button-title">{{ $t('routes.cards.mobile_title') }}</div>
				</router-link>
			</li>
			<li>
				<button class="nav-button-item" type="button" @click.stop.prevent="toggleNav">
					<div class="icon">
						<i class="fa-thin fa-bars"></i>
					</div>
					<div class="button-title">{{ $t('general.more') }}</div>
				</button>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		name: "MobileNav",
		methods: {
			toggleNav(){
				this.$store.commit('TOGGLE_SHOW_NAV');
			}
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.mobile-nav {
			background: var(--white-color, $whiteColor);
		}

		.nav-button-item {
			color: var(--background-color, $backgroundColor);
			&:hover {
				color: var(--background-color, $backgroundColor);
			}

			&.router-link-active:not([href="/"]),
			&.router-link-exact-active {
				color: var(--background-color, $backgroundColor);
			}
		}

	}
	.mobile-nav {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--background-color, $backgroundColor);
		//background: linear-gradient(to top, $backgroundColor 0% , $backgroundColor 85%, transparent 100%);
		padding:  10px 15px 15px;
		display: none;
		z-index: 1000;
		@media($mobileM){
			display: block;
		}
	}

	.nav-buttons {
		display: flex;
		justify-content: space-around;
	}
	.nav-button-item {
		text-align: center;
		border: none;
		height: 100%;
		display: block;
		background: transparent;
		color: var(--font-color, $fontColor);

		.icon {
			font-size: 32px;
			flex-grow: 1;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 2px;
		}

		&:hover {
			color: var(--white-color, $whiteColor);
		}

		&.router-link-active:not([href="/"]),
		&.router-link-exact-active {
			color: var(--white-color, $whiteColor);

			i {
				color: var(--secondary-color, $primaryColor);
			}
		}

		.button-title {
			font-weight: 500;
			text-transform: uppercase;
			font-size: 12px;
		}
	}
</style>