<template>
    <div class="editable-value-field">
        <label>{{ label }}</label>
        <div class="container" v-if="isEditing && !disabled">
            <input type="text" v-model="value" :name="name">
            <button class="editable-button" @click.prevent="toggleEdit"><i class="fa-light fa-check"></i></button>
        </div>
        <div class="container" v-else>
            <input type="hidden" v-model="value" :name="name">
            <p class="show-value">{{ value }}</p>
            <button class="editable-button" @click.prevent="toggleEdit" v-if="!disabled"><i class="fa-light fa-pencil"></i></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EditableValueField",
        props: ['name', 'oldValue', 'disabled', 'label'],
        data() {
            return {
                isEditing: true,
                value: this.oldValue
            }
        },
        methods: {
            toggleEdit(){
                this.isEditing = !this.isEditing;
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    .light-mode {
        label {
            color: var(--secondary-font-color, $secondaryFontColor);
        }
        .editable-button {
            background: var(--background-color, $backgroundColor);
            color: var(--white-color, $whiteColor);
            &:hover {
                background: var(--primary-color, $primaryColor);
            }
        }
        input[type="text"], input[type="email"], input[type="password"], textarea, select {
            color: var(--background-color, $backgroundColor);
            border-color: var(--background-color, $backgroundColor);
        }
        .show-value {
            color: var(--background-color, $backgroundColor);
        }
    }
    .editable-value-field {
        margin-bottom: 15px;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
    }
    label {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 0;
        color: var(--white-color, $whiteColor);
    }
    .editable-button {
        width: 28px;
        min-width: 28px;
        height: 28px;
        background: var(--white-color, $whiteColor);
        color: var(--primary-color, $primaryColor);
        border: none;
        border-radius: 50%;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        line-height: 1;
        transition: $animationDefaultDuration;
        cursor: pointer;
        margin-left: 10px;
        position: absolute;
        right: 0;
        &:hover {
            color: var(--white-color, $whiteColor);
            background: var(--background-color, $backgroundColor);
        }
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
    }
    input[type="text"], input[type="email"], input[type="password"], textarea, select {
        color: var(--white-color, $whiteColor);
        //border-bottom: 2px solid var(--white-color, $whiteColor);
        border-radius: 0;
        background-color: transparent;
        font-size: 30px;
        font-weight: 400;
        padding: 0 35px;
        text-align: center;
        border: none;
        border-bottom: 2px solid $whiteColor;
    }
    .show-value {
        font-size: 30px;
        color: var(--white-color, $whiteColor);
        margin-bottom: 0;
        position: relative;
        top: -1px;
        text-align: center;
        padding: 0 35px;
    }
</style>