<template>
	<form class="default-form" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<input type="hidden" name="_method" value="PUT">
		<input type="hidden" name="complete_registration" value="1">
		<div class="form-row two-columns">
			<default-field-box type="text" name="first_name" :placeholder="$t('forms.first_name')"
			                   :value="first_name" :required="true"/>
			<default-field-box type="text" name="last_name" :placeholder="$t('forms.last_name')"
			                   :value="last_name" :required="true"/>
		</div>
		<div class="form-row two-columns">
			<default-field-box type="email" name="email" :placeholder="$t('forms.email')"
			                   :value="email" :required="true"/>
			<default-field-box type="tel" name="phone" :placeholder="$t('forms.phone')"
			                   :value="phone" :required="true"/>
		</div>
		<div class="form-actions">
			<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('forms.save')}}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "CompleteRegistrationForm",
		components: {
			DefaultFieldBox,
			ErrorsList
		},
		data() {
			return {
				errors: false,
				loading: false,
				first_name: this.$store.state.auth.user.first_name,
				last_name: this.$store.state.auth.user.last_name,
				email: this.$store.state.auth.user.email,
				phone: this.$store.state.auth.user.phone,
			}
		},
		methods: {
			submitForm(e) {
				let formData = new FormData(e.target);

				this.loading = true;
				this.axios.post('/api/user', formData).then((response) => {
					this.$store.commit('SET_USER', response.data.user);
					this.errors = false;
					this.loading = false;
					this.$store.commit('CLOSE_COMPLETE_REGISTRATION_POPUP');
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			}
		}
	}
</script>

<style scoped>

</style>