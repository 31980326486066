const investmentsMixin = {
	methods: {
		getCurrencySymbol(currency){
			let symbol;
			switch (currency) {
				case 'EUR':
					symbol = '€';
					break;
				case 'USD':
					symbol = '$';
					break;
				case 'CZK':
					symbol = 'Kč';
					break;
				default:
					symbol = currency;
					break;
			}

			return symbol;
		},

		getPortfoliosChartOptions(){
			let data = {
				chartOptions: {
					chart: {
						type: 'donut',
					},
					legend: {
						show: true,
						position: 'right',
						fontSize: '12px',
						fontFamily: 'Poppins',
						width: 155,
						labels: {
							colors: 'inherit',
						},
						markers: {
							width: 12,
							height: 12,
							radius: 2,
							offsetX: -5,
							offsetY: 3
						},
					},
					colors: [
						'#f9a03a',
						'#2BA748',
						'#008FFB',
						'#FF4560',
						'#775DD0'
					],
					dataLabels: {
						enabled: true,
						dropShadow: {
							enabled: true,
							top: 2,
							left: 1,
							blur: 2,
							color: '#000',
							opacity: 0.2,
						},
					},
					plotOptions: {
						pie: {
							borderWidth: 0
						}
					},
					stroke: {
						show: true,
						curve: 'smooth',
						lineCap: 'butt',
						colors: [
							'#f9a03a',
							'#2BA748',
							'#008FFB',
							'#FF4560',
							'#775DD0'
						],
						width: 2,
						dashArray: 1,
					},
					tooltip: {
						enabled: true,
						offset: 100,
						y: {
							show: true,
							formatter: (seriesName) => {
								return `${seriesName} ${process.env.VUE_APP_CURRENCY}`;
							},
						},
					},
					responsive: [{
						breakpoint: 991,
						options: {
							legend: {
								width: 'auto',
								position: 'bottom',
								markers: {
									offsetX: -2,
									offsetY: 0,
								}
							}
						},
					}]
				},
			};

			return data;
		},
		getBalanceChartOptions(){
			let gridColor = 'rgba(var(--white-color-rgb), 0.15)';
			let legendColor = 'var(--white-color)';
			if (!this.$store.state.app.darkMode) {
				gridColor = 'rgba(var(--background-color-rgb), 0.15)';
				legendColor = 'var(--font-dark-color)';
			}

			let data = {
				chartOptions: {
					chart: {
						type: 'area',
						zoom: {
							enabled: false
						},
						toolbar: {
							show: false
						},
					},
					dataLabels: {
						enabled: false,
					},
					colors: [
						'var(--primary-color)',
						'#2BA748'
					],
					grid: {
						borderColor: gridColor,
					},
					yaxis: {
						opposite: true,

						tooltip: {
							enabled: false,
						},
						labels: {
							formatter: function (value) {
								return Math.round(value);
							},
							style: {
								colors: 'inherit',
								fontFamily: 'Poppins',
							},

						}
					},
					xaxis: {
						axisBorder: {
							color: gridColor,
						},
						type: 'datetime',
						tooltip: {
							enabled: false,
						},
						labels: {
							style: {
								colors: 'inherit',
								fontFamily: 'Poppins',
							},

						}
					},
					legend: {
						offsetY: 20,
						labels: {
							colors: [legendColor],
						},
						itemMargin: {
							horizontal: 10,
							vertical: 0
						},
					},
					tooltip: {
						enabled: true,
						fillSeriesColor: true,
						theme: 'dark',
						x: {},
						y: {
							formatter(value) {
								return `${value} ${process.env.VUE_APP_CURRENCY}`;
							}
						}
					},
				},
			};

			return data;
		}
	}
};

export default investmentsMixin;