<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_iad.title') }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<panel-boxes-container :data="accountsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import ActionsPanel from "@/components/panels/ActionsPanel";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsIad",
	components: {
		ActionsPanel,
		PanelBoxesContainer
	},
	computed: {
		investmentsData(){
			return this.$store.state.views.investments;
		},
		accountsData(){
			let data = [];
			if (!this.investmentsData){
				return data;
			}

			this.investmentsData.accounts_iad.forEach(item => {
				let slug = this.makeSlug(item);
				let existingItemIndex = data.findIndex(i => i.slug === slug);
				if (existingItemIndex === -1) {
					data.push({
						name: this.makeName(item),
						description: this.$t('investments.account_balance'),
						category: 'IAD',
						price: item.ObejmInvesticieEur,
						detailUrl: `${this.$t('routes.investments_iad.path')}/${slug}`,
						redirectDetail: true,
						slug: slug,
					});
				} else {
					data[existingItemIndex].price += item.ObejmInvesticieEur;
				}
			});

			return data;
		}
	},
	methods: {
		makeName(item){
			if (item.Sporenie === 'fondy'){
				return 'Podielové fondy';
			}

			return `${item.Sporenie} (${item.CisloZmluvy})`;
		},
		makeSlug(item){
			return `${item.Sporenie.toLowerCase().replaceAll(' ', '-')}-${item.CisloZmluvy}`;
		},
		getAccountTotal(portoflios) {
			let total = 0;
			portoflios.forEach(item => {
				if (item.refAmount > 0){
					total += item.refAmount;
				}
			});

			return total.toFixed(2);
		}
	}
}
</script>

<style scoped lang="scss">
	td {
		padding: 5px 10px 5px 0;
	}
</style>