<template>
	<form class="profile-form" @submit.prevent="submitForm">
		<input type="hidden" name="_method" value="PUT">
		<div class="avatar-field-box">
			<figure class="preview fit-image">
				<img :src="avatarSrc" v-if="avatarSrc">
				<img :src="getImage($store.state.auth.user.avatar.filename)" v-else-if="$store.state.auth.user.avatar">
				<img src="@/assets/img/user-sample.jpg" v-else>
			</figure>
			<input type="file" name="avatar" class="file-input" ref="avatar" @change="handleFile" accept="image/*">
			<button class="edit-button" type="button" @click="openAvatarInput">
				<i class="fa-regular fa-pen"></i>
			</button>
		</div>
		<div class="form-info">
			<h2 class="user-name">{{ fullName }}</h2>
		</div>
		<errors-list :errors="errors"/>
		<div class="form-row two-columns">
			<default-field-box type="text" name="first_name" :placeholder="$t('forms.first_name')" :value="first_name"
			                   event="field-changed" :required="true"/>
			<default-field-box type="text" name="last_name" :placeholder="$t('forms.last_name')" :value="last_name"
			                   event="field-changed" :required="true"/>
		</div>
		<div class="form-row two-columns">
			<default-field-box type="email" name="email" :placeholder="$t('forms.email')" :value="email"
			                   :required="true"/>
			<default-field-box type="tel" name="phone" :placeholder="$t('forms.phone')" :value="phone"
			                   :required="true"/>
		</div>
		<change-password-field/>
		<div class="form-row two-columns" v-if="!agentData">
			<default-field-box type="text" name="agent_code" :placeholder="$t('forms.agent_code')"/>
		</div>
		<preview-field v-else :preview-data="agentData" type="text" :hide-field="hideAgentField"
		               :placeholder="$t('forms.agent_code')" name="agent_code"/>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="marketing" :checked="marketing">
				<span class="status"></span>
				<span class="field-title"
				      v-html="$t('forms.agreement_marketing', {link: domainUrl + $t('routes.privacy_policy_marketing.path')})"></span>
			</label>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="financial_services" :checked="financial_services">
				<span class="status"></span>
				<span class="field-title"
				      v-html="$t('forms.agreement_financial_services', {link: domainUrl + $t('routes.financial_services.path')})"></span>
			</label>
		</div>
		<div class="form-actions">
			<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('forms.save_changes') }}</span>
			</button>
			<button type="button" class="button style-6" @click.prevent="removeAccount">
				<span class="inner">{{ $t('auth.delete_account') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import ErrorsList from "../containers/ErrorsList";
	import PreviewField from "../form-fields/PreviewField";
	import ChangePasswordField from "../form-fields/ChangePasswordField";

	export default {
		name: "ProfileForm",
		components: {
			DefaultFieldBox,
			ErrorsList,
			PreviewField,
			ChangePasswordField,
		},
		mixins: [pageFunctionsMixin],
		data() {
			return {
				first_name: this.$store.state.auth.user.first_name,
				last_name: this.$store.state.auth.user.last_name,
				email: this.$store.state.auth.user.email,
				phone: this.$store.state.auth.user.phone,
				marketing: this.$store.state.auth.user.marketing,
				financial_services: this.$store.state.auth.user.financial_services,
				avatarFile: null,
				avatarSrc: null,
				loading: false,
				errors: false,
				hideAgentField: 0,
			}
		},
		computed: {
			domainUrl() {
				return process.env.VUE_APP_URL;
			},
			agentData() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				let data = {
					avatar: this.$store.state.auth.user.agent.avatar,
					full_name: this.$store.state.auth.user.agent.fullName,
					description: this.$t('contacts.your_agent')
				};

				return data;
			},
			fullName() {
				return `${this.first_name} ${this.last_name}`;
			},

		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				this.processFieldChanged(data)
			});
		},
		destroyed() {
			this.$root.$off('field-changed');
		},
		methods: {
			processFieldChanged(data) {
				this[data.name] = data.value;
			},
			async removeAccount() {
				let result = await this.$swal.fire({
					icon: 'warning',
					title: this.$t('general.delete_confirm_title'),
					text: this.$t('general.delete_confirm_text_account'),
					confirmButtonText: this.$t('general.yes'),
					denyButtonText: this.$t('general.no'),
					showDenyButton: true,
				});

				if (!result.isConfirmed) {
					return false;
				}

				this.$store.commit('OPEN_USER_DELETION_POPUP');
			},
			handleFile() {
				if (!this.$refs.avatar.files.length) {
					return false;
				}

				this.avatarFile = this.$refs.avatar.files[0];
				let fileSize = ((this.avatarFile.size / 1024) / 1024).toFixed(4);
				if (fileSize > 10) {
					this.Swal.fire({
						title: this.$t('forms.max_file_size'),
						icon: 'error'
					});
					this.avatarFile = false;
					return false;
				}

				let reader = new FileReader();
				reader.onload = (e) => {
					this.avatarSrc = e.target.result;
				}

				reader.readAsDataURL(this.$refs.avatar.files[0]);
			},
			openAvatarInput() {
				this.$refs.avatar.click();
			},
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);

				if (this.avatarFile) {
					formData.append('avatar', this.avatarFile);
				}

				this.loading = true;
				this.axios.post('/api/user', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then((response) => {
					this.$swal.fire({
						icon: 'success',
						title: this.$t('auth.account'),
						text: this.$t('auth.successfully_updated')
					});

					this.$store.commit('SET_USER', response.data.user);
					this.$store.dispatch('resetTimestamps');
					this.$store.dispatch('resetArticles');
					this.hideAgentField++;
					this.errors = false;
					this.loading = false;
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			}
		},

	}
</script>

<style scoped lang="scss">
	.light-mode {
		.avatar-field-box {
			.preview {
				border-color: var(--background-color, $backgroundColor);
			}

			.edit-button {
				background: var(--background-color, $backgroundColor);
				color: var(--white-color, $whiteColor);
			}
		}
	}

	.form-actions {
		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.profile-form {
		max-width: 660px;
		margin: 0 auto;
	}

	.change-password-field {
		margin-bottom: 20px;
	}

	.avatar-field-box {
		width: 130px;
		height: 130px;
		display: flex;
		justify-content: center;
		margin: 0 auto 25px;
		position: relative;
		cursor: pointer;
		border-radius: 100%;

		.file-input {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
			z-index: 10;
			max-width: 100%;
			border-radius: 100%;
			opacity: 0;
			cursor: pointer;
		}

		.edit-button {
			color: var(--primary-color, $primaryColor);
			border: none;
			background: var(--white-color, $whiteColor);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 1;
			position: absolute;
			width: 41px;
			height: 41px;
			font-size: 19px;
			z-index: 9;
			transition: $animationDefaultDuration;
			cursor: pointer;
			right: -5px;

			&:hover {
				background: var(--primary-color, $primaryColor);
				color: var(--white-color, $whiteColor);
			}
		}

		.preview {
			height: 100%;
			width: 100%;
			border-radius: 100%;
			overflow: hidden;
			border: 2px solid var(--white-color, $whiteColor);

			img {
				padding: 5px;
				border-radius: 100%;
			}
		}
	}

	.form-info {
		text-align: center;
		margin-bottom: 35px;

		h2 {
			font-size: 27px;
			font-weight: 700;
		}
	}
</style>