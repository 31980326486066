<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <article>
                    <contact-detail-box :data="contactDetailData"/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ContactDetailBox from "../components/containers/ContactDetailBox";

    export default {
        name: "BranchDetail",
        components: {
            ContactDetailBox,
        },
        data() {
            return {
                branch: false,
            }
        },
        computed: {
            defaultImage(){
                if (this.$store.state.app.appType === 'pluska'){
                    return 'https://pluska.invelity.com/img/pluska-logo.svg';
                }
                return 'https://portos-test.universal.sk/universal/files/show-private-file/646266/20220425/b28080d49efb33d5e110bbad9d8fcffb900921ea79568fe59ce68257c293df0a';
            },
            logo() {
                let logo = false;
                if (!this.$store.state.auth.user.agent){
                    return this.defaultImage;
                }
                if (this.$store.state.auth.user.agent.broker_data.broker_logo) {
                    logo = this.$store.state.auth.user.agent.broker_data.broker_logo;
                }

                return logo;
            },
            valueBoxes() {
                let data = [];
                if (this.branch.address_street) {
                    let url = `https://maps.google.com/?q=${this.branch.lat},${this.branch.lng}`;
                    data.push({
                        icon: '<i class="fa-thin fa-map-location-dot"></i>',
                        title: this.$t('contacts.address'),
                        value: this.branch.address_street,
                        url: url,
                        target: '_blank'
                    });
                }

                if (this.branch.representative) {
                    data.push({
                        icon: '<i class="fa-thin fa-user"></i>',
                        title: this.$t('contacts.contact_person'),
                        value: this.branch.representative,
                    });
                }

                if (this.branch.tel_1) {
                    data.push({
                        icon: '<i class="fa-thin fa-phone"></i>',
                        title: this.$t('forms.phone'),
                        value: this.branch.tel_1,
                        url: `tel:${this.branch.tel_1}`
                    });
                }

                if (this.branch.tel_2) {
                    data.push({
                        icon: '<i class="fa-thin fa-phone"></i>',
                        title: this.$t('forms.phone'),
                        value: this.branch.tel_2,
                        url: `tel:${this.branch.tel_2}`
                    });
                }

                if (this.branch.email) {
                    data.push({
                        icon: '<i class="fa-thin fa-envelope"></i>',
                        title: this.$t('forms.email'),
                        value: this.branch.email,
                        url: `mailto:${this.branch.email}`
                    });
                }

                return data;
            },
            contactDetailData(){
                let data = {
                    title: this.branch.address_city,
                    logo: this.logo,
                    valueBoxes: this.valueBoxes,
                };

                return data;
            }
        },

        created() {
            this.branch = this.$store.state.views.branches.find(item => {
                return item.id_satelit === this.$route.params.id;
            });
        }

    }
</script>

<style scoped>

</style>