<template>
	<auth-popup v-if="$store.state.popups.showResetPasswordPopup" :extra-class="extraClass">
		<div class="login-popup-box">
			<article>
				<reset-password-form/>
				<div class="actions">
					<button class="register-account" @click.prevent="openRegistrationPopup">{{ $t('auth.create_account') }}</button>
				</div>
			</article>
		</div>
	</auth-popup>
</template>

<script>
	import AuthPopup from "./AuthPopup";
	import SocialAuthBox from "../containers/SocialAuthBox";
	import ResetPasswordForm from "../forms/ResetPasswordForm";

	export default {
		name: "ResetPasswordPopup",
		components: {
			AuthPopup,
			SocialAuthBox,
			ResetPasswordForm
		},
		data() {
			return {
				extraClass: 'reset-password-popup'
			}
		},
		computed: {

		},
		methods: {
			openRegistrationPopup() {
				this.$store.dispatch('openRegistrationPopup');
			},

		}
	}
</script>

<style scoped lang="scss">

	.actions {
		margin-top: 20px;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.register-account {
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: rgba(var(--white-color-rgb), .5);
		display: inline-block;
		background: transparent;
		border: none;
		cursor: pointer;
		transition: $animationDefaultDuration;

		&:hover {
			color: var(--white-color, $whiteColor);
		}
	}
</style>