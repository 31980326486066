<template>
	<figure class="broker-logo">
		<img :src="brokerLogo">
	</figure>
</template>

<script>
	export default {
		name: "BrokerLogo",
		computed: {
			brokerLogo(){
				if (this.$store.state.app.appType === 'pluska'){
					return 'https://pluska.invelity.com/img/pluska-logo.svg';
				}

				let url = 'https://isanon.invelity.com/img/universal-logo.png';
				if (this.$store.state.auth.user.agent && this.$store.state.auth.user.agent.broker_data){
					url = this.$store.state.auth.user.agent.broker_data.broker_logo;
				}

				return url;
			}
		}
	}
</script>

<style scoped lang="scss">
	.broker-logo {
		img {
			max-height: 50px;
		}
	}
</style>