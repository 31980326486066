<template>
    <div class="card-boxes-container" :class="extraClass">
        <card-box v-for="item in data" :key="item.id" :data="item" :disable-actions="disableActions"/>
    </div>
</template>

<script>
    import CardBox from "./CardBox";

    export default {
        name: "CardBoxesContainer",
        props: ['data', 'extraClass', 'disableActions'],
        components: {
            CardBox
        }
    }
</script>

<style scoped lang="scss">
    .card-boxes-container {
        display: flex;
        flex-wrap: wrap;
        &.with-scroll {
            @media($mobileM){
                overflow-x: auto;
                flex-wrap: nowrap;
                justify-content: flex-start;
                padding: 6px 15px;
                margin-left: -15px;
                margin-right: -15px;
                .card-box {
                    width: 33.333%;
                    min-width: 33.333%;
                    margin-right: 10px;
                    &:nth-child(n + 3){
                        margin-top: 0;
                    }
                    &:nth-child(n + 2){
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        &.four-columns {
            .card-box {
                @media(min-width: 992px){
                    width: calc(25% - (30px / 4));
                    margin-right: 10px;
                }


                @media(min-width: 1221px){
                    &:nth-child(4n + 4){
                        margin-right: 0;
                    }
                    &:nth-child(n + 5){
                        margin-top: 10px;
                    }
                }
                @media($desktopM) and (min-width: 992px){
                    width: calc(33% - (20px / 3));
                    &:nth-child(3n + 3){
                        margin-right: 0;
                    }
                    &:nth-child(n + 4){
                        margin-top: 10px;
                    }
                }
            }
        }
        &:not(.four-columns){
            @media(min-width: 1221px) {
                .card-box {
                    width: calc(33.333% - (70px / 3));
                    margin-right: 35px;

                    &:nth-child(3n + 3) {
                        margin-right: 0;
                    }

                    &:nth-child(n + 4) {
                        margin-top: 35px;
                    }
                }
                flex-wrap: wrap;
            }
            @media($desktopM) and (min-width: 992px) {
                .card-box {
                    width: calc(33.333% - (40px / 3));
                    margin-right: 20px;

                    &:nth-child(3n + 3) {
                        margin-right: 0;
                    }

                    &:nth-child(n + 4) {
                        margin-top: 20px;
                    }
                }
            }
        }

        @media($tabletL) {
            justify-content: space-between;
            .card-box {
                width: calc(50% - 10px);

                &:nth-child(n + 3) {
                    margin-top: 20px;
                }
            }
        }
        @media($mobileM){
            .card-box {
                width: calc(50% - 5px);

                &:nth-child(n + 3) {
                    margin-top: 10px;
                }
            }
        }
        @media($mobileS) {
            .card-box {
                width: 100%;

                &:nth-child( n + 2) {
                    margin-top: 10px;
                }
            }
        }
    }
</style>