<template>
    <form class="login-form" @submit.prevent="submitForm">
        <errors-list :errors="errors"/>
        <div class="form-row hidden" v-if="userDefaultEmail || userDefaultId">
          <input type="hidden" name="email" :value="userDefaultEmail" v-if="userDefaultEmail">
          <input type="hidden" name="user_id" :value="userDefaultId" v-if="userDefaultId">
        </div>
        <div class="form-row" v-else>
            <default-field-box type="email" name="email" :placeholder="$t('forms.email')" :required="true"/>
        </div>
        <div class="form-row">
            <default-field-box type="password" name="password" :placeholder="$t('forms.password')" :required="true"/>
        </div>
        <div class="form-actions">
            <button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
                <span class="inner">{{ $t('auth.log_in') }}</span>
            </button>
        </div>
    </form>
</template>

<script>
    import DefaultFieldBox from "../form-fields/DefaultFieldBox";
    import ErrorsList from "../containers/ErrorsList";

    export default {
        name: "LoginForm",
        components: {
            DefaultFieldBox,
            ErrorsList,
        },
        data() {
            return {
                errors: false,
                loading: false,
            }
        },
        computed:{
            userDefaultEmail() {
              return this.$store.state.auth.userDefaultEmail;
            },
            userDefaultId() {
              return this.$store.state.auth.userDefaultId;
            }
        },
        methods: {
            submitForm(e){
                let form = e.target;
                let formData = new FormData(form);

                this.loading = true;
                this.$store.dispatch('login', formData).catch(() => {
                  this.loading = false;
                });
            }
        },
        mounted() {
            this.$root.$on('socials-login-loading-status', (data) => {
                this.loading = data;
            });
        },
        destroyed() {
            this.$root.$off('socials-login-loading-status');
        }
    }
</script>

<style scoped lang="scss">
  .form-row.hidden {
    display: none;
  }
</style>