<template>
    <section class="landing-section">
        <div class="landing-text-box">
            <h1 class="landing-title" v-html="$t('landing.title')"></h1>
            <p v-html="$t('landing.text')"></p>
            <store-buttons-box/>
        </div>
        <figure class="image">
            <img :src="getImage(`landing-layout/phone-${$store.state.app.appType}.png`)">
        </figure>
    </section>
</template>

<script>
    import StoreButtonsBox from "./StoreButtonsBox";
    export default {
        name: "WebLandingTextBox",
        components: {
            StoreButtonsBox
        },
        methods: {
            getImage(path) {
                return require(`@/assets/img/${path}`);
            },
        }
    }
</script>

<style scoped lang="scss">
    .landing-section {
        background: url("~@/assets/img/landing-layout/background.png") right center;
        background-size: cover;
        position: relative;
        overflow: hidden;
    }
    .image {
        position: absolute;
        top: 15%;
        bottom: 0;
        left: 45%;
        max-width: 55%;
        display: flex;
        align-items: center;
    }
    .store-buttons-box {
        margin-top: 50px;
    }
    .landing-text-box {
        background: $whiteColor;
        min-height: calc(100vh - 30px);
        max-width: 49.94%;
        display: flex;
        flex-direction: column;
        //justify-content: flex-end;
        justify-content: center;
        padding: 150px;
        min-width: 500px;
        box-shadow: 0 20px 90px transparentize($blackColor, .9);
    }
    .landing-title {
        color: var(--background-color, $backgroundColor);
        font-weight: 600;
        font-size: 60px;
        margin-bottom: 35px;
    }
    p {
        color: var(--secondary-font-color, $secondaryFontColor);
        font-size: 18px;
        max-width: 520px;
    }
    @media($desktopXL){
        .landing-text-box {
            padding: 7.5%;
        }
        .landing-title {
            font-size: 3.75vw;
        }
    }
    @media($desktopL){
        .landing-text-box {
            padding: 5%;
        }
        .landing-title {
            font-size: 4.5vw;
        }
    }
    @media($tabletL){
        .image {
            width: 540px;
            max-width: initial;
            left: 450px;
        }
        .landing-text-box {
            padding-top: 100px;
            min-height: initial;
        }
        .landing-title {
            font-size: 45px;
            max-width: 520px;
        }
    }
    @media($tabletM){
        .image {
            opacity: .07;
            left: 60%;
        }
        .landing-text-box {
            padding: 35px;
            min-width: initial;
            border-radius: 0;
            width: 100%;
            max-width: initial;
        }
    }
    @media($mobileM){
        .image {
            left: 45%;
        }
        .landing-title {
            font-size: 8.5vw;
        }
        .store-buttons-box {
            margin-top: 15px;
        }
    }
    @media($mobileS){
        .landing-title {
            font-size: 28px;
            margin-bottom: 25px;
        }
        .landing-text-box {
            padding: 25px;
        }
    }

</style>