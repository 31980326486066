<template>
	<form class="contracts-form" enctype="multipart/form-data" @submit.prevent="submitForm">
		<input type="hidden" name="_method" value="PUT">
		<div class="icon">
			<i class="fa-thin fa-file-contract"></i>
		</div>
		<div class="form-row">
			<default-field-box type="text" name="name" :disabled="!isEditing" :value="data.name"
			                   :label="$t('forms.document_title')" :required="true"/>
		</div>
		<errors-list :errors="errors"/>
		<div class="form-row">
			<field-box type="text" name="ext_id" :disabled="true" :label="$t('forms.document_number')" :value="data.ext_id"/>
		</div>
		<div class="form-row two-columns" v-if="data.product.type || data.product.provider">
			<field-box name="no_input" :label="$t('forms.product_type')" type="text" prefix="edit"
			           :value="data.product.type.name"
			           :disabled="true" v-if="data.product.type"/>
			<field-box name="no_input" :label="$t('forms.provider')" type="text" prefix="edit"
			           :value="data.product.provider.name"
			           :disabled="true" v-if="data.product.provider"/>
		</div>
		<div class="form-row two-columns">
			<field-box name="no_input" :label="$t('forms.total_payment')" type="text" prefix="edit" :value="data.total_payment"
			           :disabled="true"/>
			<field-box name="no_input" :label="$t('forms.payment_frequency')" type="text" prefix="edit"
			           :value="paymentFrequency"
			           :disabled="true"/>
		</div>
		<div class="form-row two-columns" v-if="data.sub_payments">
			<field-box name="no_input" :label="$t('forms.main_contract_payment')" type="text" prefix="edit" :value="data.payment"
			           :disabled="true"/>
			<field-box name="no_input" :label="$t('forms.sub_payments')" type="text" prefix="edit"
			           :value="data.sub_payments"
			           :disabled="true"/>
		</div>
		<div class="form-row two-columns" v-for="(row, index) in formattedData" :key="index">
			<field-box :name="item.key" :label="item.name" type="text" prefix="edit" :value="item.value"
			           :disabled="true" v-for="(item, itemIndex) in row" :key="itemIndex"/>
		</div>
		<div class="attachments-container" v-if="data.attachments.length">
			<attachment-preview-field v-for="attachment in data.attachments"
			                          :data="attachment"
			                          :allow-remove="false"
			                          name="remove_attachments[]"
			                          :key="attachment.id"/>
		</div>
		<div class="form-actions">
			<button class="button style-4" type="button" v-if="!isEditing" @click.prevent="isEditing = true">
				<span class="inner">{{ $t('forms.edit') }}</span>
			</button>
			<button class="button style-4" type="submit" :class="loading ? 'loading' : ''" v-else>
				<span class="inner">{{ $t('forms.save') }}</span>
			</button>
			<button class="button style-1" type="button" @click="$store.commit('HIDE_CONTRACTS_EDIT_FORM')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";
	import EditableValueField from "../form-fields/EditableValueField";
	import FieldBox from "../form-fields/FieldBox";
	import DropzoneBox from "../form-fields/DropzoneBox";
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import AttachmentPreviewField from "../form-fields/AttachmentPreviewField";

	export default {
		name: "ContractsEditForm",
		props: ['data'],
		components: {
			DefaultFieldBox,
			ErrorsList,
			EditableValueField,
			FieldBox,
			DropzoneBox,
			CheckboxSwitcher,
			AttachmentPreviewField
		},
		data() {
			return {
				isEditing: false,
				loading: false,
				errors: false,
			}
		},
		computed: {
			paymentFrequency(){
				let paymentFrequency;
				switch (this.data.payment_frequency) {
					case 'mesačne':
						paymentFrequency = this.$t('documents.payment_monthly');
						break;
					case 'ročne':
						paymentFrequency = this.$t('documents.payment_yearly');
						break;
					case 'jednorazovo':
						paymentFrequency = this.$t('documents.payment_once');
						break;
					default:
						paymentFrequency = this.data.payment_frequency;
						break;
				}
				return paymentFrequency;
			},
			notificationOptions() {
				let options = [];
				for (let i = 1; i <= 31; i++) {
					options.push({
						value: i,
						title: i
					});
				}

				options.forEach(item => {
					if (item.value === this.data.notification) {
						item.selected = true;
					}
				});

				return options;
			},
			formattedData() {
				let data = [];
				let index = 0;
				for (const [key] of Object.entries(this.data.data)) {
					if (key === 'frekvencia_platby') {
						continue;
					}

					let item = {
						name: this.data.data[key].label,
						key: key,
					};

					if (this.data.data[key].type === 'date') {
						item.value = this.$moment(this.data.data[key].value).format('D.M.YYYY');
					} else {
						item.value = this.data.data[key].value;
					}

					if (index % 2 === 0) {
						data.push([item]);
					} else {
						data[data.length - 1].push(item);
					}

					index++;
				}

				return data;
			}
		},
		methods: {
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);

				this.loading = true;
				this.axios.post(`/api/contracts/${this.data.id}`, formData).then(response => {
					this.loading = false;

					this.$store.dispatch('updateContract', {
						contract: response.data.contract,
					});

					this.$store.commit('HIDE_CONTRACTS_EDIT_FORM');
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			},
			resetForm(form) {
				form.reset();

				let attachmentInputs = form.querySelectorAll('input[name="attachments[]"]');
				if (attachmentInputs.length) {
					for (let attachmentInput of attachmentInputs) {
						attachmentInput.parentNode.removeChild(attachmentInput);
					}
				}

				let dzPreviews = form.querySelectorAll('.dz-file-preview');
				if (dzPreviews.length) {
					for (let dzPreview of dzPreviews) {
						dzPreview.parentNode.removeChild(dzPreview);
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.contracts-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.form-group {
		label {
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 600;
		}
	}


	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}

	.form-actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;

		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media($mobileS) {
		.form-actions {
			margin-top: 25px;
		}
		.form-row {
			margin-bottom: 10px;

			&.two-columns {
				flex-direction: column;

				.field-box {
					width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>