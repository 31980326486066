<template>
	<div class="article-detail-box">
		<h1 class="article-detail-title">{{ data.name }}</h1>
		<div class="date-category">
			<small class="date">{{ formattedDate }}</small>
			<small class="category" :class="`category-color-${data.article_category.color}`"
			       v-if="data.article_category">{{
				data.article_category.name }}</small>
		</div>
		<figure class="featured-image" v-if="data.featured_image">
			<img :src="getImage(data.featured_image.filename)">
		</figure>
		<article class="article-detail-content" v-html="data.content"></article>
		<user-box :data="data.author" v-if="data.author"/>
		<div class="actions">
			<router-link :to="$t('routes.articles.path')" class="button style-1">{{ $t('articles.back') }}</router-link>
		</div>
	</div>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import UserBox from "./UserBox";

	export default {
		name: "ArticleDetailBox",
		props: ['data'],
		mixins: [pageFunctionsMixin],
		components: {
			UserBox
		},
		computed: {
			formattedDate() {
				return this.$moment(this.data.date).format('D.M.YYYY');
			}
		}

	}
</script>

<style lang="scss">
.light-mode {
	.article-detail-box {
		.article-detail-content {
			ol, ul, p {
				color: var(--secondary-font-color, $secondaryFontColor);
			}
		}
	}
}
	.article-detail-box {
		.user-box,
		.article-detail-content,
		.article-detail-title,
		.date-category,
		.actions {
			max-width: 820px;
			margin-left: auto;
			margin-right: auto;
			//padding: 0 30px;
		}

		.article-detail-title {
			font-size: 46px;
			font-weight: 600;
		}

		iframe {
			width: 100%;
			max-width: 100%;
		}

		.date-category {
			margin-bottom: 50px;

			.date {
				margin-right: 40px;
				font-size: 14px;

				&:last-child {
					margin-right: 0;
				}
			}

			.category {
				font-weight: 600;
				text-transform: uppercase;

			}
		}

		.category-color-1 {
			color: $primaryColor;
		}

		.category-color-2 {
			color: #F52070;
		}

		.featured-image {
			overflow: hidden;
			border-radius: 10px;
			margin-bottom: 50px;
			width: calc(100% + 60px);
			margin-left: -30px;
			margin-right: -30px;

			img {
				width: 100%;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.article-detail-content {
			margin-bottom: 50px;

			&:last-child {
				margin-bottom: 0;
			}

			ol, ul, p {
				color: var(--white-color, $whiteColor);
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.actions {
			margin-top: 75px;
			display: flex;
			justify-content: space-between;

			.button {
				max-width: 320px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		@media($tabletL) {
			.article-detail-title {
				font-size: 36px;
			}
			.featured-image {
				margin-left: -15px;
				margin-right: -15px;
				width: calc(100% + 30px);
			}
			.actions {
				.button {
					max-width: calc(50% - 10px);
				}
			}
		}
		@media($mobileM) {
			padding-bottom: 25px;
			.article-detail-title {
				font-size: 30px;
			}
			.date-category {
				margin-bottom: 35px;
			}
			.featured-image {
				margin-bottom: 35px;
			}
			.actions {
				flex-direction: column;

				.button {
					max-width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}


</style>