<template>
	<form class="documents-filter-form">
		<field-box name="intranet_user" type="select" :options="clientOptions" v-if="clientOptions.length"/>
		<field-box name="category" type="select" :options="categoryOptions" v-if="category"/>
	</form>
</template>

<script>
	import FieldBox from "../form-fields/FieldBox";

	export default {
		name: "DocumentsFilterForm",
		props: ['category'],
		components: {
			FieldBox
		},
		computed: {
			clientOptions() {
				let options = [];
				if (!this.$store.state.auth.user || !this.$store.state.auth.user.relations || !this.$store.state.auth.user.relations.length) {
					return false;
				}

				let relations = this.$store.state.auth.user.relations;
				options.push({
					value: '',
					title: this.$t('services.choose_client')
				});

				if (this.$store.state.auth.user.intranet_id){
					options.push({
						value: this.$store.state.auth.user.intranet_id,
						title: this.$store.state.auth.user.full_name
					});
				}

				relations.forEach(item => {
					let name = [];
					if (item.first_name) {
						name.push(item.first_name);
					}
					if (item.last_name) {
						name.push(item.last_name);
					}
					name = name.join(' ');
					options.push({
						value: item.id,
						title: name,
					});
				});

				return options;
			},
			categoryOptions(){
				let options = [];
				options.push({
					value: '',
					title: this.$t('forms.choose_category')
				});
				this.$store.state.views.document_categories.forEach(item => {
					options.push({
						value: item.id,
						title: item.name
					});
				});

				return options;
			}
		},
	}
</script>

<style lang="scss">
	.documents-filter-form {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		.field-box {
			width: 100%;
			max-width: 200px;
			padding: 11px 15px 12px;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
		}
		@media($tabletS){
			justify-content: space-between;
			.field-box {
				max-width: initial;
				width: calc(50% - 5px);
				margin-right: 0;
			}
		}
		@media($mobileM){
			flex-direction: column;
			.field-box {
				width: 100%;
				margin-bottom: 10px;
				text-align: center;
				.selectr-selected {
					padding: 0 12px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
</style>