import { render, staticRenderFns } from "./StoreButtonsBox.vue?vue&type=template&id=b8cc5efa&scoped=true&"
import script from "./StoreButtonsBox.vue?vue&type=script&lang=js&"
export * from "./StoreButtonsBox.vue?vue&type=script&lang=js&"
import style0 from "./StoreButtonsBox.vue?vue&type=style&index=0&id=b8cc5efa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8cc5efa",
  null
  
)

export default component.exports