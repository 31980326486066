<template>
	<div class="nearest-branch-box" v-if="nearestBranch">
		<div class="inner">
			<user-box :data="branchData"/>
			<div class="distance-box">
				<figure class="icon">
					<i class="fa-thin fa-route"></i>
				</figure>
				<p>{{ distanceData }}</p>
			</div>
			<div class="actions">
				<router-link class="button style-1" :to="nearestBranch.url">
					<span class="inner">{{ $t('contacts.show_branch') }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import branchesMixin from "../../mixins/branchesMixin";
	import {Geolocation} from '@capacitor/geolocation';
	import UserBox from "./UserBox";

	export default {
		name: "NearestBranchBox",
		mixins: [branchesMixin],
		data() {
			return {
				currentPosition: false,
			}
		},
		components: {
			UserBox,
		},
		computed: {
			branchData() {
				let agent = this.$store.state.auth.user.agent;
				if (!agent) {
					if (this.$store.state.app.appType === 'pluska'){
						return {
							full_name: this.$t('contacts.default_company_name'),
							description: this.$t('contacts.nearest_branch'),
							avatar: 'https://pluska.invelity.com/img/pluska-logo.svg'
						};
					}

					return {
						full_name: this.$t('contacts.default_company_name'),
						description: this.$t('contacts.nearest_branch'),
						avatar: 'https://portos-test.universal.sk/universal/files/show-private-file/646266/20220425/b28080d49efb33d5e110bbad9d8fcffb900921ea79568fe59ce68257c293df0a'
					};
				}

				return {
					full_name: agent.broker_data.broker_title,
					description: this.$t('contacts.nearest_branch'),
					avatar: agent.broker_data.broker_logo
				};
			},
			distanceData() {
				return `${this.nearestBranch.distance.toFixed(2)}km, ${this.nearestBranch.address_street}, ${this.nearestBranch.address_city}`;
			},
			nearestBranch() {
				let branches = this.$store.state.views.branches;
				if (!branches.length || !this.currentPosition) {
					return false;
				}

				let distances = [];
				branches.forEach(item => {
					distances.push(this.getDistance(this.currentPosition.lat, this.currentPosition.lng, item.lat, item.lng))
				});

				let distance = Math.min(...distances);
				let minDistanceIndex = distances.indexOf(distance);

				let branch = branches[minDistanceIndex];
				branch.distance = distance;

				return branch;
			},
		},
	}
</script>

<style scoped lang="scss">
	.light-mode .nearest-branch-box {
		background: #F0F0F2;

		.distance-box {
			.icon, p {
				color: var(--background-color, $backgroundColor);
			}
		}
	}

	.nearest-branch-box {
		background: rgba(var(--white-color-rgb), .06);
		border-radius: 5px;
		padding: 17px 15px;

		.button {
			width: 100%;
			font-size: 12px;
			padding: 9px 15px;
		}

		.user-box {
			margin-bottom: 15px;
		}

		.distance-box {
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			.icon {
				width: 50px;
				min-width: 50px;
				height: 50px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				background: var(--primary-gradient, $primaryGradient);
				color: $whiteColor;
				margin-right: 20px;
			}

			p {
				color: $whiteColor;
				font-weight: 600;
				font-size: 12px;
				max-width: 200px;
			}
		}
	}

</style>