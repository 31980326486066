<template>
	<main class="app-main">
		<div class="content-wrap responsive-reorder">
			<div class="sections-container">
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('routes.products.title') }}</h2>
					</header>
					<article>
						<product-boxes-container :products="$store.state.views.products.slice(0, 2)"/>
					</article>
				</section>
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('articles.section_title') }}</h2>
					</header>
					<article>
						<article-boxes-container :articles="$store.state.views.articles.slice(0, 2)"/>
					</article>
				</section>
			</div>
			<div class="sections-container">
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('documents.section_title') }}</h2>
					</header>
					<article>
						<icon-link-boxes-container :data="$store.state.views.document_categories"
						                           :extra-class="scrollClass ? 'with-scroll' : ''"/>
					</article>
				</section>
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('routes.services.title') }}</h2>
					</header>
					<article>
						<icon-link-boxes-container :data="$store.state.views.services"
						                           v-if="$store.state.views.services.length"
						                           :extra-class="scrollClass ? 'with-scroll' : ''"
						                           box-extra-class="color-icon"/>
						<p v-else>{{ $t('services.no_services') }}</p>
						<services-options-form v-if="$store.state.views.services.length"/>
					</article>
				</section>
			</div>
			<div class="sections-container">
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('cards.section_title') }}</h2>
					</header>
					<article>
						<card-boxes-container :data="$store.state.views.cards.slice(0, 8)" v-if="$store.state.views.cards.length"
						                      :extra-class="extraCardsBoxesContainerClasses"
						                      :disable-actions="true"/>
						<p v-else>{{ $t('cards.no_cards') }}</p>
					</article>
				</section>
				<section class="content-section">
					<header class="section-header">
						<h2 class="section-title">{{ $t('contacts.section_title') }}</h2>
					</header>
					<article>
						<contact-info-box/>
					</article>
				</section>
			</div>
		</div>
	</main>
</template>

<script>
	import ProductBoxesContainer from "../components/containers/ProductBoxesContainer";
	import ArticleBoxesContainer from "../components/containers/ArticleBoxesContainer";
	import IconLinkBoxesContainer from "../components/containers/IconLinkBoxesContainer";
	import ServicesOptionsForm from "../components/forms/ServicesOptionsForm";
	import CardBoxesContainer from "../components/containers/CardBoxesContainer";
	import ContactInfoBox from "../components/containers/ContactInfoBox";

	export default {
		name: 'Home',
		components: {
			ProductBoxesContainer,
			ArticleBoxesContainer,
			IconLinkBoxesContainer,
			ServicesOptionsForm,
			CardBoxesContainer,
			ContactInfoBox,
		},
		data() {
			return {}
		},
		methods: {
			initIconLinkBoxes() {
				let boxes = document.querySelectorAll('.icon-link-box .inner');
				if (!boxes.length) {
					return false;
				}

				let heights = [];
				for (let box of boxes) {
					heights.push(box.offsetHeight);
				}

				let maxHeight = Math.max(...heights);
				for (let box of boxes) {
					box.style.minHeight = `${maxHeight}px`;
				}
			}
		},
		computed: {
			scrollClass() {
				return !!this.$store.state.app.native;
			},
			extraCardsBoxesContainerClasses() {
				let classes = [];
				if (this.scrollClass) {
					classes.push('with-scroll');
				}

				classes.push('four-columns');

				return classes.join(' ');
			}
		},
		mounted() {
			this.initIconLinkBoxes();
		}
	}
</script>


<style lang="scss" scoped>
	@media($tabletL) {
		.responsive-reorder {
			display: flex;
			flex-direction: column;

			.sections-container {
				&:first-child {
					order: 2;
					margin-bottom: 0;
				}

				&:last-child {
					order: 1;
					margin-bottom: 35px;
				}
			}
		}
	}
</style>