<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <header class="section-header">
                    <h2 class="section-title">{{ $t('routes.products.title') }}</h2>
                </header>
                <article>
                    <product-containers-group
                            v-if="$store.state.views.products.length"
                            :featured-product="$store.state.views.products[0]"
                            :products="$store.state.views.products.slice(1)"/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ProductContainersGroup from "../components/containers/ProductContainersGroup";

    export default {
        name: "ProductsHome",
        components: {
            ProductContainersGroup
        },
        data() {
            return {}
        },
        created() {
            this.$store.dispatch('getProductsData');
        }
    }
</script>

<style scoped>

</style>