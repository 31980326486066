<template>
	<p class="app-version-box" v-if="$store.state.app.native && $store.state.app.version">
		{{ $t('application.version', {version: $store.state.app.version}) }}
	</p>
</template>

<script>
export default {
	name: "AppVersionBox",
}
</script>

<style scoped lang="scss">
	.app-version-box {
		position: absolute;
		left: 15px;
		right: 15px;
		text-align: center;
		bottom: 10px;
		font-size: 14px;
	}
</style>