<template>
	<div class="mobility-content-box">
		<figure class="mobility-featured-image">
			<img :src="getImage(data.featured_image.filename)">
		</figure>
		<article class="mobility-content" v-html="data.content"></article>
		<mobility-form :mobility-id="data.id"/>
	</div>
</template>

<script>
	import MobilityForm from "../forms/MobilityForm";
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	export default {
		name: "MobilityContentBox",
		mixins: [pageFunctionsMixin],
		props: ['data'],
		components: {
			MobilityForm,
		},
		data() {
			return {

			}
		},
	}
</script>

<style lang="scss">
	.mobility-content-box {
		max-width: 820px;
		margin: 0 auto;
		.mobility-featured-image {
			margin-bottom: 35px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.mobility-content {
			margin-bottom: 50px;
		}
		ul {
			list-style-type: disc;
			padding-left: 20px;
		}
		figure {
			text-align: center;
			a {
				display: inline-block;
			}
		}
	}
</style>