<template>
	<aside class="default-sidebar" :class="active ? 'active' : ''"
	       :style="`padding-top:${sidebarSafeAreaPadding}px`">
		<div class="default-sidebar-inner">
			<header class="sidebar-header" v-if="icon || title">
				<div class="icon" v-if="icon" v-html="icon"></div>
				<h4 class="sidebar-subtitle" v-if="subtitle">{{ subtitle }}</h4>
				<h3 class="sidebar-title" v-if="title">{{ title}}</h3>
			</header>
			<div class="sidebar-actions">
				<button class="close-sidebar" @click.prevent="closeSidebar">
					<i class="fa-thin fa-arrow-left-long"></i>
				</button>
			</div>
			<article>
				<slot/>
			</article>
		</div>
	</aside>
</template>

<script>
	export default {
		name: "DefaultSidebar",
		props: ['active', 'title', 'subtitle', 'icon'],
		data() {
			return {
				key: 'value'
			}
		},
		methods: {
			closeSidebar() {
				this.$store.dispatch('closeSidebars');
			}
		},
		computed: {
			sidebarSafeAreaPadding() {
				return this.$store.state.app.safeArea.top;
			}
		}

	}
</script>

<style scoped lang="scss">
	.light-mode {
		.default-sidebar {
			background: #F0F0F2;
			box-shadow: -50px 0 99px rgba(var(--black-color-rgb), .25);

			.sidebar-actions {
				.close-sidebar {
					color: var(--background-color, $backgroundColor);
				}
			}
		}
	}

	.web .default-sidebar {
		&::-webkit-scrollbar {
			width: 5px;
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--primary-color, $primaryColor);
		}

		&::-webkit-scrollbar-button {
			width: 0;
			height: 0;
			display: none;
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent;
			display: none;
		}
	}

	.default-sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		width: 380px;
		z-index: 100;
		box-shadow: -50px 0 99px var(--black-color, $blackColor);
		background: var(--aside-background-color, #0F1C37);

		transform: translateX(calc(100% + 300px));
		transition: $animationDefaultDuration;
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		.default-sidebar-inner {
			padding: 50px 0;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			position: relative;
		}

		&.active {
			transform: translateX(0);
		}

		.sidebar-actions {
			position: absolute;
			left: 25px;
			top: 25px;
			font-size: 21px;
			display: none;

			.close-sidebar {
				background: transparent;
				border: none;
				color: var(--white-color, $whiteColor);

				&:hover,
				&:active {
					color: var(--primary-color, $primaryColor);
				}
			}
		}

		.sidebar-header {
			text-align: center;
			margin-bottom: 40px;
			padding: 0 25px;

			&:last-child {
				margin-bottom: 0;
			}

			.icon {
				font-size: 21px;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.sidebar-title {
				font-size: 26px;
				font-weight: 600;
			}

			.sidebar-subtitle {
				font-size: 14px;
				font-weight: 400;
			}
		}

		article {
			padding: 0 25px;
			//overflow-y: auto;
			//max-height: calc(100% - 112px);
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}

		@media($mobileM) {
			width: 100%;
			bottom: 0;
			z-index: 1001;
			.default-sidebar-inner {
				padding: 25px 0;
			}
			.sidebar-actions {
				display: block;
			}
		}
		@media($mobileS) {
			.default-sidebar-inner {
				display: block;
			}
			.sidebar-actions {
				font-size: 18px;
			}
			.sidebar-title {
				font-size: 21px;
			}
		}
	}
</style>