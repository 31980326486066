<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <header class="section-header">
                    <h2 class="section-title">404</h2>
                </header>
            </section>
        </div>
    </main>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>