<template>
	<form class="reset-password" @submit.prevent="submitForm">
		<div class="form-message">
			<p>{{ messages[step] }}</p>
		</div>
		<errors-list :errors="errors"/>
		<div class="form-row" v-if="step === 0">
			<default-field-box type="email" name="email" :placeholder="$t('forms.email')" :required="true"
			                   event="field-changed" :key="0"/>
		</div>
		<div class="form-row uppercase" v-if="step === 1">
			<default-field-box type="text" name="code" :placeholder="$t('forms.code')" :required="true"
			                   event="field-changed" :key="1"/>
		</div>
		<div class="form-row" v-if="step === 1">
			<default-field-box type="password" name="password" :placeholder="$t('forms.password')" :required="true"
			                   event="field-changed" :key="2"/>
		</div>
		<div class="form-row" v-if="step === 1">
			<default-field-box type="password" name="password_confirmation" :placeholder="$t('forms.password_confirmation')"
			                   :required="true" event="field-changed" :key="3"/>
		</div>
		<div class="form-actions">
			<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('forms.submit') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "ResetPasswordForm",
		components: {
			DefaultFieldBox,
			ErrorsList,
		},
		data() {
			return {
				errors: false,
				loading: false,
				email: '',
				code: '',
				password: '',
				password_confirmation: '',
				usedEmail: '',
				step: 0,
				messages: [
					this.$t('auth.enter_email'),
					this.$t('auth.enter_reset_code'),
				],

			}
		},

		methods: {
			submitForm(e) {
				if (this.step === 0) {
					this.submitEmail();
				} else {
					this.submitNewPassword();
				}
			},
			submitEmail() {
				this.loading = true;

				this.usedEmail = this.email;
				this.axios.post('/api/reset-password', {
					email: this.email
				}).then(() => {
					this.step = 1;
					this.loading = false;
				}).catch(error => {
					this.loading = false;
					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
				});
			},
			submitNewPassword() {
				this.loading = true;

				this.axios.post('/api/new-password', {
					email: this.usedEmail,
					code: this.code.toUpperCase(),
					password: this.password,
					password_confirmation: this.password_confirmation
				}).then(() => {
					this.$swal.fire({
						icon: 'success',
						title: this.$t('auth.your_password'),
						text: this.$t('auth.successfully_changed')
					});
					this.$store.dispatch('closePopups');
					this.loading = false;
				}).catch(error => {
					this.loading = false;
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
				});
			},
			processFieldChange(data) {
				this[data.name] = data.value;
			},
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				this.processFieldChange(data)
			});
		},
		destroyed() {

		}
	}
</script>

<style scoped lang="scss">
	.uppercase {
		text-transform: uppercase;

		&::placeholder {
			text-transform: none;
		}
	}

	.errors-list {
		margin-bottom: 20px;
	}

	.form-message {
		margin-bottom: 20px;
		text-align: center;

		p {
			color: $whiteColor;
			font-weight: 700;
			font-size: 18px;
			max-width: 270px;
			margin: 0 auto;
		}
	}
</style>