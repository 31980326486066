<template>
	<component is="style">
		:root {
		--primary-color: {{ vars.primaryColor }};
		--secondary-color: {{ vars.secondaryColor }};
		--background-color: {{ vars.backgroundColor }};
		--aside-background-color: {{ vars.asideBackgroundColor }};
		--dark-background-color: {{ vars.darkBackgroundColor }};
		--end-gradient-color: {{ vars.endGradientColor}};
		--white-color: {{ vars.whiteColor }};
		--black-color: {{ vars.blackColor }};
		--font-color: {{ vars.fontColor }};
		--secondary-font-color: {{ vars.secondaryFontColor }};
		--footer-color: {{ vars.footerColor }};
		--auth-popup-color: {{ vars.authPopupColor }};

		--background-color-rgb: {{ vars.backgroundColorRgb }};
		--dark-background-color-rgb: {{ vars.darkBackgroundColorRgb }};
		--white-color-rgb: {{ vars.whiteColorRgb }};
		--black-color-rgb: {{ vars.blackColorRgb }};
		--primary-color-rgb: {{ vars.primaryColorRgb }};

		--primary-gradient: {{ vars.primaryGradient }};
		}
	</component>
</template>

<script>
	import isanonBrokersVars from '../../data/isanon-brokers-vars.json';
	import insanonBrokersVars from '../../data/insanon-brokers-vars.json';

	export default {
		name: "CssVariables",
		computed: {
			brokersVars() {
				let appType = process.env.VUE_APP_TYPE;
				let brokerVars;
				switch (appType) {
					case 'insanon':
						brokerVars = insanonBrokersVars;
						break;
					default:
						brokerVars = isanonBrokersVars;
						break;
				}

				return brokerVars;
			},
			brokerId() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				if (!this.$store.state.auth.user.agent.broker_data) {
					return false;
				}

				return this.$store.state.auth.user.agent.broker_data.broker_id;
			},
			pluskaVars() {
				let vars = {
					primaryColor: '#FFCF28',
					secondaryColor: '#7F8994',
					backgroundColor: '#141412',
					asideBackgroundColor: '#141412',
					lightBackgroundColor: '#1d2943',
					darkBackgroundColor: '#000000',
					endGradientColor: '#F0A21B',
					whiteColor: '#ffffff',
					blackColor: '#000000',
					fontColor: 'rgba(var(--white-color-rgb), .5)',
					secondaryFontColor: '#808795',
					footerColor: '#ffffff',
					authPopupColor: '#020202',

					primaryColorRgb: '255, 207, 40',
					backgroundColorRgb: '20, 20, 18',
					darkBackgroundColorRgb: '0, 0, 0',
					whiteColorRgb: '255, 255, 255',
					blackColorRgb: '0, 0, 0',

					primaryGradient: 'linear-gradient(to right, var(--primary-color) 0%, var(--end-gradient-color) 100%)',
				};

				return vars;
			},
			isanonVars() {
				let vars = {
					primaryColor: '#f9a03a',
					secondaryColor: 'var(--primary-color)',
					backgroundColor: '#000E2A',
					asideBackgroundColor: '#0F1C37',
					lightBackgroundColor: '#1d2943',
					darkBackgroundColor: '#000C23;',
					endGradientColor: '#ee4a38',
					whiteColor: '#ffffff',
					blackColor: '#000000',
					fontColor: 'rgba(var(--white-color-rgb), .5)',
					secondaryFontColor: '#808795',
					footerColor: '#f6f9ff',
					authPopupColor: '#000715',

					primaryColorRgb: '245, 130, 32',
					backgroundColorRgb: '0, 14, 42',
					darkBackgroundColorRgb: '0, 12, 35',
					whiteColorRgb: '255, 255, 255',
					blackColorRgb: '0, 0, 0',

					primaryGradient: 'linear-gradient(to right, var(--primary-color) 0%, var(--end-gradient-color) 100%)',
				};

				return vars;
			},
			vars() {
				if (this.$store.state.app.appType === 'pluska') {
					return this.pluskaVars;
				}

				let vars = this.isanonVars;

				if (this.brokerId && this.brokersVars[`broker_${this.brokerId}`]) {
					let brokerVars = this.brokersVars[`broker_${this.brokerId}`];
					for (let [key, value] of Object.entries(brokerVars)) {
						vars[key] = value;
					}
				}

				return vars;
			}
		}
	}
</script>

<style scoped>

</style>