<template>
	<button class="button style-4" :class="loading ? 'loading' : ''" @click.prevent.stop="startOnboarding" v-if="!verified">
		<span class="inner">{{ $t('general.verify_identity') }}</span>
	</button>
	<button class="button style-1" :class="loading ? 'loading' : ''" @click.prevent.stop="startOnboarding" v-else>
		<span class="inner">{{ $t('general.identity_verified') }}</span>
	</button>
</template>

<script>
	import {Browser} from "@capacitor/browser";

	export default {
		name: "OnboardingBox",
		data() {
			return {
				loading: false,
				ocrLink: false,
				ocrUid: false,
			}
		},
		computed: {
			verified(){
				let user = this.$store.state.auth.user;

				return user.verified;
			},
			buttonText() {
				let user = this.$store.state.auth.user;
				if (!user.verified) {
					return this.$t('general.verify_identity');
				}

				return this.$t('general.verify_identity_again');
			}
		},
		methods: {
			startOnboarding() {
				if (this.loading) {
					return false;
				}

				this.loading = true;
				this.axios.post('/api/personal-documents/get-onboarding-link').then(async (response) => {
					this.loading = false;
					this.ocrLink = response.data.ocr_link;
					this.ocrUid = response.data.ocr_uid;

					this.$store.commit('SET_STOP_AUTOMATIC_LOGOUT');

					await Browser.open({
						url: this.ocrLink
					});

				}).catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});

					this.loading = false;
				});
			},
			verifyOnboarding() {
				if (!this.ocrUid) {
					return false;
				}

				this.axios.post('/api/personal-documents/verify', {
					ocr_uid: this.ocrUid
				}).then(() => {
					this.$swal.fire({
						title: this.$t('general.thank_you'),
						text: this.$t('general.user_verified')
					});
				});
			},
		},
		mounted() {
			Browser.addListener('browserFinished', () => {
				this.verifyOnboarding();
				this.$store.commit('UNSET_STOP_AUTOMATIC_LOGOUT');
			});
		},
		destroyed() {
			Browser.removeAllListeners();
		}
	}
</script>

<style scoped>

</style>