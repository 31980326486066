<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <header class="section-header">
                    <h2 class="section-title">{{ $t('articles.section_title') }}</h2>
                </header>
                <article>
                    <article-containers-group
                            v-if="$store.state.views.articles.length"
                            :featured-article="$store.state.views.articles[0]"
                            :articles="$store.state.views.articles.slice(1)"/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ArticleContainersGroup from "../components/containers/ArticleContainersGroup";

    export default {
        name: "ProductsHome",
        components: {
            ArticleContainersGroup
        },
        data() {
            return {

            }
        },
        created() {
            this.$store.dispatch('getArticlesData');
        }
    }
</script>

<style scoped>

</style>