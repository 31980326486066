<template>
    <div class="tabs-boxes-container">
        <nav class="tabs-nav">
            <ul>
                <li v-for="(item, index) in tabs" :class="selected === index ? 'active' : ''"
                    @click="selectTab(index)">{{ item.$attrs.title }}
                </li>
            </ul>
        </nav>
        <div class="tabs-content">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TabBoxesContainer",
        props: [],
        data() {
            return {
                tabs: [],
                selected: null,
            }
        },
        methods: {
            selectTab(i) {
                this.selected = i

                this.tabs.forEach((tab, index) => {
                    tab.isActive = (index === i)
                });
            }
        },
        created() {
            this.tabs = this.$children;
        },
        mounted() {
            if (this.$route.query.tab){
                let tab = parseInt(this.$route.query.tab);
                if (tab + 1 > this.tabs.length){
                    this.selectTab(0);
                } else {
                    this.selectTab(tab);
                }
            } else {
                this.selectTab(0);
            }


        }
    }
</script>

<style scoped lang="scss">
    .light-mode {
        .tabs-nav {
            ul {
                li {
                    &:hover,
                    &.active {
                        color: var(--background-color, $backgroundColor);
                    }
                    &:before {
                        background: rgba(var(--background-color-rgb), 0.1);
                    }
                }
            }
        }
    }
    .tabs-nav {
        margin-bottom: 47px;
        ul {
            display: flex;
            //gap: 10px;

            li {
                flex: 1;
                text-align: center;
                display: inline-block;
                font-size: 21px;
                position: relative;
                padding-bottom: 16px;
                line-height: 1.4;
                transition: $animationDefaultDuration;
                cursor: pointer;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: 0;
                    height: 3px;
                    left: 0;
                    border-radius: 5px;
                    transition: $animationDefaultDuration;
                }

                &:before {
                    //background: transparentize($whiteColor, .9);
                    background: rgba(var(--white-color-rgb), .1);
                    right: 0;
                }

                &:after {
                    background: var(--secondary-color, $primaryColor);
                    width: 0;
                }

                &:hover,
                &.active {
                    color: var(--white-color, $whiteColor);
                }

                &.active {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
        @media($mobileM){
            margin-bottom: 25px;
            ul {
                li {
                    font-size: 18px;
                    padding-bottom: 12px;
                }
            }
        }
        @media($mobileS){
            ul {
                li {
                    font-size:16px;
                }
            }
        }
    }
</style>