<template>
    <div class="auth-popup" :class="extraClass" :style="`padding-top:${sidebarSafeAreaPadding}px`">
        <div class="popup-actions" :style="`padding-top:${sidebarSafeAreaPadding}px`">
            <button type="button" class="close-popup" @click="closePopup"><i class="fa-thin fa-x"></i></button>
        </div>
        <div class="popup-inner" @click.stop>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AuthPopup",
        props: ['extraClass'],
        methods: {
            closePopup() {
                this.$store.dispatch('closePopups');
            }
        },
        computed: {
            sidebarSafeAreaPadding() {
                return this.$store.state.app.safeArea.top;
            }
        }
    }
</script>

<style scoped lang="scss">
    .native .auth-popup {
        background: var(--background-color, $backgroundColor);
        &.login-popup,
        &.registration-popup,
        &.reset-password-popup {
            .popup-inner {
                width: 100%;
                background: transparent;
                box-shadow: none;
                padding: 0 25px;
            }
        }
    }

    .auth-popup.login-popup {
        .popup-inner {
            max-width: 420px;
            width: 100%;
        }
    }

    .auth-popup.reset-password-popup {
        .popup-inner {
            max-width: 420px;
            width: 100%;
        }
    }

    .auth-popup.registration-popup {
        .popup-inner {
            max-width: 800px;
            width: 100%;
        }
    }

    .auth-popup {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        //background: transparentize($backgroundColor, .2);
        background: rgba(var(--background-color-rgb), .8);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1000;

        .popup-actions {
            position: absolute;
            right: 25px;
            top: 25px;
        }

        .popup-header {
            text-align: center;
            color: var(--white-color, $whiteColor);
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                font-size: 18px;
                font-weight: 700;

            }
        }

        .popup-title {
            font-weight: 700;
            font-size: 36px;
            margin: 0 0 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .close-popup {
            background: transparent;
            color: var(--white-color, $whiteColor);
            transition: $animationDefaultDuration;
            border: none;
            cursor: pointer;

            &:hover {
                color: var(--primary-color, $primaryColor);
            }
        }
    }

    .web:not(.native){
        .popup-inner {
            &::-webkit-scrollbar {
                width: 5px;
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--primary-color, $primaryColor);
            }

            &::-webkit-scrollbar-button {
                width: 0;
                height: 0;
                display: none;
            }

            &::-webkit-scrollbar-corner {
                background-color: transparent;
                display: none;
            }
        }
    }

    .popup-inner {
        max-height: calc(100vh - 50px);
        padding: 50px;
        border-radius: 10px;
        background:  var(--auth-popup-color, $authPopupColor);
        box-shadow: 0 20px 40px var(--black-color, $blackColor);
        overflow: auto;
    }

    @media($tabletL) {
        .popup-inner {
            max-height: calc(100vh - 115px);
            width: 90%;
        }
        .auth-popup.login-popup {
            .popup-inner {
                width: 90%;
            }
        }

        .auth-popup.registration-popup {
            .popup-inner {
                width: 90%;
            }
        }
    }
    @media($tabletS){
        .popup-inner {
            padding: 35px;
        }
    }
    @media($mobileM){
      .popup-inner {
        padding: 25px;
      }
      .auth-popup.login-popup {
        .popup-inner {
          width: 100%;
        }
      }

      .auth-popup.registration-popup {
        .popup-inner {
          width: 100%;
        }
      }
    }
    @media($mobileS){
      .popup-inner {
        padding: 20px;
      }
        .auth-popup {
            .popup-title {
                font-size: 30px;
            }
            .popup-header {
                p {
                    font-size: 16px;
                }
            }
        }

    }
</style>