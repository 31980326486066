<template>
	<default-sidebar :title="sidebar.title" :icon="sidebar.icon" :active="$store.state.sidebars.showNotifications">
		<notification-boxes-container :data="$store.state.auth.user.unread_notifications"
		                              v-if="$store.state.auth.user.unread_notifications && $store.state.auth.user.unread_notifications.length"/>
		<p class="no-data" v-else>{{ $t('notifications.no_notifications') }}</p>
	</default-sidebar>
</template>

<script>
	import DefaultSidebar from "./DefaultSidebar";
	import PanelBoxesContainer from "../containers/PanelBoxesContainer";
	import NotificationBoxesContainer from "../containers/NotificationBoxesContainer";

	export default {
		name: "NotificationsSidebar",
		components: {
			PanelBoxesContainer,
			DefaultSidebar,
			NotificationBoxesContainer
		},
		data() {
			return {
				sidebar: {
					icon: '<i class="fa-thin fa-bell"></i>',
					title: this.$t('notifications.your_notifications'),
				},
			}
		},
		mounted() {
			this.$echo.private(`notifications.${this.$store.state.auth.user.id}`)
				.listen('NotificationEvent', (e) => {
				    this.$store.commit('ADD_NOTIFICATION', e.notification);
					this.$store.commit('INCREASE_NOT_SHOWN_NOTIFICATIONS');
                });
			this.$echo.join('global-notifications').listen('GlobalNotificationEvent', (e) => {
				this.$store.commit('ADD_NOTIFICATION', e.notification);
				this.$store.commit('INCREASE_NOT_SHOWN_NOTIFICATIONS');
			});
		},
		destroyed() {
			this.$echo.leave(`notifications.${this.$store.state.auth.user.id}`);
			this.$echo.leave('global-notifications');
		}

	}
</script>

<style scoped lang="scss">
	.default-sidebar {
		width: 600px;
		@media($mobileM) {
			width: 100%;
		}
	}
	.no-data {
		text-align: center;
	}
</style>