<template>
    <div class="product-detail-box" :class="`type-${data.type}`">
        <div class="background" :style="`top: -${$store.state.app.safeArea.top}px`">
            <figure v-if="data.background_image">
                <img :src="getImage(data.background_image.filename)">
            </figure>
        </div>
        <h1 class="product-detail-title">{{ data.name }}</h1>
        <article class="product-detail-content" v-html="data.content"></article>
        <div class="actions">
            <button class="button style-5" type="button" @click="openPopup">
                <span class="inner">{{ $t('products.contact_agent') }}</span>
            </button>
            <router-link :to="$t('routes.products.path')" class="button style-1">
                <span class="inner">{{ $t('products.back') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
    export default {
        name: "ProductDetailBox",
        props: ['data'],
        mixins: [pageFunctionsMixin],
        methods: {
            openPopup(){
                this.$store.commit('OPEN_PRODUCT_POPUP', this.data);
            }
        }
    }
</script>

<style lang="scss">
    .light-mode {
        .product-detail-box {
            .product-detail-title {
                color: var(--white-color, $whiteColor);
            }
            .button.style-1 {
                color: var(--white-color, $whiteColor);
                background: rgba(var(--white-color-rgb), 0.06);
                &:hover {
                    background: var(--white-color, $whiteColor);
                    color: var(--background-color, $backgroundColor);
                }
            }
        }
    }
    .product-detail-box {
        .product-detail-title,
        .product-detail-content,
        .actions {
            position: relative;
            z-index: 1;
            max-width: 820px;
            margin: 0 auto;
        }

        .product-detail-title {
            font-size: 70px;
            margin-bottom: 35px;
            color: var(--white-color, $whiteColor);
            &:last-child {
                margin-bottom: 0;
            }
        }

	    iframe {
		    width: 100%;
		    max-width: 100%;
	    }

        .product-detail-content {
            text-align: center;
            margin-bottom: 75px;
            color: var(--white-color, $whiteColor);
            &:last-child {
                margin-bottom: 0;
            }

            p, ul, ol, h1, h2, h3, h4, h5, span, div {
                text-align: left;
            }

            img {
                display: inline-block;
                margin-bottom: 50px;
                border-radius: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        ol,
        ul,
        p {
            color: var(--white-color, $whiteColor);
            opacity: 1;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.type-1 {
            .background {
                background: $primaryColor;

                figure {
                    &:before {
                        background-image: linear-gradient(to top, $primaryColor 0%, transparentize($primaryColor, .99));
                    }
                }
            }
        }

        &.type-2 {
            .background {
                background: $otherColor1;

                figure {
                    &:before {
                        background-image: linear-gradient(to top, $otherColor1 0%, transparentize($otherColor1, .99));
                    }
                }
            }
        }

        &.type-3 {
            .background {
                background: $otherColor2;

                figure {
                    &:before {
                        background-image: linear-gradient(to top, $otherColor2 0%, transparentize($otherColor2, .99));
                    }
                }
            }
        }

        &.type-4 {
            .background {
                background: $otherColor3;

                figure {
                    &:before {
                        background-image: linear-gradient(to top, $otherColor3 0%, transparentize($otherColor3, .99));
                    }
                }
            }
        }

        &.type-5 {
            .background {
                background: $otherColor4;

                figure {
                    &:before {
                        background-image: linear-gradient(to top, $otherColor4 0%, transparentize($otherColor4, .99));
                    }
                }
            }
        }

        .background {
            position: absolute;
            left: 300px;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: hidden;

            figure {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }

                img {
                    width: 100%;
                    mix-blend-mode: soft-light;
                }
            }

        }

        .actions {
            display: flex;
            justify-content: space-between;
            .button {
                max-width: 320px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        @media($desktopL){
            .background {
                left: 250px;
            }
        }
        @media($desktopM){
            .background {
                left: 200px;
            }
        }
        @media($tabletL){
            .product-detail-title {
                font-size: 36px;
            }
            .actions {
                .button {
                    max-width: calc(50% - 10px);
                }
            }
        }
        @media($tabletM){
            .background {
                left: 70px;
            }
            .actions {
                flex-direction: column;
                .button {
                    max-width: 100%;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        @media($mobileM){
            padding-bottom: 25px;
            .background {
                left: 0;
            }
            .product-detail-title {
                font-size: 30px;
            }
            .product-detail-content img{
                margin-bottom: 35px;
            }

        }
    }
</style>