<template>
	<form class="cards-form" enctype="multipart/form-data" @submit.prevent="submitForm">
		<div class="form-step" v-if="!category">
			<div class="card-categories-containers-group">
				<div class="card-categories-container">
					<h3 class="card-categories-container-title">{{ $t('cards.custom_cards') }}</h3>
					<div class="card-category-box">
						<a href="#" @click.stop.prevent="chooseCategory('custom')">
							<div class="icon">
								<i class="fa-solid fa-credit-card-blank"></i>
							</div>
							<div class="text">
								<h4 class="card-category-title">{{ $t('cards.add_custom_card') }}</h4>
							</div>
						</a>
					</div>
				</div>
				<div class="card-categories-container">
					<h3 class="card-categories-container-title">{{ $t('cards.predefined_cards') }}</h3>
					<div class="card-category-box" v-for="item in cardCategories" :key="item.id">
						<a href="#" @click.stop.prevent="chooseCategory(item.code)">
							<figure class="image fit-image" v-if="item.logo">
								<img :src="getImage(item.logo.filename)">
							</figure>
							<div class="text">
								<h4 class="card-category-title">{{ item.name }}</h4>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="form-step" v-else>
			<div class="form-headline">
				<h3 class="form-title">{{ $t('cards.add_new_card') }}</h3>
			</div>
			<div class="card-preview">
				<figure class="image fit-image" v-if="category !== 'custom'">
					<img :src="cardPreviewImage">
				</figure>
				<div class="custom-card-preview" v-else :style="`background: ${color};`">
					<div class="icon">
						<i class="fa-solid fa-credit-card-blank"></i>
					</div>
					<div class="custom-card-preview-title">{{ name ? name : $t('forms.card_title') }}</div>
				</div>
			</div>
			<errors-list :errors="errors"/>
			<div class="form-row" v-if="category === 'custom'">
				<field-box name="name" :label="$t('forms.card_title')" type="text"/>
			</div>
			<div class="form-row" v-if="category === 'custom'">
				<button type="button" class="button style-1" @click.prevent.stop="category = false"><span class="inner">{{ $t('forms.choose_predefined') }}</span>
				</button>
			</div>
			<div class="form-row">
				<field-box name="number" :label="$t('forms.card_number')" type="text" :value="number" :extra-class="numberClass"/>
			</div>
			<div class="form-row" v-if="category === 'custom'">
				<color-picker name="color" :colors="colors" :selected="color" :label="$t('forms.choose_color')"/>
			</div>
			<div class="form-row" v-if="$store.state.app.native">
				<barcode-field/>
			</div>
			<div class="form-row">
				<quagga-field/>
			</div>
			<div class="dropzone-boxes-container">
				<dropzone-box name="front_side_id"
				              :label="$t('forms.upload_front_side')"
				              url="api/cards/attachment"
				              :max-files="1"/>
				<dropzone-box name="back_side_id"
				              :label="$t('forms.upload_back_side')"
				              url="api/cards/attachment"
				              :max-files="1"/>
			</div>
		</div>
		<div class="form-actions">
			<button class="button style-4" type="submit" :class="loading ? 'loading' : ''" v-if="category">
				<span class="inner">{{ $t('forms.save') }}</span>
			</button>
			<button class="button style-1" type="button"
			        @click="$store.commit('TOGGLE_CARDS_CREATE_FORM')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import ErrorsList from "../containers/ErrorsList";
	import EditableValueField from "../form-fields/EditableValueField";
	import FieldBox from "../form-fields/FieldBox";
	import ColorPicker from "../form-fields/ColorPicker";
	import DropzoneBox from "../form-fields/DropzoneBox";
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import BarcodeField from "../form-fields/BarcodeField";
	import QuaggaField from "../form-fields/QuaggaField";

	export default {
		name: "CardsCreateForm",
		mixins: [pageFunctionsMixin],
		components: {
			ErrorsList,
			EditableValueField,
			FieldBox,
			DropzoneBox,
			CheckboxSwitcher,
			ColorPicker,
			BarcodeField,
			QuaggaField,
		},
		data() {
			return {
				category: false,
				loading: false,
				errors: false,
				name: '',
				color: '#4951CB',
				number: '',
				numberClass: '',
				colors: [
					'#4951CB',
					'#4981CB',
					'#49B9CB',
					'#49CB93',
					'#7DCB49',
					'#C7CB49',
					'#CB9349',
				],
			}
		},
		computed: {
			cardPreviewImage() {
				let category = this.$store.state.views.card_categories.find((item) => item.code === this.category);
				return this.getImage(category.logo.filename);
			},
			cardCategories() {
				return this.$store.state.views.card_categories.filter(item => item.code !== 'custom');
			},
		},
		methods: {
			chooseCategory(id) {
				this.category = id;
			},

			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);
				formData.append('card_category', this.category);

				this.loading = true;
				this.axios.post('/api/cards', formData).then(response => {
					this.resetForm(form);
					this.loading = false;

					this.$store.commit('ADD_CARD', response.data.card);
					this.$store.commit('TOGGLE_CARDS_CREATE_FORM');
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			},
			resetForm(form) {
				form.reset();

				let frontSideInput = form.querySelector('input[name="front_side_id"]');
				if (frontSideInput) {
					frontSideInput.parentNode.removeChild(frontSideInput);
				}

				let backSideInput = form.querySelector('input[name="back_side_id"]');
				if (backSideInput) {
					backSideInput.parentNode.removeChild(backSideInput);
				}

				let dzPreviews = form.querySelectorAll('.dz-preview');
				if (dzPreviews.length) {
					for (let dzPreview of dzPreviews) {
						dzPreview.parentNode.removeChild(dzPreview);
					}
				}

				let dropzoneEls = form.querySelectorAll('.dropzone');
				if (dropzoneEls.length) {
					for (let dropzoneEl of dropzoneEls) {
						dropzoneEl.classList.remove('dz-started');
					}
				}
			}
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				switch (data.name) {
					case 'name':
						this.name = data.value;
						break;
					case 'color':
						this.color = data.value;
						break;
				}
			});
			this.$root.$on('barcode-scanned', (data) => {
				this.number = data;
			});
			this.$root.$on('screenshot-imported', (data) => {
				this.number = data;
				this.numberClass = 'with-outline';
			});
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.card-category-box {
			.card-category-title {
				color: var(--background-color, $backgroundColor);
			}
		}
	}

	.button {
		width: 100%;
	}

	.card-preview {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.custom-card-preview {
			width: 230px;
			height: 170px;
			border-radius: 5px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: var(--white-color, $whiteColor);
			overflow: hidden;

			.custom-card-preview-title {
				max-width: 90%;
				margin: 0 auto;
				text-align: center;
			}
		}


		.image {
			width: 230px;
			height: 170px;
			border-radius: 5px;
			margin: 0 auto;
			overflow: hidden;

		}
	}


	.form-step {
		margin-bottom: 35px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-headline {
		margin-bottom: 35px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-title {
		font-size: 26px;
		text-align: center;
	}

	.card-categories-container {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.card-categories-container-title {
		font-size: 26px;
		text-align: center;
		padding-bottom: 15px;
		//border-bottom: 1px solid transparentize($whiteColor, .85);
		border-bottom: 1px solid rgba(var(--white-color-rgb), .15);
	}

	.card-category-box {
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: flex;
			align-items: center;

			&:hover {
				.card-category-title {
					color: var(--primary-color, $primaryColor);
				}
			}
		}

		.icon,
		.image {
			width: 80px;
			height: 60px;
			border-radius: 5px;
			margin-right: 20px;
			overflow: hidden;
		}

		.icon {
			background: var(--white-color, $whiteColor);
			margin-bottom: 0;
			font-size: 24px;
			color: var(--primary-color, $primaryColor);
			display: flex;
			align-items: center;
			justify-content: center;
		}


		.card-category-title {
			font-size: 18px;
			font-weight: 600;
			color: var(--white-color, $whiteColor);
			transition: $animationDefaultDuration;
		}
	}


	.cards-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}


	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}

	.form-actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;

		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.dropzone-boxes-container {
		display: flex;
		justify-content: space-between;

		.dropzone-box {
			width: calc(50% - 8px);
		}
	}

	@media($mobileS) {
		.card-categories-container-title,
		.form-title {
			font-size: 21px;
		}
		.form-actions {
			margin-top: 25px;
		}
		.dropzone-boxes-container {
			flex-direction: column;

			.dropzone-box {
				width: 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.form-row {
			margin-bottom: 10px;

			&.two-columns {
				flex-direction: column;

				.field-box {
					width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>