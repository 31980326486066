`<template>
    <ul class="errors-list" v-if="errorItems.length">
        <li v-for="(item, index) in errorItems" :key="index">{{ item }}</li>
    </ul>
</template>

<script>
    export default {
        name: "ErrorsList",
        props: ['errors'],
        data() {
            return {
                errorItems: [],
            }
        },
        watch: {
            errors(data) {
                this.errorItems = [];
                for (const [key, value] of Object.entries(data)) {
                    value.forEach((item => this.errorItems.push(item)));
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .errors-list {
        text-align: center;
        font-size: 14px;
        margin-bottom: 35px;
        color: #C22E2E;
        &:last-child {
            margin-bottom: 0;
        }
        li +li {
            margin-top: 5px;
        }
    }
</style>`