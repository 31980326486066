<template>
    <div class="tab-box" v-if="isActive">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "TabBox",
        data() {
            return {
                isActive: false
            }
        },
    }
</script>

<style scoped>

</style>