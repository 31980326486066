<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_investika_account.title') }}
						{{ investmentsInvestikaAccount.contract.idShareholderAccount }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments_investika.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData" v-if="investmentsInvestikaAccount.portfolios.length"/>
					<p v-else>{{ $t('investments.no_data') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import investmentsMixin from "@/mixins/investmentsMixin";
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsInvestikaAccount",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	mixins: [investmentsMixin],
	computed: {
		investmentsInvestikaAccount() {
			return this.$store.state.views.investmentsInvestikaAccount;
		},
		panelsData() {
			let data = [];
			this.investmentsInvestikaAccount.portfolios.forEach(item => {
				if (item.amount <= 0) {
					return;
				}

				let currency = this.getCurrencySymbol(item.currencyVAL);
				let transactions = this.investmentsInvestikaAccount.transactions.filter(transaction => {
					return transaction.financialInstrumentIsin === item.financialinstrumentisin;
				});
				let totalBalance = 0;
				transactions.forEach(transaction => {
					if (transaction.type === 'Odkup CP'){
						totalBalance-= parseFloat(transaction.amount);
					} else {
						totalBalance+= parseFloat(transaction.amount);
					}
				});

				let returnPercentage = (parseFloat(item.amount) / totalBalance) * 100 - 100;

				data.push({
					id: item.financialinstrumentisin,
					category: this.$t('investments.portfolio'),
					name: item.financialinstrumentname,
					description: this.$t('investments.portfolio_balance'),
					price: parseFloat(item.amount),
					returnPercentage: returnPercentage,
					type: 'portfolio',
					portfolioType: 'investika',
					currency: currency,
					detail: !!item.isin,
				});
			});

			return data;
		},
		chartsData() {
			let data = [
				this.balanceChart,
				this.portfoliosChart,
			];

			return data;
		},
		balanceChart() {
			let data = this.getBalanceChartOptions();

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			let currentDate = new Date().toISOString().slice(0, 10);

			/*this.investmentsEicAccount.balanceHistory.historyLog.forEach(item => {
				let date = item.dateAsString.split(' ')[0];
				series[0].data.push({
					x: date,
					y: item.balance,
				});
			});

			series[0].data.push({
				x: currentDate,
				y: this.totalAccountBalance,
			});*/

			let deposit = 0;

			let totalDeposits = 0;
			let totalWithdrawals = 0;

			/*let transactions = [];
			this.transactions.forEach(item => {
				let amount = parseFloat(item.data.amount);
				if (item.type === 'bank'){
					if (item.creditDebet === 'Debet'){
						amount = amount * -1;
						totalWithdrawals += item.data.amount;
					} else {
						totalDeposits += item.data.amount;
					}
				} else {
					if (item.data.creditAccountVAL !== this.investmentsEicAccount.accId){
						amount = amount * -1;
						totalWithdrawals += item.data.amount;
					} else {
						totalDeposits += item.data.amount;
					}
				}

				transactions.push({
					amount: amount,
					date: item.date,
				});
			});
			transactions = transactions.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			transactions.forEach(item => {
				deposit += item.amount;
				series[1].data.push({
					x: item.date,
					y: deposit.toFixed(2),
				});
			});

			series[1].data.push({
				x: currentDate,
				y: deposit.toFixed(2),
			});*/

			data.series = series;
			data.totalBalance = this.investmentsInvestikaAccount.summary.asset;
			data.totalDeposits = this.investmentsInvestikaAccount.summary.totalDeposits;
			data.totalWithdrawals = this.investmentsInvestikaAccount.summary.totalWithdraws;
			data.totalFees = this.investmentsInvestikaAccount.summary.paidCommissions;
			data.totalReturn =  parseFloat(this.investmentsInvestikaAccount.summary.realizedProfit);

			return data;
		},
		portfoliosChart() {
			let data = this.getPortfoliosChartOptions();

			let series = [];
			let labels = [];
			this.investmentsInvestikaAccount.portfolios.forEach(item => {
				series.push(item.amount);
				labels.push(item.financialinstrumentname);
			});

			data.chartOptions.labels = labels;
			data.series = series;

			return data;
		},
	}
}
</script>

<style scoped>

</style>