<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_conseq.title') }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<panel-boxes-container :data="accountsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsConseq",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	computed: {
		investmentsData(){
			return this.$store.state.views.investments;
		},
		accountsData() {
			let data = [];
			if (!this.investmentsData){
				return data;
			}

			this.investmentsData.accounts_conseq.forEach(item => {
				let accountBalance = 0;
				item.valuation.forEach((valuation) => {
					accountBalance += parseFloat(valuation.BaseValuation);
				});

				data.push({
					name: item.ServiceName,
					description: this.$t('investments.account_balance'),
					category: 'Conseq',
					price: accountBalance,
					detailUrl: `${this.$t('routes.investments_conseq.path')}/${item.ContractNumber}`,
					redirectDetail: true,
				});
			});

			return data;
		},
	}
}
</script>

<style scoped>

</style>