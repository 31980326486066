const overlays = {
	state: {
		showBarcodeOverlay: false,
	},
	mutations: {

	},
	actions: {
		openBarcodeOverlay(context) {
			document.body.classList.add('camera-overlay');
			context.state.showBarcodeOverlay = true;
		},

		closeBarcodeOverlay(context) {
			document.body.classList.remove('camera-overlay');
			context.state.showBarcodeOverlay = false;
		},
	}
};

export default overlays;