const pageFunctionsMixin = {
    data() {
        return {
            appUrl: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}`,
        }
    },
    methods: {
        formatNumber(number){
            number = parseFloat(number);
            return number.toLocaleString('sk-Sk', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: true
            }).replace(',', '.');
        },
        getLocalAsset(path){
            return require(`@/assets/${path}`);
        },
        getImage(filename){
            return `${this.appUrl}/storage/${filename}`;
        },
        logActivity(type, value){
            this.axios.post('/api/activity-logs', {
                type,
                value
            });
        }
    },
};

export default pageFunctionsMixin;