<template>
    <default-sidebar :active="$store.state.sidebars.showPersonalDocumentsEditForm" :icon="sidebar.icon" :title="sidebar.title">
        <personal-documents-edit-form v-if="$store.state.sidebars.showPersonalDocumentsEditForm" :data="$store.state.sidebars.showPersonalDocumentsEditForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import PersonalDocumentsEditForm from "../forms/PersonalDocumentsEditForm";

    export default {
        name: "PersonalDocumentsEditFormSidebar",
        components: {
            DefaultSidebar,
            PersonalDocumentsEditForm,
        },
        data() {
            return {
                sidebar: {
                    icon: '<i class="fa-thin fa-id-card"></i>',
                    title: this.$t('cards.personal_document_detail')
                }
            }
        },
    }
</script>

<style scoped lang="scss">

</style>