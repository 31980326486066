<template>
	<form class="user-activation-form" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<default-field-box type="text" name="activation_code" :placeholder="$t('forms.activation_code')"
		                   v-model="activationCode" event="activation-code-changed" :required="true"/>
		<div class="form-actions">
			<button class="button style-4" :class="activateUserLoading ? 'loading' : ''">
				<span class="inner">{{ $t('auth.activate_account') }}</span>
			</button>
			<button class="button style-1" :class="sendActivationCodeLoading ? 'loading' : ''"
			        @click.prevent="sendActivationCode">
				<span class="inner">{{ $t('auth.send_code_again') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "UserActivationForm",
		components: {
			DefaultFieldBox,
			ErrorsList
		},
		data() {
			return {
				activationCode: '',
				errors: false,
				activateUserLoading: false,
				sendActivationCodeLoading: false,
			}
		},
		methods: {
			submitForm() {
				if (!this.activationCode) {
					return false;
				}

				this.activateUserLoading = true;
				this.axios.post('/api/user/activate-account', {
					activation_code: this.activationCode,
				}).then(response => {
					this.$store.state.auth.user.active = 1;
					this.$store.commit('CLOSE_USER_ACTIVATION_POPUP');
				}).catch(error => {
					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					} else {
						if (error.response.data.message) {
							this.errors = {error: [error.response.data.message]};
						}
					}

					this.activateUserLoading = false;
				});

			},
			sendActivationCode(e, showErrorMessage = true) {
				this.sendActivationCodeLoading = true;
				this.axios.get('/api/user/send-activation-code').then((response) => {
					this.sendActivationCodeLoading = false;
				}).catch((error) => {
					this.sendActivationCodeLoading = false;

					if (!showErrorMessage) {
						return false;
					}

					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message,
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
				});
			},
			processActivationCodeChanged(data) {
				this.activationCode = data.value;
			}
		},
		mounted() {
			this.$root.$on('activation-code-changed', (data) => {
				this.processActivationCodeChanged(data);
			});
			this.sendActivationCode(null, false);
		},
		destroyed() {
			this.$root.$off('activation-code-changed');
		}
	}
</script>

<style scoped lang="scss">
	.button {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>