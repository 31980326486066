<template>
	<section class="public-content-section">
		<header class="public-section-header">
			<div class="content-wrap">
				<h3 class="public-pre-section-title">{{ $t('sections.about.pre_title') }}</h3>
				<h3 class="public-section-title">{{ $t('sections.about.title') }}</h3>
				<p>{{ $t('sections.about.text') }}</p>
			</div>
		</header>
		<article>
			<div class="content-wrap">
				<div class="about-boxes-container">
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-road"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_1.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_1.text') }}</p>
					</div>
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-user-tie"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_2.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_2.text') }}</p>
					</div>
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-location-dot"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_3.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_3.text') }}</p>
					</div>
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-chart-line"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_4.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_4.text') }}</p>
					</div>
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-car"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_5.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_5.text') }}</p>
					</div>
					<div class="about-box">
						<div class="icon-title">
							<div class="icon">
								<i class="fa-solid fa-address-card"></i>
							</div>
							<h4 class="about-title">{{ $t('sections.about.boxes.box_6.title') }}</h4>
						</div>
						<p>{{ $t('sections.about.boxes.box_6.text') }}</p>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
	export default {
		name: "AboutSection"
	}
</script>

<style scoped lang="scss">
	.public-section-header {
		text-align: center;
		margin-bottom: 75px;
	}
	.about-boxes-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.about-box {
			width: 30%;
			max-width: 330px;
			&:nth-child(n + 4){
				margin-top: 50px;
			}
		}

		@media($tabletL){
			.about-box {
				width: 46%;
				max-width: initial;
				&:nth-child(n + 3){
					margin-top: 50px;
				}
			}
		}

		@media($mobileM){
			flex-direction: column;
			.about-box {
				width: 100%;
				max-width: 380px;
				margin: 0 auto;
				&:nth-child(n + 2){
					margin-top: 35px;
				}
			}
		}

	}
	.about-box {
		color: var(--background-color, $backgroundColor);
		.icon-title {
			display: flex;
			align-items: center;
			margin-bottom: 35px;
		}
		.icon {
			width: 110px;
			min-width: 110px;
			height: 110px;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--primary-color, $primaryColor);
			background: #F4F6FB;
			font-size: 28px;
			margin-right: 30px;
			i {
				background: var(--primary-gradient, $primaryGradient);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		.about-title {
			color: $blackColor;
			font-weight: 600;
			font-size: 22px;
			line-height: 1.3636;
		}
		p {
			font-size: 18px;
		}

		@media($tabletM){
			.icon {
				width: 90px;
				min-width: 90px;
				height: 90px;
				border-radius: 10px;
			}
			.about-title {
				font-size: 20px;
			}

		}
		@media($tabletS){
			text-align: center;
			.icon-title {
				.icon {
					margin-bottom: 25px;
					margin-right: 0;
				}
				flex-direction: column;
				margin-bottom: 25px;
			}
			p {
				font-size: 16px;
			}
		}
	}
</style>