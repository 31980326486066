<template>
	<div class="agent-detail-box">
		<div class="company-box">
			<broker-logo/>
			<h4 class="company-name">{{ data.company.name }}</h4>
			<p class="company-description">{{ data.company.description }}</p>
		</div>
		<user-box :data="data" v-if="data.agent"/>
		<div class="request-agent-box" v-else>
			<p>{{ $t('contacts.no_agent') }}</p>
			<button class="button style-4" :class="loading ? `loading` : ''"
			        @click.prevent="requestAgent"
			        v-if="showRequestAgentButton">
				<span class="inner">{{ $t('contacts.request_agent') }}</span>
			</button>
		</div>
	</div>
</template>

<script>
	import UserBox from "./UserBox";
	import BrokerLogo from "../partials/BrokerLogo";

	export default {
		name: "AgentDetailBox",
		props: ['data'],
		data() {
			return {
				loading: false,
				agentRequestSent: false,
			}
		},
		components: {
			UserBox,
			BrokerLogo,
		},
		computed: {
			showRequestAgentButton() {
				let user = this.$store.state.auth.user;
				if (user.agent) {
					return false;
				}

				if (user.settings.agent_requested) {
					return false;
				}

				if (this.agentRequestSent) {
					return false;
				}

				return true;
			},
		},
		methods: {
			requestAgent() {
				this.loading = true;
				this.axios.post('/api/agent-requests').then(() => {
					this.$swal.fire({
						icon: 'success',
						title: this.$t('contacts.agent'),
						text: this.$t('contacts.text_assign')
					});

					this.$store.state.auth.user.settings.agent_requested = true;
					this.agentRequestSent = true;
					this.loading = false;
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.reseponse.data.message
					});
					this.loading = false;
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.request-agent-box {
		margin-top: 5px;
		text-align: center;

		p {
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			text-align: center;
			color: var(--primary-color, $primaryColor);
		}
	}

	.agent-detail-box {
		margin-bottom: 5px;
		padding: 0 35px 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;

		&:before {
			content: '';
			left: 0;
			right: 0;
			top: 75px;
			bottom: 0;
			//background: transparentize($whiteColor, .94);
			background: rgba(var(--white-color-rgb), .06);
			position: absolute;
			display: block;
			border-radius: 5px;
			z-index: -1;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.company-box {
			text-align: center;
			margin-bottom: 25px;
			padding-bottom: 25px;
			//border-bottom: 1px solid transparentize($whiteColor, .85);
			border-bottom: 1px solid rgba(var(--white-color-rgb), .15);
			width: 100%;

			.image,
			.broker-logo {
				margin: 0 auto 20px;
				width: 150px;
				height: 150px;
				border-radius: 100%;
				border: 2px solid var(--primary-color, $primaryColor);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.company-name {
				font-weight: 600;
				font-size: 27px;
				margin: 0 0 10px;
			}

			.company-description {
				font-size: 15px;
				font-weight: 400;
			}
		}

		@media($mobileM) {
			.company-box {
				.company-name {
					font-size: 24px;
				}
			}
		}
		@media($mobileS) {
			.company-box {
				.company-name {
					font-size: 20px;
				}
			}
		}
	}
</style>