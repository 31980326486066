<template>
	<div class="service-iframe-box">
		<iframe :src="data.hash_complete_link" @load="load" ref="iframe"></iframe>
	</div>
</template>

<script>
	export default {
		name: "ServiceIframeBox",
		props: ['data'],
		data() {
			return {}
		},
		computed: {
			url() {
				let link = this.data.hash_complete_link;
				let args = [];
				this.data.arguments.forEach(item => {
					switch (item) {
						case 'klient_id':
							//link = link.replace('{client_id}', 'K300465');
							break;
						case 'agent_id':
							if(this.$store.state.auth.user.agent_code){
								args.push(`${item}=${this.$store.state.auth.user.agent_code}`);
							}
							break;
					}
				});

				return link;
			}
		},
		methods: {
			load(){

			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.service-iframe-box {
		width: 100%;
		iframe {
			width: 100%;
			border: none;
			vertical-align: middle;
			height: 1000px;
		}
	}
</style>