<template>
    <div class="panel-boxes-container">
        <panel-box v-for="item in data" :key="item.id" :data="item"/>
    </div>
</template>

<script>
    import PanelBox from "./PanelBox";
    export default {
        name: "PanelBoxesContainer",
        props: ['data'],
        components: {
            PanelBox
        }
    }
</script>

<style scoped lang="scss">
    .panel-boxes-container {
        .panel-box {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>