<template>
	<div class="preview-field">
		<user-box :data="previewData"/>
		<div class="field">
			<button type="button" class="button style-1" v-if="!showField" @click.prevent="toggleShowField">
				<span class="inner">{{ $t('forms.change') }}</span>
			</button>
			<default-field-box :type="type" :name="name" :placeholder="placeholder" v-else />
		</div>
	</div>
</template>

<script>
	import DefaultFieldBox from "./DefaultFieldBox";
	import UserBox from "../containers/UserBox";

	export default {
		name: "PreviewField",
		props: ['preview-data', 'placeholder', 'name', 'type', 'options', 'hide-field'],
		components: {
			UserBox,
			DefaultFieldBox
		},
		data() {
			return {
				showField: false
			}
		},
		watch:{
			hideField(){
				this.showField = false;
			}
		},
		methods: {
			toggleShowField(){
				this.showField = !this.showField;
			}
		},

	}
</script>

<style scoped lang="scss">
	.preview-field {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.field,
	.user-box {
		width: calc(50% - 10px);
		.button {
			width: 100%;
		}
	}
	@media($mobileM){
		.preview-field {
			flex-direction: column;
		}
		.field,
		.user-box {
			width: 100%;
		}
		.user-box {
			margin-bottom: 15px;
		}
	}
</style>