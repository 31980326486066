<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<mobility-content-box :data="mobility"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import MobilityContentBox from "../components/containers/MobilityContentBox";
	export default {
		name: "MobilityDetail",
		components: {
			MobilityContentBox
		},
		data() {
			return {
				mobility: this.$store.state.views.mobilityDetail,
			}
		},
		computed: {
		}

	}
</script>

<style scoped>

</style>