import { PushNotifications } from '@capacitor/push-notifications';
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import axios from "axios";

const pushSubscriptionMixin = {
	data() {
		return {
			pushNotificationEnabled: false,
			pushNotificationLoading: false,
			nativePushNotificationPermission: false,
			pushNotificationNativeToken: false,
			pushNotificationRegisterType: '',
		}
	},
	methods: {
		checkPushNotificationSetting() {
			if (!this.$store.state.app.native) {
				return false;
			}

			PushNotifications.checkPermissions().then(data => {
				if (data.receive === 'prompt') {
					PushNotifications.requestPermissions().finally(() => {
						this.checkPushNotificationNotice();
					});
					return false;
				}
				if (data.receive !== 'granted') {
					this.checkPushNotificationNotice();
					return false;
				}

				this.nativePushNotificationPermission = true;
				this.pushNotificationRegisterType = 'check';

				PushNotifications.register();
			});

			//this.subscribeNative();
		},

		/**
		 * Register the service worker.
		 */
		registerServiceWorker() {
			if (this.$store.state.app.native) {
				return false;
			}

			if (!('serviceWorker' in navigator)) {
				return false;
			}

			navigator.serviceWorker.register('/service-worker.js')
				.then(() => this.initialiseServiceWorker())
		},

		initialiseServiceWorker() {
			if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
				return false;
			}

			if (Notification.permission === 'denied') {
				return false;
			}

			if (!('PushManager' in window)) {
				return false;
			}

			navigator.serviceWorker.ready.then(registration => {
				registration.pushManager.getSubscription()
					.then(subscription => {
						this.pushButtonDisabled = false

						if (!subscription) {
							return
						}

						this.updateSubscriptionWeb(subscription)

						this.pushNotificationEnabled = true;
					})
					.catch(e => {
						console.log('Error during getSubscription()', e)
					})
			});
		},

		subscribeWeb() {
			navigator.serviceWorker.ready.then(registration => {
				const options = { userVisibleOnly: true }
				const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

				if (vapidPublicKey) {
					options.applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey)
				}

				registration.pushManager.subscribe(options)
					.then(subscription => {
						this.pushNotificationEnabled = true;
						this.pushButtonDisabled = false;

						this.updateSubscriptionWeb(subscription);
					})
					.catch(e => {
						console.log(e);
						/*if (Notification.permission === 'denied') {
							console.log('Permission for Notifications was denied')
							this.pushButtonDisabled = true
						} else {
							console.log('Unable to subscribe to push.', e)
							this.pushButtonDisabled = false
						}*/
					});
			});
		},

		subscribeNative() {
			PushNotifications.requestPermissions().then((data) => {
				if (data.receive !== 'granted') {
					this.$swal.fire({
						icon: 'info',
						title: this.$t('settings.push_notification_denied_popup_title'),
						text: this.$t('settings.push_notification_denied_popup_text'),
					}).then(() => {
						if (this.$store.state.app.platform === 'ios') {
							NativeSettings.openIOS({
								option: IOSSettings.App
							});
						} else if (this.$store.state.app.platform === 'android') {
							NativeSettings.openAndroid({
								option: AndroidSettings.ApplicationDetails
							});
						}
					});

					return false;
				}

				this.nativePushNotificationPermission = true;
				this.pushNotificationRegisterType = 'register';

				return PushNotifications.register();
			});
		},

		/**
		 * Subscribe for push notifications.
		 */
		subscribe() {
			if (this.$store.state.app.native) {
				this.subscribeNative();
			} else {
				this.subscribeWeb();
			}
		},

		unsubscribeWeb() {
			navigator.serviceWorker.ready.then(registration => {
				registration.pushManager.getSubscription().then(subscription => {
					if (!subscription) {
						this.pushNotificationEnabled = false;
						this.pushButtonDisabled = false;
						return;
					}

					subscription.unsubscribe().then(() => {
						this.deleteSubscriptionWeb(subscription)

						this.pushNotificationEnabled = false;
						this.pushButtonDisabled = false;
					}).catch(e => {
						console.log('Unsubscription error: ', e)
						this.pushButtonDisabled = false;
					})
				}).catch(e => {
					console.log('Error thrown while unsubscribing.', e)
				})
			});
		},

		unsubscribeNative() {
			if (!this.pushNotificationNativeToken) {
				return false;
			}

			this.pushNotificationLoading = true;
			this.axios.post('/api/native-tokens', {
				_method: 'DELETE',
				token: this.pushNotificationNativeToken,
				type: this.$store.state.app.platform
			}).then(() => {
				this.$store.state.auth.user.native_tokens = this.$store.state.auth.user.native_tokens.filter(item => item.token !== this.pushNotificationNativeToken);
				this.pushNotificationLoading = false;
				this.pushNotificationEnabled = false;
			}).catch((error) => {
				this.pushNotificationLoading = false;
			});
		},

		/**
		 * Unsubscribe from push notifications.
		 */
		unsubscribe() {
			if (this.$store.state.app.native) {
				this.unsubscribeNative();
			} else {
				this.unsubscribeWeb();
			}
		},

		/**
		 * Toggle push notifications subscription.
		 */
		togglePushNotifications(value) {
			if (!value) {
				this.unsubscribe()
			} else {
				this.subscribe()
			}
		},

		/**
		 * Send a request to the server to update user's subscription.
		 *
		 * @param {PushSubscription} subscription
		 */
		updateSubscriptionWeb(subscription) {
			const key = subscription.getKey('p256dh')
			const token = subscription.getKey('auth')
			const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

			const data = {
				endpoint: subscription.endpoint,
				publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
				authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
				contentEncoding
			}

			this.pushNotificationLoading = true;
			this.axios.post('/api/subscriptions', data).then(() => {
				this.pushNotificationLoading = false;
			}).catch(() => {
				this.pushNotificationLoading = false;
			});
		},

		/**
		 * Send a requst to the server to delete user's subscription.
		 *
		 * @param {PushSubscription} subscription
		 */
		deleteSubscriptionWeb(subscription) {
			this.pushNotificationLoading = true;

			this.axios.post('/api/subscriptions', {
				endpoint: subscription.endpoint,
				_method: 'DELETE',
			}).then(() => {
				this.pushNotificationLoading = false;
			});
		},

		/**
		 * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
		 *
		 * @param  {String} base64String
		 * @return {Uint8Array}
		 */
		urlBase64ToUint8Array(base64String) {
			const padding = '='.repeat((4 - base64String.length % 4) % 4)
			const base64 = (base64String + padding)
				.replace(/\-/g, '+')
				.replace(/_/g, '/')

			const rawData = window.atob(base64)
			const outputArray = new Uint8Array(rawData.length)

			for (let i = 0; i < rawData.length; ++i) {
				outputArray[i] = rawData.charCodeAt(i)
			}

			return outputArray
		},

		listenRegisterEvent() {
			if (!this.$store.state.app.native) {
				return false;
			}

			PushNotifications.addListener('registration', (data) => {
				if (!this.nativePushNotificationPermission) {
					return false;
				}

				this.pushNotificationNativeToken = data.value;

				if (this.$store.state.auth.user.native_tokens && this.$store.state.auth.user.native_tokens.find(item => item.token === data.value)) {
					this.pushNotificationEnabled = true;
					return false;
				}

				if (this.pushNotificationRegisterType === 'check') {
					this.checkPushNotificationNotice();
					return false;
				}

				this.pushNotificationLoading = true;
				this.axios.post('/api/native-tokens', {
					token: data.value,
					type: this.$store.state.app.platform
				}).then((response) => {
					if (!this.$store.state.auth.user.native_tokens) {
						this.$store.state.auth.user.native_tokens = [];
					}
					this.$store.state.auth.user.native_tokens.push(response.data.token);
					this.pushNotificationEnabled = true;
					this.pushNotificationLoading = false;
				}).catch(() => {
					this.pushNotificationLoading = false;
				});
			});

			PushNotifications.addListener('registrationError', error => {
				console.log('registration error');
				console.log(error);
			});
		},

		removePushNotificationListeners() {
			if (!this.$store.state.app.native) {
				return false;
			}
			PushNotifications.removeAllListeners();
		},

		checkPushNotificationNotice() {
			if (this.pushNotificationEnabled) {
				return;
			}

			if (this.$store.state.auth.user.settings.push_notification_notice && this.$store.state.auth.user.settings.push_notification_notice === this.$store.state.app.version) {
				return;
			}

			this.$swal.fire({
				icon: 'question',
				title: this.$t('settings.push_notification_popup_title'),
				text: this.$t('settings.push_notification_popup_text'),
				confirmButtonText: this.$t('general.yes'),
				cancelButtonText: this.$t('general.no'),
				showConfirmButton: true,
				showCancelButton: true
			}).then(result => {
				if (result.isConfirmed) {
					this.$store.commit('TOGGLE_SETTINGS');
					this.subscribeNative();
				}

				this.axios.post('/api/user/settings', {
					_method: 'PUT',
					name: 'push_notification_notice',
					value: this.$store.state.app.version,
				});
			});
		}

	},


};

export default pushSubscriptionMixin;