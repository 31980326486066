<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_iad_account.title') }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments_iad.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData" v-if="funds.length"/>
					<p v-else>{{ $t('investments.no_data') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import investmentsMixin from "@/mixins/investmentsMixin";
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";
import funds from '@/data/funds';

export default {
	name: "InvestmentsIadAccount",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	mixins: [investmentsMixin],
	computed: {
		investmentsIadAccount() {
			return this.$store.state.views.investmentsIadAccount;
		},
		funds() {
			let data = [];
			this.investmentsIadAccount.funds.forEach(item => {
				data.push(item);
			});

			return data;
		},
		chartsData() {
			let data = [
				this.balanceChart,
				this.portfoliosChart,
			];

			return data;
		},
		balanceChart() {
			let data = this.getBalanceChartOptions();

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			let transactions = [];
			let deposit = 0;
			let totalDeposits = 0;
			let totalWithdrawals = 0;
			let totalSwitch = 0;
			let fees = 0;
			let funds = {};
			let totalBalance = 0;


			this.transactions.forEach(item => {
				if (!funds[item.ISIN]) {
					funds[item.ISIN] = {
						isin: item.ISIN,
						totalAmount: 0,
						lastRate: 0,
					};
				}

				funds[item.ISIN].totalAmount += item.ObjemOperacievPL;
				funds[item.ISIN].lastRate = item.CenaZaKus;

				let itemBalance = item.ObjemOperacieVmene;
				if (item.ObjemOperacievPL < 0) {
					itemBalance = itemBalance * -1;
				}
				if (item.TypOperacie === 'p') {
					totalWithdrawals += item.ObjemOperacieVmene;
				}
				if (item.TypOperacie === 'k' || item.TypOperacie === 'z' || item.TypOperacie === 'r') {
					totalDeposits += item.ObjemOperacieVmene;
				}
				if (item.TypOperacie === 'sw') {
					totalSwitch += item.ObjemOperacieVmene;
				}

				let currentValue = 0;
				for (let fund in funds) {
					currentValue += funds[fund].totalAmount * funds[fund].lastRate;
				}

				fees += item.PoplatkyOperacieVmene;

				totalBalance = currentValue;
				deposit += itemBalance;
				if (deposit < 0){
					deposit = 0;
				}

				series[0].data.push({
					x: item.Datum,
					y: totalBalance.toFixed(2),
				});

				series[1].data.push({
					x: item.Datum,
					y: deposit.toFixed(2),
					amount: itemBalance,
					data: item,
				});

			});

			let currentDate = new Date().toISOString().slice(0, 10);
			series[0].data.push({
				x: currentDate,
				y: this.totalAccountBalance,
			});

			series[1].data.push({
				x: currentDate,
				y: deposit.toFixed(2),
			});

			totalWithdrawals = totalWithdrawals - totalSwitch;

			data.series = series;
			console.clear();
			series[1].data.forEach(item => {
				const parsedDate = new Date(item.x);

				const day = parsedDate.getDate();
				const month = parsedDate.getMonth() + 1;
				const year = parsedDate.getFullYear();
				const hours = parsedDate.getHours();
				const minutes = parsedDate.getMinutes();
				const seconds = parsedDate.getSeconds();

				const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;

				console.log(`Date: ${formattedDate}`);
				console.log(`Current value: ${item.y}`);
				console.log(`Amount: ${item.amount}`);
				console.log(item.data);
			});

			data.totalDeposits = totalDeposits;
			data.totalWithdrawals = totalWithdrawals;
			data.totalFees = fees;

			data.totalBalance = this.totalAccountBalance;
			data.totalReturn = this.investmentsIadAccount.profit;

			return data;
		},
		portfoliosChart() {
			let data = this.getPortfoliosChartOptions();

			let series = [];
			let labels = [];

			this.funds.forEach(item => {
				series.push(item.ObejmInvesticieEur);

				let name = item.NazovFondu;
				let isin = item.ISIN.trim();
				if (item.NazovFondu.trim() === isin) {
					name = funds[isin];
				}
				labels.push(name);
			});

			data.chartOptions.labels = labels;
			data.series = series;

			return data;
		},
		panelsData() {
			let data = [];

			this.investmentsIadAccount.funds.forEach(item => {
				let currency = this.getCurrencySymbol(item.Mena);

				let totalBalance = 0;
				let transactions = this.transactions.filter((transaction) => {
					return item.ISIN === transaction.ISIN;
				});

				transactions.forEach(transaction => {
					switch (transaction.TypOperacie) {
						case 'k':
						case 'z':
						case 'sw':
							totalBalance += transaction.ObjemOperacieVmene;
							break;
						case 'p':
							totalBalance -= transaction.ObjemOperacieVmene;
							break;
					}
				});

				let returnPercentage = (item.ObejmInvesticie / totalBalance) * 100 - 100;

				let name = item.NazovFondu;
				let isin = item.ISIN.trim();
				if (item.NazovFondu.trim() === isin) {
					name = funds[isin];
				}

				data.push({
					id: item.id,
					category: this.$t('investments.fund'),
					name: name,
					description: this.$t('investments.fund_balance'),
					price: item.ObejmInvesticie,
					returnPercentage: returnPercentage,
					type: 'portfolio',
					portfolioType: 'iad',
					isin: isin,
					currency: currency,
					detail: !!item.ISIN,
				});
			});

			return data;
		},
		transactions() {
			if (!this.investmentsIadAccount) {
				return [];
			}

			let transactions = this.investmentsIadAccount.transactions;
			transactions = transactions.sort((a, b) => {
				return new Date(a.Datum) - new Date(b.Datum);
			});

			console.clear();
			transactions.forEach((item) => {
				console.log(item.TypOperacie);
				console.log(item.ObjemOperacieVmene);
			})

			return transactions;
		},
		totalAccountBalance() {
			if (!this.investmentsIadAccount) {
				return 0;
			}

			let total = 0;
			this.funds.forEach(item => {
				total += item.ObejmInvesticieEur;
			});

			return total.toFixed(2);
		},
		totalFees() {
			if (!this.investmentsIadAccount) {
				return 0;
			}

			let total = 0;
			this.investmentsIadAccount.monthlyFees.forEach(item => {
				total += item.refAmount;
			});

			return total.toFixed(2);
		}
	}
}
</script>

<style scoped>

</style>