<template>
	<header class="app-header">
		<div class="content-wrap">
			<div class="container">
				<app-logo link="/"/>
				<nav class="user-nav">
					<ul class="nav-list">
						<li>
							<button class="user-nav-item" @click.stop="$store.dispatch('toggleNotificationsSidebar')">
								<span class="count"
								      v-if="$store.state.auth.user.not_shown_notifications_count">{{ $store.state.auth.user.not_shown_notifications_count }}</span>
								<i class="fa-thin fa-bell"></i>
							</button>
						</li>
						<li>
							<button class="user-nav-item" @click.stop="$store.commit('TOGGLE_SETTINGS')">
								<i class="fa-thin fa-gear"></i>
							</button>
						</li>
						<li>
							<div class="user-nav-box" v-if="$store.state.auth.user.full_name">
								<figure class="image fit-image">
									<router-link to="/profil">
										<img :src="getImage($store.state.auth.user.avatar.filename)"
										     v-if="$store.state.auth.user.avatar">
										<img src="@/assets/img/user-sample.jpg" v-else>
									</router-link>
								</figure>
								<div class="text">
									{{ greeting }},
									<router-link to="/profil">{{ $store.state.auth.user.full_name }}!</router-link>
								</div>
							</div>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import AppLogo from "./AppLogo";

	export default {
		name: "AppHeader",
		components: {
			AppLogo
		},
		mixins: [pageFunctionsMixin],
		computed:{
			greeting() {
				let hours = new Date().getHours();
				if (hours <= 7 && hours > 4) {
					return this.$t('greetings.morning');
				} else if (hours <= 16) {
					return this.$t('greetings.day');
				} else {
					return this.$t('greetings.evening');
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.user-nav-box {
			a {
				color: var(--background-color, $backgroundColor);
			}
			.image {
				border: 1px solid var(--background-color, $backgroundColor);
			}
		}
		.nav-list {
			.user-nav-item {
				color: var(--background-color, $backgroundColor);
			}
			li {
				border-right: 1px solid rgba(var(--background-color-rgb), 0.1);
			}
		}
	}
	.app-header {
		padding: 50px 0;
		position: relative;
		z-index: 10;
		@media($mobileM) {
			padding: 10px 0 15px;
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.nav-list {
		display: flex;

		.user-nav-item {
			font-size: 21px;
			background: transparent;
			padding: 0;
			margin: 0;
			border: none;
			color: var(--white-color, $whiteColor);
			position: relative;
			line-height: 1;
			cursor: pointer;
			transition: $animationDefaultDuration;

			.count {
				position: absolute;
				display: inline-block;
				font-size: 15px;
				line-height: 1.5;
				color: var(--white-color, $whiteColor);
				font-weight: 700;
				border-radius: 4px;
				background: var(--primary-gradient, $primaryGradient);
				padding: 5px 8px;
				right: calc(100% + 12px);
				top: -5px;

				&:after {
					content: '';
					position: absolute;
					display: block;
					width: 12px;
					height: 12px;
					transform: rotate(45deg);
					right: -3px;
					top: calc(50% - 6px);
					background: var(--end-gradient-color, $endGradientColor);
					z-index: -1;
				}
			}

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}

		li {
			padding: 5px 20px;
			//border-right: 1px solid transparentize($whiteColor, .9);
			border-right: 1px solid rgba(var(--white-color-rgb), .1);
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:last-child {
				border-right: 0;
				padding-right: 0;
			}
		}

		@media($tabletL) {
			li {
				padding: 5px 12px;
			}
		}

	}


	.user-nav-box {
		display: flex;
		align-items: center;

		.image {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			position: relative;
			border: 1px solid var(--white-color, $whiteColor);
			margin-right: 15px;
			overflow: hidden;

			img {
				border-radius: 100%;
			}

			a {
				display: block;
			}

			img {
				padding: 2px;
			}
		}

		a {
			font-weight: 400;
			color: var(--white-color, $whiteColor);

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}

		@media($tabletL) {
			.image {
				margin-right: 0;
			}
			.text {
				display: none;
			}
		}
	}
</style>