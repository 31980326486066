import { render, staticRenderFns } from "./ProtocolDetailSidebar.vue?vue&type=template&id=618b1f57&scoped=true&"
import script from "./ProtocolDetailSidebar.vue?vue&type=script&lang=js&"
export * from "./ProtocolDetailSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618b1f57",
  null
  
)

export default component.exports