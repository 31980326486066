import {Storage} from '@capacitor/storage';
import {Device} from "@capacitor/device";

const pinMixin = {
	data() {
		return {
			hasPin: false,
			pinLoading: false,
		}
	},
	computed: {
		async pinEnabled() {
			let pin = await Storage.get({
				key: 'user_pin'
			});

			if (pin && pin.value){
				this.hasPin = true;
				return true;
			}

			return false;
		}
	},
	methods: {
		togglePinLogin(value){
			if (value){
				this.openSetupPinPopup();
			} else {
				this.removePinLogin();
			}
		},

		openSetupPinPopup(){
			this.$store.commit('OPEN_SETUP_PIN_POPUP');
		},

		async removePinLogin(){
			this.pinLoading = true;
			const deviceId = await Device.getId();

			this.axios.post('/api/user/delete-native-auth', {
				device_id: deviceId.uuid,
				type: 'pin',
			}).then(() => {
				this.hasPin = false;
				this.pinLoading = false;
				Storage.remove({
					key: 'user_pin'
				});
			}).catch(() => {
				this.pinLoading = false;
			});
		}
	},
	mounted(){
		this.$root.$on('pin-login-setup', () => {
			this.hasPin = true;
		});
	}
};

export default pinMixin;