<template>
    <div class="icon-link-boxes-container" :class="extraClass">
        <icon-link-box v-for="item in data" :key="item.id" :data="item" :extra-class="boxExtraClass"/>
    </div>
</template>

<script>
    import IconLinkBox from "./IconLinkBox";

    export default {
        name: "IconLinkBoxesContainer",
        props: ['data', 'extraClass', 'boxExtraClass'],
        components: {
            IconLinkBox
        }
    }
</script>

<style scoped lang="scss">
    .default-sidebar {
        .icon-link-boxes-container {
            justify-content: space-between;
            .icon-link-box {
                width: calc( 50% -  10px);
                margin-right: 0;
                &:nth-child(n + 3){
                    margin-top: 20px;
                }
            }
        }
    }
    .contact-info-box {
        .icon-link-boxes-container {
            .icon-link-box {
                margin-right: 4px;
                width: calc(33.333% - (8px / 3));
                &:nth-child(3n + 3){
                    margin-right: 0;
                }
                &:nth-child(n + 3){
                    margin-top: 0;
                }
            }
        }
    }
    .icon-link-boxes-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        //gap: 4px;
        &.flex-grow-boxes {
            .icon-link-box {
                flex-grow: 1;
            }
        }
        .icon-link-box {
            //flex-grow: 1;
            margin-right: 4px;
            width: calc(33.333% - (8px / 3));

        }
        @media(min-width: 481px){
            .icon-link-box {
                &:nth-child(3n + 3){
                    margin-right: 0;
                }
                &:nth-child(n + 4){
                    margin-top: 4px;
                }
            }
        }
        @media($mobileM){
            justify-content: space-between;
            &.flex-grow-boxes {
                .icon-link-box {
                    flex-grow: initial;
                }
            }
            &.with-scroll {
                overflow-x: auto;
                flex-wrap: nowrap;
                padding: 6px 15px 0;
                margin-top: -6px;
                margin-left: -15px;
                margin-right: -15px;
                .icon-link-box {
                    margin-right: 4px;
                    width: 40%;
                    min-width: 40%;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:nth-child(n + 3){
                        margin-top: 0;
                    }
                }
            }
            .icon-link-box {
                //flex-grow: 1;
                width: calc(50% - 2px);
                margin-right: 0;
                &:nth-child(n + 3){
                    margin-top: 4px;
                }
            }
        }
    }
</style>