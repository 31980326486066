<template>
	<div class="native-landing-screen">
		<div class="content-wrap">
			<div class="inner">
				<app-logo/>
				<div class="text-actions">
					<div class="text">
						<h2>{{ greeting }}</h2>
						<p>{{ $t('auth.login_text') }}</p>
					</div>
					<div class="actions" v-if="!userDefaultEmail && !userDefaultId">
						<button type="button" class="button style-4" @click="openLoginPopup">
							<span class="inner">{{ $t('auth.login') }}</span>
						</button>
						<!--<button type="button" class="button style-5">
							<span class="inner">Vyskúšať demo účet</span>
						</button>-->
						<button type="button" class="button style-1" @click="openRegistrationPopup">
							<span class="inner">{{ $t('auth.create_account') }}</span>
						</button>
					</div>
					<div class="actions" v-else>
						<button type="button" class="button style-4" :class="loading ? 'loading': ''"
						        @click="loginNative" v-if="hasNativeAuth && !pinCanceled">
							<span class="inner">{{ $t('auth.login') }}</span>
						</button>
						<button type="button" class="button style-4" @click="openLoginPopup" v-else>
							<span class="inner">{{ $t('auth.login') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<pin-login-popup :device-id="pinCredentials" v-if="$store.state.popups.showPinLoginPopup"/>
	</div>
</template>

<script>
import AppLogo from "../partials/AppLogo";
import { NativeBiometric } from 'capacitor-native-biometric';
import { Storage } from '@capacitor/storage';
import PinLoginPopup from "@/components/popups/PinLoginPopup";

export default {
	name: "NativeLandingScreen",
	components: {
		AppLogo,
		PinLoginPopup,
	},
	data() {
		return {
			biometricCanceled: false,
			pinCanceled: false,
			biometricCredentials: null,
			pinCredentials: null,
			hasNativeAuth: false,
			loading: false,
		}
	},
	computed: {
		greeting() {
			let hours = new Date().getHours();
			if (hours <= 7 && hours > 4) {
				return this.$t('greetings.morning');
			} else if (hours <= 16) {
				return this.$t('greetings.day');
			} else {
				return this.$t('greetings.evening');
			}
		},
		userDefaultEmail() {
			return this.$store.state.auth.userDefaultEmail;
		},
		userDefaultId() {
			return this.$store.state.auth.userDefaultId;
		},

		async pinAuth() {
			const pinAuth = await Storage.get({
				key: 'user_pin'
			});

			if (pinAuth && pinAuth.value) {
				this.pinCredentials = pinAuth.value;
				return true;
			}

			return false;
		},
	},
	methods: {
		async checkNativeAuth() {
			const pin = await this.pinAuth;
			if ((this.biometricCredentials) || (pin && this.pinCredentials)) {
				this.hasNativeAuth = true;
				return true;
			}

			return false;
		},
		openLoginPopup() {
			this.$store.dispatch('openLoginPopup');
		},
		openRegistrationPopup() {
			this.$store.dispatch('openRegistrationPopup');
		},
		async loginNative() {
			if (this.biometricCredentials) {
				this.loginBiometrically();
				return true;
			}

			if (this.pinCredentials) {
				this.$store.dispatch('openPinLoginPopup');
				return true;
			}

			return false;
		},
		loginBiometrically() {
			NativeBiometric.verifyIdentity({
				reason: this.$t('auth.biometric_log_in'),
				//title: "Log in",
				//subtitle: "Maybe add subtitle here?",
				//description: "Maybe a description too?",
			}).then(() => {
				this.loading = true;

				let formData = new FormData();
				formData.append('device_id', this.biometricCredentials.username);
				formData.append('hash', this.biometricCredentials.password);
				formData.append('type', 'biometric');

				this.$store.dispatch('nativeLogin', formData).catch((error) => {
					this.loading = false;
					this.cancelBiometricLogin();
				});
			}).catch(() => {
				this.cancelBiometricLogin();
			});
		},
		cancelBiometricLogin() {
			this.biometricCanceled = true;
			if (this.pinCredentials) {
				this.$store.dispatch('openPinLoginPopup');
			} else {
				this.openLoginPopup();
			}
		},
		loginWithPassword() {
			this.pinCanceled = true;
			this.openLoginPopup();
		},
		async checkBiometricAuth() {
			if (!this.userDefaultEmail || this.biometricCanceled) {
				return false;
			}

			let availableBiometric = await NativeBiometric.isAvailable();
			if (!availableBiometric.isAvailable) {
				return false;
			}

			try {
				this.biometricCredentials = await NativeBiometric.getCredentials({
					server: process.env.VUE_APP_CAP_ID_ANDROID,
				});
				return true;
			} catch (error) {
				return false;
			}
		},
	},
	async mounted() {
		this.$store.dispatch('closePopups');
		this.$root.$on('login-with-password', this.loginWithPassword);
		this.loading = false;

		await this.checkBiometricAuth();
		await this.checkNativeAuth();
	},
	async updated() {
		await this.checkBiometricAuth();
		await this.checkNativeAuth();
	}
}
</script>

<style scoped lang="scss">
.native-landing-screen {
	.content-wrap {
		> .inner {
			padding: 40px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			min-height: 100vh;
		}
	}

	.logo {
		max-width: 144px;
		margin-top: auto;
		margin-bottom: 35px;
	}

	@media($mobileS) {
		.logo {
			max-width: 100px;
		}
	}
}

.text-actions {
	margin-top: auto;
	width: 100%;
}

.text {
	color: var(--white-color, $whiteColor);
	margin-bottom: 50px;

	&:last-child {
		margin-bottom: 0;
	}

	h2 {
		margin-bottom: 15px;
	}

	p {
		font-weight: 700;
		font-size: 15px;
	}
}

.actions {
	.button {
		width: 100%;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>