<template>
	<default-popup extra-class="complete-registration-popup no-transparent" :disable-close="true">
		<div class="content">
			<h2 class="popup-title">{{ $t('auth.please_complete_account') }}</h2>
			<complete-registration-form/>
		</div>
	</default-popup>
</template>

<script>
	import DefaultPopup from "./DefaultPopup";
	import CompleteRegistrationForm from "../forms/CompleteRegistrationForm";

	export default {
		name: "CompleteRegistrationPopup",
		components: {
			DefaultPopup,
			CompleteRegistrationForm
		},
		data() {
			return {

			}
		},
	}
</script>

<style scoped lang="scss">
	.popup-inner {
		border-radius: 0;
		.default-form {
			max-width: 100%;
			margin-top: 50px;
		}
	}
</style>