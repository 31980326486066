<template>
	<header class="public-header">
		<div class="content-wrap">
			<div class="container">
				<div class="left">
					<app-logo :link="$t('routes.landing.path')"/>
					<!--<ul class="pages-list">
						<li><a href="#">Blog</a></li>
						<li><a href="#">Videonávody</a></li>
						<li><a href="#">Kontakt</a></li>
					</ul>!-->
				</div>
				<div class="right">
					<ul class="auth-list" v-if="!$store.state.auth.isLoggedIn">
						<li>
							<button class="button style-4" @click="openLoginPopup">
								<span class="inner">{{ $t('auth.login') }}</span>
							</button>
						</li>
						<li>
							<button class="button style-2" @click="openRegistrationPopup">
								<span class="inner">{{ $t('auth.registration') }}</span>
							</button>
						</li>
					</ul>
					<ul class="auth-list" v-else>
						<li>
							<router-link to="/" class="button style-4">
								<span class="inner">{{ $t('general.go_to_app') }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import AppLogo from "./AppLogo";

	export default {
		name: "PublicHeader",
		components: {
			AppLogo
		},
		computed: {

		},
		methods: {
			openLoginPopup() {
				this.$store.dispatch('openLoginPopup');
			},
			openRegistrationPopup() {
				this.$store.dispatch('openRegistrationPopup');
			}
		}
	}
</script>

<style scoped lang="scss">
	@media(min-width: 768px){
		.landing {
			.public-header {
				position: absolute;
				left: 0;
				right: 0;
				top: 60px;
				padding: 0;
				background: transparent;
			}
		}
	}


	.public-header {
		padding: 60px 0;
		position: relative;
		z-index: 10;
	}

	.button.style-2 {
		box-shadow: 0 20px 30px transparentize($blackColor, .95);
	}


	.content-wrap {
		max-width: calc(100% - 60px);
	}

	.logo {
		margin-right: 50px;
		//padding-right: 50px;
		//border-right: 1px solid transparentize($backgroundColor, .9);
		//border-right: 1px solid rgba(var(--background-color), .1);
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
		}
	}

	.auth-list {
		display: flex;

		.button {
			max-width: 240px;
			width: 240px;
		}

		li + li {
			margin-left: 20px;
		}
	}

	.pages-list {
		display: flex;

		li + li {
			margin-left: 50px;
		}

		a {
			font-weight: 600;
			text-transform: uppercase;
			color: var(--background-color, $backgroundColor);

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}
	}

	@media($desktopM) {
		.content-wrap {
			max-width: 100%;
		}
		.logo {
			padding-right: 25px;
			margin-right: 25px;
		}
		.pages-list {
			li + li {
				margin-left: 25px;
			}
		}
		.auth-list {
			.button {
				width: 175px;
				max-width: 175px;
			}
		}
	}

	@media($tabletL) {
		.landing {
			.public-header {
				top: 0;

				.content-wrap {
					padding: 0;
				}
			}
		}
		.public-header {
			padding: 25px 0;
		}


		.button.style-4 {
			&:hover {
				background: var(--primary-color, $primaryColor);
				border-color: var(--primary-color, $primaryColor);
				color: var(--white-color, $whiteColor);
			}
		}
		.logo {
			padding-right: 15px;
			margin-right: 15px;
		}
		.auth-list {
			li + li {
				margin-left: 10px;
			}

			.button {
				width: initial;
				padding: 15px 25px;
			}
		}
		.pages-list {
			font-size: 14px;

			li + li {
				margin-left: 15px;
			}
		}
		.button.style-5 {
			background: var(--background-color, $backgroundColor);
			border-color: var(--background-color, $backgroundColor);

			&:hover {
				background: var(--primary-color, $whiteColor);
				border-color: var(--primary-color, $whiteColor);
				color: var(--white-color, $whiteColor);
			}
		}
	}

	@media($tabletM) {
		.landing {
			.public-header {
				position: static;
				.content-wrap {
					padding: 0 15px;
				}
			}
		}
		.public-header {
			border-radius: 0;
		}
		.container {
			flex-direction: column;

			.right {
				margin-top: 15px;
			}
		}
		.auth-list {
			.button {
				width: 185px;
				max-width: 185px;
			}
		}
	}

	@media($mobileM) {
		.right {
			width: 100%;
		}
		.logo {
			padding-right: 12px;
			margin-right: 12px;
		}
		.pages-list {
			font-size: 12px;

			li + li {
				margin-left: 12px;
			}
		}
		.auth-list {
			width: 100%;
			justify-content: center;
			//flex-direction: column;
			li + li {
				margin-left: 10px;
			}

			.button {
				max-width: 155px;
				width: 155px;
			}
		}
		@media($mobileS) {
			.auth-list {
				justify-content: space-between;

				li {
					width: calc(50% - 5px);
				}

				.button {
					width: 100%;
					max-width: 100%;
					padding: 15px;
				}
			}
			.pages-list {
				li + li {
					margin-left: 8px;
				}
			}
			.logo {
				padding-right: 8px;
				margin-right: 8px;
			}
		}
	}
</style>