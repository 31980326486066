<template>
	<form class="default-form" @submit.prevent="submitForm">
		<input type="hidden" name="hash" :value="hash">
		<errors-list :errors="errors"/>
		<ul class="links-list" v-if="data.file_documentation_url_links.length">
			<li v-for="(item, key) in data.file_documentation_url_links" :key="key">
				<a :href="item.url">{{ item.title }}</a>
			</li>
		</ul>
		<div class="form-row">
			<default-field-box type="text" name="first_name" :placeholder="$t('forms.first_name')" :required="true"/>
		</div>
		<div class="form-row">
			<default-field-box type="text" name="last_name" :placeholder="$t('forms.last_name')" :required="true"/>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="terms">
				<span class="status"></span>
				<span class="field-title"><a :href="data.file_consent_url" target="_blank">{{
						data.text_checkbox_title
					}}</a></span>
			</label>
			<div class="checkbox-description">
				<p>{{ data.text_warning }}</p>
			</div>
		</div>
		<div class="form-row">
			<h4 class="form-section-title">{{ $t('protocols.documentation_agreement') }}</h4>
		</div>
		<div class="form-row">
			<div class="options-group">
				<label class="radio-box">
					<input type="radio" name="documentation_agreement" value="1" v-model="documentation_agreement">
					<span class="status"></span>
					<span class="field-title">{{ $t('forms.agree') }}</span>
				</label>
				<label class="radio-box">
					<input type="radio" name="documentation_agreement" value="0" v-model="documentation_agreement">
					<span class="status"></span>
					<span class="field-title">{{ $t('forms.disagree') }}</span>
				</label>
				<div class="radio-description">
					<p>{{ data.text_consent }}</p>
				</div>
			</div>
		</div>
		<div class="form-actions">
			<p class="cant-sign" v-if="statusToSign.includes(this.data.status_dokumentu_id) && !canSign">
				{{ $t('protocols.personal_document_id_required') }}
			</p>
			<button class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('protocols.sign_protocol') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
import DefaultFieldBox from "../form-fields/DefaultFieldBox";
import ErrorsList from "../containers/ErrorsList";
import protocolMixin from "../../mixins/protocolMixin";

export default {
	name: "ProtocolsSignForm",
	components: {
		DefaultFieldBox,
		ErrorsList,
	},
	props: ['data', 'hash'],
	mixins: [protocolMixin],
	data() {
		return {
			errors: false,
			documentation_agreement: '',
			loading: false,
		}
	},
	computed: {
		canSign() {
			return this.$store.state.auth.user.can_sign;
		},
	},
	methods: {
		async submitForm(e) {
			if (this.documentation_agreement === '0') {
				let result = await this.$swal.fire({
					icon: 'question',
					title: this.$t('protocols.refuse_protocol_title'),
					text: this.$t('protocols.refuse_protocol_text'),
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: this.$t('general.yes'),
					cancelButtonText: this.$t('general.no')
				});

				if (!result.isConfirmed) {
					return false;
				}
			}

			let formData = new FormData(e.target);

			this.loading = true;
			this.axios.post(`/api/protocols/sign-protocol`, formData).then(response => {
				this.loading = false;

				//UPDATE
				let text = this.$t('protocols.text_approved');
				if (this.documentation_agreement === '0') {
					text = this.$t('protocols.text_refused');
				}

				this.$store.dispatch('updateProtocol', response.data.protocol);
				this.$swal.fire({
					icon: 'success',
					title: this.$t('protocols.protocol'),
					text: text
				});

				this.$store.commit('HIDE_PROTOCOL_DETAIL');
			}).catch(error => {
				if (error.response.data.errors) {
					this.errors = error.response.data.errors;
				}

				this.loading = false;

				this.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: error.response.data.message
				}).then(() => {
					if (!error.response.data.open_protocol) {
						return false;
					}

					let protocol = this.$store.state.views.protocols.find(item => {
						return item.hash === error.response.data.open_protocol;
					});

					if (!protocol) {
						return false;
					}

					if (this.$store.state.sidebars.showProtocolDetail) {
						this.$store.commit('HIDE_PROTOCOL_DETAIL');
						setTimeout(() => {
							this.$store.commit('SHOW_PROTOCOL_DETAIL', protocol);
						}, 400);
						return false;
					}
				});
			});
		},
	},
}
</script>

<style scoped lang="scss">
.links-list {
	font-size: 14px;
	font-weight: 600;

	li {
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.options-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.radio-box {
		width: 50%;
	}

	.radio-description {
		width: 100%;
	}
}
</style>