<template>
	<div class="form-group" :class="extraClasses">
		<label v-if="label">{{ label }}</label>
		<div class="field" :class="type">
			<input :type="fieldType" :name="name" :placeholder="placeholder" @blur.prevent="validateField"
			       v-model="inputValue"
			       @keyup="handleKeyUp"
			       @change="dispatchEvent"
			       :readonly="readonly"
			       :autocomplete="autocomplete"
			       :disabled="disabled"
			       v-if="type !== 'textarea'">
			<textarea :name="name" :placeholder="placeholder" @blur.prevent="validateField"
			          v-model="inputValue"
			          @keyup="handleKeyUp"
			          @change="dispatchEvent"
			          :readonly="readonly"
			          :autocomplete="autocomplete"
			          :disabled="disabled"
			          v-else></textarea>
			<div class="action" v-if="type === 'password'">
				<button type="button" class="toggle-password" @click.prevent="togglePasswordVisibility">
					<i class="fa-light fa-eye" v-if="!passwordVisibility"></i>
					<i class="fa-light fa-eye-slash" v-else></i>
				</button>
			</div>
		</div>
		<ul class="validate-message-list" v-if="validateMessages.length">
			<li v-for="(item, index) in validateMessages" :key="index">{{ item }}</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "DefaultFieldBox",
	props: ['label', 'name', 'type', 'placeholder', 'value', 'disabled', 'required', 'event', 'readonly', 'autocomplete', 'passwordValidation'],
	data() {
		return {
			error: false,
			filled: false,
			inputValue: this.value,
			validateMessages: [],
			passwordVisibility: false,
		}
	},
	computed: {
		fieldType() {
			if (this.type === 'password' && this.passwordVisibility) {
				return 'text';
			}

			return this.type;
		},
		extraClasses() {
			let classes = [];
			if (this.required) {
				classes.push('required');
			}

			if (this.error) {
				classes.push('error');
			}

			if (this.filled) {
				classes.push('filled');
			}

			classes = classes.join(' ');

			return classes;
		}
	},
	watch: {
		value(value) {
			this.inputValue = value;
			this.validateField();
		}
	},
	methods: {
		togglePasswordVisibility() {
			this.passwordVisibility = !this.passwordVisibility;
		},
		validateField(event, mounted = false) {
			if (this.readonly) {
				return false;
			}

			this.filled = !!this.inputValue;

			let validate = true;
			if (this.type === 'email') {
				if (this.inputValue && !this.validateEmail()) {
					validate = false;
				}
			} else if (this.type === 'tel') {
				if (this.inputValue && !this.validatePhone()) {
					validate = false;
				}
			}

			if (this.passwordValidation && !mounted) {
				if (!this.validatePassword()) {
					validate = false;
				}
			}

			if (this.required && !this.inputValue && !mounted) {
				validate = false;
			}

			this.error = !validate;
		},
		validateEmail() {
			let reg = /\S+@\S+\.\S+/;
			return reg.test(this.inputValue);
		},
		validatePhone() {
			return this.inputValue.includes('+');
		},
		validatePassword() {
			let messages = [];
			let validate = true;
			if (!/[a-z]/.test(this.inputValue)) {
				messages.push(this.$t('validation.password_lowercase'));
				validate = false;
			}
			if (!/[A-Z]/.test(this.inputValue)) {
				messages.push(this.$t('validation.password_uppercase'));
				validate = false;
			}
			if (!/\d/.test(this.inputValue)) {
				messages.push(this.$t('validation.password_number'));
				validate = false;
			}
			if (!/[^a-zA-Z\d]/.test(this.inputValue)) {
				messages.push(this.$t('validation.password_special_char'));
				validate = false;
			}

			this.validateMessages = messages;
			return validate;
		},
		handleKeyUp() {
			if (this.passwordValidation) {
				this.validateField();
			}
			this.dispatchEvent();
		},
		dispatchEvent() {
			if (!this.event) {
				return false;
			}

			this.$root.$emit(this.event, {
				name: this.name,
				value: this.inputValue
			});
		}
	},
	mounted() {
		this.validateField(null, true);
	}
}
</script>

<style scoped lang="scss">
.validate-message-list {
	padding-left: 0px;
	padding-right: 0px;
	margin-top: 10px;
	font-size: 12px;
	color: $errorColor;
}

.light-mode .form-group.filled {
	input:not(:focus):not(:active),
	textarea:not(:focus):not(:active) {
		border-color: var(--background-color, $backgroundColor);
	}

	&:after {
		background: var(--background-color, $backgroundColor);
	}
}

.form-group {
	position: relative;

	.field.password {
		input {
			padding-right: 45px;
		}
	}

	.action {
		position: absolute;
		right: 20px;
		top: calc(50% - 8px);
		line-height: 1;
		font-size: 16px;

		button {
			border: none;
			background: transparent;
			color: var(--primary-color, $primaryColor);
			cursor: pointer;
		}
	}

	label {
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
	}

	&.required {
		input,
		textarea {
			padding-right: 35px;
		}

		.field {
			position: relative;

			&.password {
				&:after {
					content: none;
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				right: 25px;
				//top: calc(50% - 2px);
				bottom: 23px;
				height: 4px;
				width: 4px;
				border-radius: 50%;
				background: var(--primary-color, $primaryColor);
				transition: $animationDefaultDuration;
			}
		}
	}

	input,
	textarea {
		border-color: transparentize($whiteColor, .8);
		transition: $animationDefaultDuration;

		&:focus,
		&:active {
			border-color: var(--primary-color, $primaryColor);
		}
	}

	&.filled {
		input:not(:focus):not(:active),
		textarea:not(:focus):not(:active) {
			border-color: var(--white-color, $whiteColor);
		}

		.field {
			&:after {
				background: var(--white-color, $whiteColor);
			}
		}
	}

	&.error {
		input:not(:focus):not(:active),
		textarea:not(:focus):not(:active) {
			border-color: var(--error-color, $errorColor);
		}

		.field {
			&:after {
				background: var(--error-color, $errorColor);
			}
		}
	}
}
</style>