<template>
    <default-sidebar :active="$store.state.sidebars.showProtocolDetail">
        <protocol-detail-box :data="$store.state.sidebars.showProtocolDetail" v-if="$store.state.sidebars.showProtocolDetail"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import ProtocolDetailBox from "../containers/ProtocolDetailBox";

    export default {
        name: "ProtocolDetailSidebar",
        components: {
            DefaultSidebar,
            ProtocolDetailBox,
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>