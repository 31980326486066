<template>
	<header class="native-header" :class="userNav ? 'active' : ''">
		<div class="content-wrap">
			<div class="container">
				<nav class="user-nav">
					<div class="user-nav-box" v-if="$store.state.auth.user.full_name" @click.stop="toggleUserNav">
						<figure class="image fit-image">
							<img :src="getImage($store.state.auth.user.avatar.filename)"
							     v-if="$store.state.auth.user.avatar">
							<img src="@/assets/img/user-sample.jpg" v-else>
						</figure>
						<div class="status">
							<i class="fa-thin fa-chevron-down"></i>
						</div>
					</div>
					<div class="user-nav-content" :class="userNav ? 'active' : ''" @click.stop>
						<p class="greeting">{{ greeting }}, <strong>{{ $store.state.auth.user.full_name }}</strong></p>
						<user-box :data="brokerData" v-if="brokerData"/>
						<user-box :data="agentData" v-if="agentData"/>
						<div class="actions">
							<button class="button style-5" @click.stop="$store.commit('TOGGLE_SETTINGS')"
							        @click.prevent="closeUserNav">
								<span class="inner">{{ $t('forms.settings') }}</span>
							</button>
							<button class="button style-5" @click.prevent="goToProfile">
								<span class="inner">{{ $t('forms.edit_profile') }}</span>
							</button>
							<button class="button style-5" :class="personalDocumentsLoading ? 'loading' : ''"
							        @click.stop="togglePersonalDocumentsCreateForm">
								<span class="inner">{{ $t('cards.add_personal_document') }}</span>
							</button>
							<onboarding-box/>
						</div>
					</div>
				</nav>
				<app-logo :link="'/'"/>
				<div class="actions">
					<button class="user-notifications" @click.stop="$store.dispatch('toggleNotificationsSidebar')">
						<span class="count"
						      v-if="$store.state.auth.user.not_shown_notifications_count">{{ $store.state.auth.user.not_shown_notifications_count }}</span>
						<i class="fa-thin fa-bell"></i>
					</button>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import AppLogo from "./AppLogo";
	import UserBox from "../containers/UserBox";
	import OnboardingBox from "../containers/OnboardingBox";

	export default {
		name: "NativeHeader",
		components: {
			AppLogo,
			UserBox,
			OnboardingBox,
		},
		mixins: [pageFunctionsMixin],
		data() {
			return {
				userNav: false,
				personalDocumentsLoading: false,
			}
		},
		computed: {
			brokerData() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				let agent = this.$store.state.auth.user.agent;

				return {
					full_name: agent.broker_data.broker_title,
					description: agent.broker_data.broker_url,
					avatar: agent.broker_data.broker_logo,
					imageObjectFit: 'contain',
				};
			},
			agentData() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				let agent = this.$store.state.auth.user.agent;

				return {
					avatar: agent.avatar,
					full_name: agent.fullName,
					description: this.$t('contacts.your_agent')
				};
			},
			greeting() {
				let hours = new Date().getHours();
				if (hours <= 7 && hours > 4) {
					return this.$t('greetings.morning');
				} else if (hours <= 16) {
					return this.$t('greetings.day');
				} else {
					return this.$t('greetings.evening');
				}
			},
		},
		methods: {
			togglePersonalDocumentsCreateForm() {
				if (!this.$store.state.views.personal_document_categories.length) {
					this.personalDocumentsLoading = true;
					this.axios.get('/api/personal-document-categories').then(response => {
						this.$store.dispatch('setPersonalDocumentCategories', response.data.personal_document_categories);
						this.$store.commit('TOGGLE_PERSONAL_DOCUMENTS_CREATE_FORM');
						this.personalDocumentsLoading = false;
						this.closeUserNav();
					}).catch(() => {
						this.personalDocumentsLoading = false;
					});

					return;
				}

				this.$store.commit('TOGGLE_PERSONAL_DOCUMENTS_CREATE_FORM');
				this.closeUserNav();
			},
			goToProfile() {
				this.closeUserNav();
				if (this.$router.currentRoute.name === 'Profile') {
					return false;
				}
				this.$router.push({name: 'Profile'});
			},
			toggleUserNav() {
				this.userNav = !this.userNav;
			},
			openUserNav() {
				this.userNav = true;
			},
			closeUserNav() {
				this.userNav = false;
			}
		},
		mounted() {
			document.body.addEventListener('click', this.closeUserNav);
		},
		destroyed() {
			document.body.removeEventListener('click', this.closeUserNav);
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.user-nav-content {
			background: #F0F0F2;
			box-shadow: 0 50px 99px rgba(var(--black-color-rgb), .25);

			.greeting {
				strong {
					color: var(--background-color, $backgroundColor);
				}
			}
		}

		.user-nav-box {
			a {
				color: var(--background-color, $backgroundColor);
			}

			.image {
				border: 1px solid var(--background-color, $backgroundColor);
			}
		}

		.user-notifications {
			color: var(--background-color, $backgroundColor);
		}
	}

	.native-header {
		padding: 50px 0;
		position: relative;
		z-index: 10;
    &.active {
      z-index: 100;
    }
		@media($mobileM) {
			padding: 15px 0 30px;
		}
	}

	.user-nav-content {
		//display: none;
		position: absolute;
		left: -15px;
		right: -15px;
		top: 100%;
		background: $backgroundColor;
		padding: 30px 15px;
		box-shadow: 0 50px 99px var(--black-color, $blackColor);
		background: var(--aside-background-color, #0F1C37);
		transition: $animationDefaultDuration;
		transform: translateY(-200%);

		&.active {
			transform: translateY(0);
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100%;
			height: 120px;
			background: inherit;
		}

		.user-box {
			+ .user-box {
				padding-top: 15px;
				margin-top: 15px;
				border-top: 1px solid transparentize($whiteColor, .85);
			}
		}

		.actions {
			margin-top: 30px;
		}

		.greeting {
			text-align: center;
			font-size: 21px;

			strong {
				color: $whiteColor;
			}
		}

		.button {
			width: 100%;

			+ .button {
				margin-top: 15px;
			}
		}
	}

	.container {
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: center;

		> .actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.user-nav,
	.actions {
		flex: 1;
	}


	.user-notifications {
		font-size: 21px;
		background: transparent;
		padding: 0;
		margin: 0;
		border: none;
		color: var(--white-color, $whiteColor);
		position: relative;
		line-height: 1;
		cursor: pointer;
		transition: $animationDefaultDuration;

		.count {
			position: absolute;
			display: inline-block;
			font-size: 15px;
			line-height: 1.5;
			color: var(--white-color, $whiteColor);
			font-weight: 700;
			border-radius: 4px;
			background: var(--primary-gradient, $primaryGradient);
			padding: 5px 8px;
			right: calc(100% + 12px);
			top: -5px;

			&:after {
				content: '';
				position: absolute;
				display: block;
				width: 12px;
				height: 12px;
				transform: rotate(45deg);
				right: -3px;
				top: calc(50% - 6px);
				background: var(--end-gradient-color, #ee4a38);
				z-index: -1;
			}
		}

		&:hover {
			color: var(--primary-color, $primaryColor);
		}
	}

	.user-nav-box {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 10;

		.status {
			font-size: 10px;
			transition: $animationDefaultDuration;
		}

		> .image {
			/*width: 35px;
			height: 35px;*/
			width: 37px;
			height: 37px;
			border-radius: 100%;
			position: relative;
			border: 1px solid var(--primary-color, $primaryColor);
			margin-right: 5px;
			overflow: hidden;

			img {
				border-radius: 100%;
			}

			a {
				display: block;
			}

			img {
				padding: 2px;
			}
		}

		a {
			font-weight: 400;
			color: var(--white-color, $whiteColor);

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}
	}
</style>