<template>
    <div class="product-containers-group" :class="scrollClass ? 'with-scroll' : ''">
        <div class="featured-product">
            <product-box :data="featuredProduct"/>
        </div>
        <product-boxes-container :products="topPartProducts"/>
        <product-boxes-container :products="bottomPartProducts" :columns="4" v-if="bottomPartProducts.length"/>
    </div>
</template>

<script>
    import ProductBox from "./ProductBox";
    import ProductBoxesContainer from "./ProductBoxesContainer";

    export default {
        name: "ProductContainersGroup",
        props: ['featuredProduct', 'products'],
        components: {
            ProductBox,
            ProductBoxesContainer
        },
        data() {
            return {
                width: window.innerWidth,
            }
        },
        computed: {
            topPartProducts(){
                if (this.width < 992){
                    return this.products;
                }

                return this.products.slice(0, 4);
            },
            bottomPartProducts(){
                if (this.width < 992){
                    return [];
                }
                return this.products.slice(4);
            },
            scrollClass(){
                return !!this.$store.state.app.native;
            },
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    .product-containers-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .featured-product,
        .product-boxes-container {
            width: calc(50% - 10px);
        }
        .product-boxes-container.four-columns {
            margin-top: 20px;
            width: 100%;
        }
        .featured-product {
            display: flex;
            flex-direction: column;
            .product-box {
                flex-grow: 1;
            }
        }
        @media($desktopL){
            .featured-product,
            .product-boxes-container {
                width: calc(50% - 10px);
            }
        }
        @media($tabletL){
            flex-direction: column;
            .featured-product {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .featured-product,
            .product-boxes-container {
                width: 100%;
            }
        }

        @media($mobileM){
            &.with-scroll {
                flex-direction: column;
                overflow-x: visible;
                padding: 0 15px;
                margin: 0 -15px;
                .featured-product {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .product-box {
                        min-height: initial;
                        flex-grow: initial;
                    }
                }
                .product-boxes-container {
                    width: auto;
                    overflow-x: scroll;
                    min-width: calc(100% + 15px);
                    padding-right: 15px;
                    padding-left: 0;
                    margin: 0;
                }
            }
        }
    }
</style>