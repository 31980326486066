<template>
    <default-sidebar :active="$store.state.sidebars.showDocumentsEditForm">
        <documents-edit-form :data="$store.state.sidebars.showDocumentsEditForm" v-if="$store.state.sidebars.showDocumentsEditForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import DocumentsEditForm from "../forms/DocumentsEditForm";

    export default {
        name: "DocumentsEditFormSidebar",
        components: {
            DefaultSidebar,
            DocumentsEditForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>