<template>
	<form class="registration-form" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<input type="hidden" name="facebook_token" v-model="facebook_token" v-if="facebook_token">
		<input type="hidden" name="google_token" v-model="google_token" v-if="google_token">
		<input type="hidden" name="apple_token" v-model="apple_token" v-if="apple_token">
		<input type="hidden" name="invite_hash" v-model="inviteHash" v-if="inviteHash">
		<div class="form-row two-columns" v-if="!apple_token">
			<default-field-box type="text" name="first_name" :placeholder="$t('forms.first_name')" :value="first_name"
							   :required="true"/>
			<default-field-box type="text" name="last_name" :placeholder="$t('forms.last_name')" :value="last_name"
							   :required="true"/>
		</div>
		<div class="form-row two-columns">
			<default-field-box type="email" name="email" :placeholder="$t('forms.email')" :value="email"
							   :required="true" :readonly="readOnlyEmail"/>
			<default-field-box type="tel" name="phone" :placeholder="$t('forms.phone')" :value="phone"
							   :required="true"/>
		</div>
		<div class="form-row two-columns" v-if="!apple_token">
			<default-field-box type="password" name="password" :placeholder="$t('forms.password')" :required="true"
			                   :password-validation="true"/>
			<default-field-box type="password" name="password_confirmation"
							   :placeholder="$t('forms.password_confirmation')" :required="true"/>
		</div>
		<div class="form-row two-columns">
			<default-field-box type="text" name="agent_code" :placeholder="$t('forms.agent_code')" :value="agent_code"/>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="privacy_policy" value="1">
				<span class="status"></span>
				<span class="field-title"
					  v-html="$t('forms.agreement_privacy_policy', {link: domainUrl + $t('routes.privacy_policy_finance.path')})"></span>
			</label>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="terms" value="1">
				<span class="status"></span>
				<span class="field-title"
					  v-html="$t('forms.agreement_terms', {link: domainUrl + $t('routes.terms.path')})"></span>
			</label>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="marketing" value="1">
				<span class="status"></span>
				<span class="field-title"
					  v-html="$t('forms.agreement_marketing', {link: domainUrl + $t('routes.privacy_policy_marketing.path')})"></span>
			</label>
		</div>
		<div class="form-row">
			<label class="checkbox-box">
				<input type="checkbox" name="financial_services" value="1">
				<span class="status"></span>
				<span class="field-title"
					  v-html="$t('forms.agreement_financial_services', {link: domainUrl + $t('routes.financial_services.path')})"></span>
			</label>
		</div>
		<div class="form-actions">
			<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('auth.create_account')}}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import ErrorsList from "../containers/ErrorsList";
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";

	export default {
		name: "RegistrationForm",
		components: {
			ErrorsList,
			DefaultFieldBox,
		},
		data() {
			return {
				errors: false,
				loading: false,
				email: '',
				first_name: '',
				last_name: '',
				agent_code: '',
				phone: '',
				password: '',
				password_confirmation: '',
				facebook_token: '',
				google_token: '',
				apple_token: '',
				readOnlyEmail: false,
				inviteHash: false,
			}
		},
		computed: {
			domainUrl() {
				return process.env.VUE_APP_URL;
			}
		},
		methods: {
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);

				this.loading = true;
				this.axios.post('/api/register', formData).then(response => {
					this.$store.commit('SET_USER', response.data.user);
					this.$store.commit('SET_USER_LOGGED_IN');
					localStorage.setItem('user_token', response.data.user_token);
					this.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.user_token}`;
					this.$store.commit('SET_USER_TOKEN', response.data.user_token);
					this.$store.dispatch('importContracts');
					this.$store.dispatch('importPersonalDocuments');
					this.$store.dispatch('importIntranetData');

					if (!response.data.user.complete_registration) {
						this.$store.commit('OPEN_COMPLETE_REGISTRATION_POPUP');
					}

					this.$store.commit('OPEN_USER_ACTIVATION_POPUP');
					this.$store.commit('SET_INVITE_HASH', false);
					this.$store.commit('SET_INTRANET_USER', false);

					this.$router.push('/');
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
					this.loading = false;
				});
			},
			preFillIntranetUser() {
				let data = this.$store.state.auth.intranetUser;
				if (!data) {
					return false;
				}

				this.first_name = data.first_name;
				this.last_name = data.last_name;
				this.email = data.email;
				this.phone = data.phone.mobile1;
				this.agent_code = data.acquired_agent.id;
				this.inviteHash = this.$store.state.auth.inviteHash;
			},
			preFillAgent() {
				let agent = this.$store.state.auth.inviteAgent;
				if (!agent) {
					return false;
				}

				this.agent_code = agent;
			}
		},
		mounted() {
			this.$root.$on('socials-data-received', (data) => {
				this.first_name = data.first_name;
				this.last_name = data.last_name;
				this.email = data.email;
				this.readOnlyEmail = true;

				if (data.facebook_token) {
					this.facebook_token = data.facebook_token;
				}
				if (data.google_token) {
					this.google_token = data.google_token;
				}
				if (data.apple_token) {
					this.apple_token = data.apple_token;
				}
			});

			this.preFillIntranetUser();
			this.preFillAgent();
		},
		destroyed() {
			this.$root.$off('socials-data-received');
		}
	}
</script>

<style scoped>

</style>