<template>
    <default-sidebar :active="$store.state.sidebars.showDocumentsCreateForm">
        <documents-create-form v-if="$store.state.views.document_categories.length && $store.state.sidebars.showDocumentsCreateForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import DocumentsCreateForm from "../forms/DocumentsCreateForm";

    export default {
        name: "DocumentsCreateFormSidebar",
        components: {
            DefaultSidebar,
            DocumentsCreateForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>