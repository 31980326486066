<template>
	<main class="public-main">
		<section class="public-content-section big-padding-bottom" v-if="page">
			<header class="public-section-header">
				<div class="content-wrap">
					<h1 class="public-page-title" v-html="page.name"></h1>
				</div>
			</header>
			<article>
				<div class="content-wrap">
					<div class="default-text-box" v-html="page.content"></div>
				</div>
			</article>
		</section>
		<download-section/>
		<partners-section/>
	</main>
</template>

<script>
	import DownloadSection from "../components/sections/DownloadSection";
	import PartnersSection from "../components/sections/PartnersSection";

	export default {
		name: "PageDetail",
		components: {
			DownloadSection,
			PartnersSection
		},
		data() {
			return {
				page: this.$store.state.views.pageDetail,
			}
		},
		created() {

		}
	}
</script>

<style scoped>

</style>