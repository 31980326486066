<template>
	<form class="personal-documents-form" enctype="multipart/form-data" @submit.prevent="submitForm">
		<input type="hidden" name="_method" value="PUT">
		<errors-list :errors="errors"/>
		<div class="form-row">
			<checkbox-switcher name="child" :label="$t('forms.personal_document_child')"
			                   icon='<i class="fa-thin fa-circle-check"></i>'
			                   :checked="data.child"
			                   :disabled="!isEditing"/>
		</div>
		<div class="form-row">
			<field-box name="personal_document_category_id" :label="$t('forms.personal_document_category')" type="select"
			           :options="personalDocumentCategoriesOptions" :disabled="!isEditing"
			           :value="data.personal_document_category_id"/>
		</div>
		<div class="form-row two-columns">
			<field-box name="number" :label="$t('forms.personal_document_number')" type="text" :disabled="!isEditing" :value="data.number"/>
			<field-box name="issued_by" :label="$t('forms.issued_by')" type="text" :disabled="!isEditing" :value="data.issued_by"/>
		</div>
		<div class="form-row" v-if="category === 4">
			<field-box name="name" :label="$t('forms.personal_document_title')" type="text" :disabled="!isEditing" :value="data.name"/>
		</div>
		<div class="form-row two-columns" v-if="child">
			<field-box name="first_name" :label="$t('forms.first_name')" type="text" :disabled="!isEditing" :value="data.first_name"/>
			<field-box name="last_name" :label="$t('forms.last_name')" type="text" :disabled="!isEditing" :value="data.last_name"/>
		</div>
		<div class="form-row two-columns">
			<field-box name="valid_from" :label="$t('forms.valid_from')" type="date" :disabled="!isEditing" :value="data.valid_from"/>
			<field-box name="valid_to" :label="$t('forms.valid_to')" type="date" :disabled="!isEditing" :value="data.valid_to"/>
		</div>
		<div class="attachments-container" v-if="data.front_side || data.back_side">
			<attachment-preview-field :data="data.front_side" :allow-remove="isEditing" name="remove_front_side"
			                          v-if="data.front_side"/>
			<attachment-preview-field :data="data.back_side" :allow-remove="isEditing" name="remove_back_side"
			                          v-if="data.back_side"/>
		</div>
		<div class="dropzone-boxes-container" v-if="isEditing">
			<dropzone-box name="front_side_id" :label="$t('forms.upload_front_side')" url="api/personal-documents/attachment"
			              :max-files="1"/>
			<dropzone-box name="back_side_id" :label="$t('forms.upload_back_side')" url="api/personal-documents/attachment"
			              :max-files="1"/>
		</div>
		<div class="form-actions">
			<button class="button style-4" type="button" v-if="!isEditing" @click.prevent="isEditing = true">
				<span class="inner">{{ $t('forms.edit') }}</span>
			</button>
			<button class="button style-4" type="submit" :class="loading ? 'loading' : ''" v-else>
				<span class="inner">{{ $t('forms.save') }}</span>
			</button>
			<button class="button style-1" type="button" @click="$store.commit('HIDE_PERSONAL_DOCUMENTS_EDIT_FORM')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import ErrorsList from "../containers/ErrorsList";
	import EditableValueField from "../form-fields/EditableValueField";
	import FieldBox from "../form-fields/FieldBox";
	import DropzoneBox from "../form-fields/DropzoneBox";
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import AttachmentPreviewField from "../form-fields/AttachmentPreviewField";

	export default {
		name: "PersonalDocumentsEditForm",
		props: ['data'],
		components: {
			ErrorsList,
			EditableValueField,
			FieldBox,
			DropzoneBox,
			CheckboxSwitcher,
			AttachmentPreviewField,
		},
		data() {
			return {
				loading: false,
				errors: false,
				child: this.data.child,
				isEditing: false,
				category: '',
			}
		},
		computed: {
			personalDocumentCategoriesOptions() {
				let categories = [];
				this.$store.state.views.personal_document_categories.forEach(item => {
					if (item.ocr) {
						return;
					}
					categories.push({
						value: item.id,
						title: item.name
					});
				});

				categories.forEach(item => {
					if (item.value === this.data.personal_document_category_id) {
						item.selected = true;
					}
				});

				return categories;
			},
		},
		methods: {
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);

				this.loading = true;
				this.axios.post(`/api/personal-documents/${this.data.id}`, formData).then(response => {
					this.resetForm(form);
					this.loading = false;

					this.$store.dispatch('updatePersonalDocument', {
						personal_document: response.data.personal_document,
					});
					this.$store.commit('HIDE_PERSONAL_DOCUMENTS_EDIT_FORM');
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			},
			resetForm(form) {
				form.reset();

				let frontSideInput = form.querySelector('input[name="front_side_id"]');
				if (frontSideInput) {
					frontSideInput.parentNode.removeChild(frontSideInput);
				}

				let backSideInput = form.querySelector('input[name="back_side_id"]');
				if (backSideInput) {
					backSideInput.parentNode.removeChild(backSideInput);
				}

				let dzPreviews = form.querySelectorAll('.dz-preview');
				if (dzPreviews.length) {
					for (let dzPreview of dzPreviews) {
						dzPreview.parentNode.removeChild(dzPreview);
					}
				}

				let dropzoneEls = form.querySelectorAll('.dropzone');
				if (dropzoneEls.length) {
					for (let dropzoneEl of dropzoneEls) {
						dropzoneEl.classList.remove('dz-started');
					}
				}
			}
		},
		mounted() {
			this.$root.$on('checkbox-switcher-change', (data) => {
				if (data.name === 'child') {
					this.child = data.value;
				}
			});

			this.$root.$on('field-changed', (data) => {
				if (data.name === 'personal_document_category_id') {
					this.category = parseInt(data.value);
				}
			});
		}
	}
</script>

<style scoped lang="scss">

	.personal-documents-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}

	.form-actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;

		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.dropzone-boxes-container {
		display: flex;
		justify-content: space-between;

		.dropzone-box {
			width: calc(50% - 8px);
		}
	}

	@media($mobileS) {
		.form-actions {
			margin-top: 25px;
		}
		.dropzone-boxes-container {
			flex-direction: column;

			.dropzone-box {
				width: 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.form-row {
			margin-bottom: 10px;

			&.two-columns {
				flex-direction: column;

				.field-box {
					width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>