<template>
    <div class="product-box" :class="typeClass">
        <router-link :to="`${$t('routes.products.path')}/${data.id}`" @click.native="log">
            <div class="inner">
                <figure class="fit-image background" v-if="data.preview_image">
                    <img :src="getImage(data.preview_image.filename)">
                </figure>
                <div class="text">
                    <h3 class="product-title">{{ data.name }}</h3>
                    <p>{{ data.excerpt }}</p>
                    <div class="actions">
                        <button class="button style-2">
                            <span class="inner">{{ $t('products.box_action') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

    export default {
        name: "ProductBox",
        props: ['data'],
        mixins: [pageFunctionsMixin],
        computed: {
            typeClass() {
                return `type-${this.data.type}`;
            },
        },
        methods: {
            log(){
                this.logActivity('product', this.data.id);
            }
        }
    }
</script>

<style scoped lang="scss">
    .light-mode {
        .product-box {
            .product-title {
                color: var(--white-color, $whiteColor);
            }
        }
    }
    .product-box {
        min-height: 360px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &:before {
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            display: block;
            z-index: 2;
            border-radius: inherit;
            overflow: hidden;
        }

        .background {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            img {
                mix-blend-mode: soft-light;
            }
        }


        &.type-1 {
            .background {
                background: $primaryColor;
            }
            &:before {
                background-image: linear-gradient(to top, transparentize($primaryColor, .5) 0%, transparentize($primaryColor, .99));
            }
        }

        &.type-2 {
            .background {
                background: $otherColor1;
            }
            &:before {
                background-image: linear-gradient(to top, transparentize($otherColor1, .5) 0%, transparentize($otherColor1, .99));
            }
        }

        &.type-3 {
            .background {
                background: $otherColor2;
            }
            &:before {
                background-image: linear-gradient(to top, transparentize($otherColor2, .5) 0%, transparentize($otherColor2, .99));
            }
        }

        &.type-4 {
            .background {
                background: $otherColor3;
            }
            &:before {
                background-image: linear-gradient(to top, transparentize($otherColor3, .5) 0%, transparentize($otherColor3, .99));
            }
        }

        &.type-5 {
            .background {
                background: $otherColor4;
            }
            &:before {
                background-image: linear-gradient(to top, transparentize($otherColor4, .5) 0%, transparentize($otherColor4, .99));
            }
        }

        > a {
            display: block;
            height: 100%;
            position: relative;
            z-index: 4;
            border-radius: inherit;
            overflow: hidden;

            > .inner {
                height: 100%;
                padding: 35px 30px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .text {
            position: relative;
            z-index: 3;
            color: var(--white-color, $whiteColor);
        }

        .product-title {
            font-size: 24px;
            font-weight: 600;
        }

        p {
            font-size: 12px;
        }

        .button {
            width: 100%;
            padding: 10px;
        }

        &:hover {
            .button {
                background: var(--background-color, $backgroundColor);
                color: var(--white-color, $whiteColor);
            }
        }

        @media($desktopXL){
            > a {
                >.inner {
                    padding: 25px 20px;
                }
            }
        }
        @media($desktopM){
            min-height: 300px;
        }
        @media($tabletL){
            .product-title {
                font-size: 3vw;
            }
        }
        @media($tabletM){
            .product-title {
                font-size: 4vw;
            }
        }
        @media($mobileM){
            a{
                >.inner {
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        padding-bottom: 128.5%;
                    }
                }
            }

            .text {
                position: absolute;
                left: 20px;
                right: 20px;
                top: 25px;
                bottom: 25px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
            .product-title {
                font-size: 7.75vw;
            }
        }
    }

    .featured-product {
        .product-box {
            .inner {
                min-height: 740px;
            }
        }
        .product-title {
            font-size: 70px;
            margin-bottom: 35px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            font-size: 16px;
            margin-bottom: 50px;
            max-width: 350px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .button {
            width: 100%;
            padding: 20px 35px;
        }
        @media($desktopL){
            .product-title {
                font-size: 5.5vw;
            }
        }
        @media($desktopM){
            .product-box {
                .inner {
                    min-height: 620px;
                }
            }
        }
        @media($tabletL){
            .product-title {
                font-size: 7.5vw;
            }
            .product-box {
                .inner {
                    min-height: 450px;
                }
            }
        }
        @media($mobileM){
            .product-title {
                font-size: 7.75vw;
                margin-bottom: 25px;
            }
            p {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .button {
                padding: 10px;
            }
            .product-box {
                a{
                    >.inner {
                        min-height: auto;
                        position: relative;
                        &:before {
                            content: '';
                            display: block;
                            width: 100%;
                            padding-bottom: 128.5%;
                        }
                    }
                }

                .text {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    top: 25px;
                    bottom: 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }
        }
    }


</style>