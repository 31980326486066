<template>
	<footer class="app-footer">
		<div class="content-wrap">
			<div class="container">
				<broker-logo/>
			</div>
		</div>
	</footer>
</template>

<script>
	import BrokerLogo from "./BrokerLogo";
	export default {
		name: "AppFooter",
		components: {
			BrokerLogo
		}
	}
</script>

<style scoped lang="scss">
	.app-footer {
		padding: 0 0 25px;
		position: relative;
		.container {
			display: flex;
			justify-content: center;
		}
		.broker-logo {
			display: none;
			max-width: 175px;
		}
		@media($tabletM){
			.broker-logo {
				display: block;
			}
		}
		@media($mobileM){
			padding: 25px 0;
		}
	}
</style>