<template>
    <div class="user-box">
        <figure class="image" :class="data.imageObjectFit">
            <img :src="data.avatar">
        </figure>
        <div class="text">
            <h5 class="user-title">{{ data.full_name }}</h5>
            <p class="description">{{ data.description }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserBox",
        props: ['data'],
    }
</script>

<style scoped lang="scss">
    .light-mode {
        .user-box {
            .user-title {
                color: var(--background-color, $backgroundColor);
            }
            .image {
                border-color: var(--background-color, $backgroundColor);
            }
        }
    }
    .user-nav-content .user-box .image img {
        padding: 2px;
    }
    .contact-info-box {
        .user-box {
            .image {
                width: 50px;
                min-width: 50px;
                height: 50px;

                img {
                    object-fit: contain;
                }
            }
            .description {
                font-size: 14px;
            }
        }
    }
    .preview-field  {
        .user-box {
            .image {
                width: 49px;
                height: 49px;
                min-width: 49px;
                border: none;

                img {
                    padding: 0;
                }
            }
            .user-title {
                font-size: 20px;
            }
            .description {
                font-size: 13px;
            }
        }

    }
    .agent-detail-box {
        .user-box {
            .image {
                width: 80px;
                height: 80px;
                min-width: 80px;
            }
            .user-title {
                font-size: 26px;
            }
            .description {
                font-size: 15px;
            }
        }
        @media($tabletS){
            .user-box {
                .image {
                    width: 60px;
                    min-width: 60px;
                    height: 60px;
                }
                .user-title {
                    font-size: 20px;
                }
            }
        }
    }
    .user-box {
        display: flex;
        align-items: center;

        .user-title {
            color: var(--white-color, $whiteColor);
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 2px;
        }

        .description {
            font-size: 10px;
        }

        .image {
            min-width: 40px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 1px solid var(--white-color, $whiteColor);
            margin-right: 20px;
            &.contain {
                img {
                    object-fit: contain;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
                padding: 5px;
            }

        }
    }
</style>