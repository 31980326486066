<template>
	<div class="field-box" @click="focusField" :class="classes">
		<label :for="fieldName">{{ label }}</label>
		<select :name="name" v-if="type === 'select'" :id="fieldName" ref="field" :disabled="disabled"
		        @change="valueChanged">
			<option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.selected">{{
				option.title }}
			</option>
		</select>
		<input :type="inputType" :name="name" :id="fieldName" :value="formattedValue" :disabled="disabled"
		       @keyup="valueChanged" v-else ref="field">
	</div>
</template>

<script>
	import Selectr from 'mobius1-selectr';
	import flatpickr from 'flatpickr';
	import {Slovak} from "flatpickr/dist/l10n/sk";

	export default {
		name: "FieldBox",
		props: ['label', 'name', 'type', 'options', 'value', 'prefix', 'disabled', 'dateFormat', 'event', 'extraClass'],
		data() {
			return {
				selectr: null
			}
		},
		computed: {
			classes(){
				let classes = [];
				if (this.disabled){
					classes.push('disabled');
				}

				if (this.extraClass){
					classes.push(this.extraClass);
				}

				return classes.join(' ');
			},
			inputType() {
				let type = this.type;
				if (type === 'date') {
					type = 'text';
				}

				return type;
			},
			formattedValue() {
				if (!this.value) {
					return '';
				}

				if (this.type === 'date' && this.dateFormat !== 'd.m') {
					return this.$moment(this.value).format('D.M.YYYY');
				}

				return this.value;
			},
			fieldName() {
				let name = `${this.name}-field`;
				if (this.prefix) {
					name = `${this.prefix}-${name}`;
				}

				return name
			},
		},
		methods: {
			valueChanged() {
				let data = {
					name: this.name,
					value: this.$refs.field.value
				};

				if (this.type === 'select') {
					let option = this.options.find(item => item.value === this.$refs.field.value);
					if (option) {
						data.title = option.title;
					}
				}

				if (this.event) {
					this.$root.$emit(this.event, data);
					return;
				}

				this.$root.$emit('field-changed', data);
			},
			focusField() {
				this.$refs.field.focus();
			},
			initSelect() {
				this.selectr = new Selectr(this.$refs.field, {
					searchable: false,
					placeholder: 'Vyberte možnosť',
				});
			},
			initDate() {
				let dateFormat = 'd.m.Y';
				if (this.dateFormat) {
					dateFormat = this.dateFormat;
				}

				flatpickr(this.$refs.field, {
					'locale': Slovak,
					disableMobile: true,
					dateFormat,
					onReady(_, __, fp) {
						if (dateFormat === 'd.m') {
							fp.calendarContainer.classList.add('hide-year');
						}
					}
				});
			},
		},
		watch: {
			options(value) {
				if (this.selectr) {
					this.selectr.removeAll();
					this.selectr.add(value);
				}
			}
		},
		mounted() {
			switch (this.type) {
				case 'select':
					this.initSelect();
					break;
				case 'date':
					this.initDate();
					break;
			}
		},
	}
</script>

<style lang="scss">
	.light-mode {
		.field-box {
			background: #e2e3e6;

			.selectr-selected {
				color: var(--background-color, $backgroundColor);
			}

			.selectr-container.inverted .selectr-options-container,
			.selectr-options-container {
				background: #e2e3e6;
			}
		}
	}

	.field-box {
		//background: #1d2943;
		background: rgba(var(--white-color-rgb), .06);
		padding: 15px 15px 14px;
		border-radius: 5px;
		position: relative;
		&.with-outline {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				border: 2px solid var(--primary-color, $primaryColor);
				pointer-events: none;
				display: block;
				border-radius: inherit;
			}
		}


		.selectr-selected {
			border: none;
			background: transparent;
			color: var(--white-color, $whiteColor);
			font-weight: 500;
			font-size: 14px;
			padding: 0 12px 0 0;
		}

		&.disabled {
			label {
				pointer-events: none;
			}

			.selectr-selected:before {
				content: none;
			}

			.selectr-container {
				pointer-events: none;
			}
		}

		.selectr-container.open .selectr-selected::before, .selectr-container.native-open .selectr-selected::before,
		.selectr-selected::before {
			border: none;
			content: "\f078";
			font-family: "Font Awesome 6 Pro";
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			display: var(--fa-display, inline-block);
			font-style: normal;
			font-variant: normal;
			line-height: 1;
			text-rendering: auto;
			font-weight: 400;
			position: absolute;
			top: calc(50% - 7px);
			font-size: 12px;
		}

		.selectr-container.inverted .selectr-options-container,
		.selectr-options-container {
			left: -15px;
			right: -15px;
			width: auto;
			border: none;
			background: #1d2943;
			padding: 5px 0;
			border-radius: 0 0 5px 5px;

			.selectr-options {
				overflow-y: auto;
			}

			.selectr-option {
				padding: 2px 15px;
				font-size: 14px;

				&.selected {
					background: var(--primary-color, $primaryColor);
					color: var(--white-color, $whiteColor);
				}

				&.active {
					background: var(--primary-color, $primaryColor);
				}
			}
		}

		input[type="text"], input[type="email"], input[type="password"], textarea, select {
			padding: 0;
			background: transparent;
			color: var(--white-color, $whiteColor);
			font-weight: 500;
			font-size: 14px;
			border: none;
			border-radius: 0;
		}

		label {
			font-size: 10px;
			font-weight: 400;
			margin: 0 0 5px;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}

		select {
			padding: 3px 0 4px;
		}
	}


</style>