<template>
	<div class="notification-boxes-container">
		<notification-box v-for="item in data" :key="item.id" :data="item"/>
	</div>
</template>

<script>
	import NotificationBox from "./NotificationBox";
	export default {
		name: "NotificationBoxesContainer",
		props: ['data'],
		components: {
			NotificationBox
		}
	}
</script>

<style scoped lang="scss">
	.notification-boxes-container {
		.notification-box {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
</style>