<template>
    <div class="article-box">
        <router-link :to="`${$t('routes.articles.path')}/${data.id}`" @click.native="log">
            <div class="inner">
                <figure class="fit-image background" v-if="data.preview_image">
                    <img :src="getImage(data.preview_image.filename)">
                </figure>
                <div class="text">
                    <h3 class="article-title">{{ data.name }}</h3>
                    <h4 class="category" :class="`category-color-${data.article_category.color}`" v-if="data.article_category">
                        {{ data.article_category.name }}
                    </h4>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

    export default {
        name: "ArticleBox",
        props: ['data'],
        mixins: [pageFunctionsMixin],
        methods: {
            log(){
                this.logActivity('article', this.data.id);
            }
        }
    }
</script>

<style scoped lang="scss">
    .article-box {
        min-height: 360px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        .background {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        > a {
            display: block;
            height: 100%;
            position: relative;
            z-index: 4;
            border-radius: inherit;
            overflow: hidden;

            > .inner {
                height: 100%;
                padding: 12px 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .text {
            position: relative;
            z-index: 3;
            color: var(--background-color, $backgroundColor);
            background: var(--white-color, $whiteColor);
            border-radius: 5px;
            padding: 20px;
            min-height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: $animationDefaultDuration;
        }

        .article-title {
            color: var(--background-color, $backgroundColor);
            font-weight: 600;
            font-size: 19px;
            transition: $animationDefaultDuration;
        }

        .category {
            transition: $animationDefaultDuration;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
        }

        .category-color-1 {
            color: $primaryColor;
        }

        .category-color-2 {
            color: #F52070;
        }

        &:hover {
            .text {
                background: var(--background-color, $backgroundColor);
            }
            .article-title {
                color: var(--white-color, $whiteColor);
            }
            .category {
                color: var(--white-color, $whiteColor);
            }
        }
        @media($desktopM){
            min-height: 300px;
        }
        @media($mobileM){
            min-height: initial;
            .inner {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    padding-bottom: 128.5%;
                }
            }
            .text {
                position: absolute;
                left: 10px;
                right: 10px;
                bottom: 12px;
                max-height: calc(100% - 24px);
                overflow: hidden;
            }
            .article-title {
                max-height: calc(100% - 16px);
                overflow: hidden;
            }
        }
    }

    .featured-article {
        .article-box {
            .inner {
                min-height: 740px;
            }
        }
        .text {
            padding: 50px;
        }
        .category {
            font-size: 16px;
        }
        .article-title {
            font-size: 44px;
        }
        @media($desktopM){
            .text {
                padding: 35px;
            }
            .article-title {
                font-size: 3.3vw;
            }
            .article-box {
                .inner {
                    min-height: 620px;
                }
            }
        }
        @media($tabletL){
            .article-title {
                font-size: 35px;
            }
            .article-box {
                .inner {
                    min-height: 450px;
                }
            }
        }
        @media($mobileM){
            .text {
                padding: 20px;
            }
            .article-title {
                font-size: 19px;
            }
            .article-box {
                .inner {
                    //min-height: 300px;
                    min-height: initial;
                }
            }
        }
    }

</style>