const popups = {
	state: {
		showLoginPopup: false,
		showRegistrationPopup: false,
		showResetPasswordPopup: false,
		showProductPopup: false,
		showSetupPinPopup: false,
		showPinLoginPopup: false,
		showUserActivationPopup: false,
		showUserDeletionPopup: false,
		showOcrPopup: false,
		showCompleteRegistrationPopup: false,
	},
	mutations: {
		OPEN_SETUP_PIN_POPUP(state) {
			state.showSetupPinPopup = true;
		},
		OPEN_PRODUCT_POPUP(state, data) {
			state.showProductPopup = data;
		},
		CLOSE_PRODUCT_POPUP(state) {
			state.showProductPopup = false;
		},
		OPEN_USER_ACTIVATION_POPUP(state) {
			state.showUserActivationPopup = true;
		},
		CLOSE_USER_ACTIVATION_POPUP(state) {
			state.showUserActivationPopup = false;
		},
		OPEN_USER_DELETION_POPUP(state) {
			state.showUserDeletionPopup = true;
		},
		CLOSE_USER_DELETION_POPUP(state) {
			state.showUserDeletionPopup = false;
		},
		OPEN_OCR_POPUP(state, data) {
			state.showOcrPopup = data;
		},
		CLOSE_OCR_POPUP(state) {
			state.showOcrPopup = false;
		},
		OPEN_COMPLETE_REGISTRATION_POPUP(state){
			state.showCompleteRegistrationPopup = true;
		},
		CLOSE_COMPLETE_REGISTRATION_POPUP(state){
			state.showCompleteRegistrationPopup = false;
		}
	},
	actions: {
		openLoginPopup(context) {
			context.dispatch('closePopups');
			context.state.showLoginPopup = true;
		},

		openPinLoginPopup(context) {
			context.dispatch('closePopups');
			context.state.showPinLoginPopup = true;
		},

		openRegistrationPopup(context) {
			context.dispatch('closePopups');
			context.state.showRegistrationPopup = true;
		},

		openResetPasswordPopup(context) {
			context.dispatch('closePopups');
			context.state.showResetPasswordPopup = true;
		},

		closePopups(context) {
			for (const [key] of Object.entries(context.state)) {
				context.state[key] = false;
			}
		},
	}
};

export default popups;