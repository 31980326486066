<template>
	<section class="screens-section">
		<header class="public-section-header">
			<div class="content-wrap">
				<h3 class="public-pre-section-title">{{ $t('sections.screens.pre_title') }}</h3>
				<h3 class="public-section-title">{{ $t('sections.screens.title') }}</h3>
			</div>
		</header>
		<article>
			<div class="content-wrap">
				<figure class="screens-image">
					<img :src="getLocalAsset(`img/screens-section/screens-${$store.state.app.appType}.png`)">
				</figure>
			</div>
		</article>
	</section>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	export default {
		name: "ScreensSection",
		mixins: [pageFunctionsMixin],

	}
</script>

<style scoped lang="scss">
	.screens-section {
		padding-top: 170px;
		padding-bottom: 25px;
		position: relative;
		overflow: hidden;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 60%;
			background: var(--background-color, $backgroundColor);
		}
	}
	.public-section-title {
		color: $whiteColor;
	}
	.public-section-header {
		text-align: center;
		margin-bottom: 75px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.screens-image {
		margin: 0 -20% 0 -4%;
	}
	.public-section-header,
	article {
		position: relative;
		z-index: 1;
	}
	.public-pre-section-title {
		color: var(--primary-color, $primaryColor);
	}
	@media($tabletL) {
		.screens-section {
			padding-top: 100px;
		}
	}
	@media($tabletL){
		.screens-section {
			padding: 50px 0;
			&:before {
				height: calc(100% - 150px);
			}
		}
		.public-section-header {
			margin-bottom: 50px;
		}
	}
</style>